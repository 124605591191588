import { Box, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';

import HttpProvider from 'services/HttpProvider'
import StepperContentLoader from './Loaders';
import { observer } from "mobx-react-lite";
import drivers1 from "../../../pages/TryFiles/get_drivers.json";
import drivers2 from '../../../pages/TryFiles/get_complete_drivers.json';


const SelectDriver = ({
  selectedTeam = {},
  activeDriver = [],
  onChange = () => { },
  isTry,
  notTelem,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [drivers, setDrivers] = useState(isTry ? (notTelem ? drivers2 : drivers1 ) : []);
  const [selectedDrivers, setSelectedDrivers] = useState(activeDriver);


  const getDrivers = () => {
    return HttpProvider.get(`/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setDrivers(res.data || [])
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  const handleClick = (driver) => {
    const fullName = `${driver.name}${driver.surname}`
    const email = driver.email
    let drivers = [...selectedDrivers]
    const match = drivers.findIndex((d) => d.value === email)
    if (match >= 0) {
      drivers = drivers.filter((d) => d.value !== email)
    } else {
      drivers.push({ value: email, label: fullName }) 
    }
    setSelectedDrivers(drivers)
    onChange(drivers)
  }

  useEffect(() => {
    if(!isTry){
        getDrivers();
    }   
    
  }, [selectedTeam]);

  return (
    <Box textAlign="center" {...props}>
      {
        isLoading ? <StepperContentLoader />
          :
          <Box flexWrap={"wrap"}>
            {
              drivers.map((d) => {
                const isActive = selectedDrivers.find((driver) => driver.value === d.email)
                return (
                  <Chip
                    label={`${d.name} ${d.surname}`}
                    key={d.id}
                    color={!!isActive ? 'primary' : 'default'}
                    onClick={() => handleClick(d)} sx={{ mb: 1.5, mr: 2 }}
                  />
                )
              })
            }
          </Box>
      }
    </Box>
  );
};

export default observer(SelectDriver);