import { Box, Chip, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { display, height } from "@mui/system";
import { useEffect, useState } from 'react';

import React from "react";
import axios from 'axios';
import myUserStore from '../../stores/UserStore';

const FileDetails = ({handleChipClick} ) => {
  const [driver, setDriver] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;
  const [selectOpen, setSelectOpen] = useState(false);
  const isDriver = selectedTeam?.role === "DRV";
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
  const myEmail = parsedUserData?.email;
  const [emailName, setEmailName] = useState([]);
  const [lastSelectedDriver, setLastSelectedDriver] = useState(null);

  const handleDoneClick = (event) => {
    event.stopPropagation();
    setSelectOpen(false);
  };


  const handleChange = (event) => {
    const selectedDrivers = event.target.value;
    const { value } = event.target;
    if (value.length > 0) {
      setLastSelectedDriver(value[value.length - 1]);
    } else {
      setLastSelectedDriver(null);
    }
    setDriver(selectedDrivers);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        if (showPersonalButton) {
          setDrivers(res.data);
          setDriver([res.data[0].email]);
        } else {
          setDrivers(res.data);
          if (isDriver) {
            const myDriver = res.data.find(item => item.email === myEmail);
            setDriver([myDriver?.email]);
          }
        }
        setEmailName(res.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [selectedTeam]);

  return (
     


      <Box sx={styles.container}>
        <Typography variant="h6" sx={styles.comingSoonTitle}>
          Section Coming Soon!
        </Typography>
        <Typography variant="body1" sx={styles.comingSoonDescription}>
          You will have the possibility to transfer data from your telemetry device directly to AiMotor!
        </Typography>
        <Chip label="Import the data from your device" clickable onClick={handleChipClick} color="primary" sx={styles.chip} />
      </Box>

  );
};

const styles = {
  container: {
    backgroundColor: "#ffffff", // Changed background color to white
    color: "black",
    padding: "20px",
    borderRadius: "10px",
    width: "100%",
    maxWidth: "600px",
    height: '300px',
    margin: "auto",
    marginTop: "100px",
    textAlign: "center" // Center align text
  },
  header: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  title: {
    fontWeight: "bold",
  },
  comingSoonContainer: {
    backgroundColor: "#f7f7f7", // Light grey background for the message box
    padding: "20px",
    borderRadius: "10px",
    margin: "20px 0",
    textAlign: "center", // Center align text
  },
  comingSoonTitle: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  comingSoonDescription: {
    marginBottom: "20px",
  },
  chip: {
    marginTop: "10px", // Margin for the chip
  },
};

export default FileDetails;
