import Form from "react-bootstrap/Form";
import React from "react";
import "../components.css";
import ForgotPasswordForm from "../ForgotPasswordForm";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function ForgotPassword() {
  return (
    <>
   
        <ForgotPasswordForm />
   
    </>
  );
}

export default ForgotPassword;
