import '../components.css';

import React, { useMemo } from 'react';

import SignInForm from '../SignInForm';
import { useLocation } from "react-router-dom";

export default function SignIn() {
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let teamId = query.get('teamId');

  return(
    <>
      <SignInForm teamId={teamId}/>
    </>
  )
}
