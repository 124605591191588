import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"

export default function CardLoader ({ times = 8, height = 144, direction = "vertical", spacing = "24px", ...props }) {
  const isHorizontal = direction === 'horizontal'

  return (
    <Grid container mt={0} flexGrow={0} {...props}>
      {
        Array.from(Array(times).keys()).map((t, index) => (
          <Grid
            item
            xs={12}
            md={ isHorizontal ? (12 / times) : 12}
            mb={spacing}
            key={index}
          >
            <Skeleton sx={{ height, borderRadius: '4px' }} animation="wave" variant="rectangular" />
          </Grid>
        ))
      }
    </Grid>
  )
}