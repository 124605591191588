import { Skeleton, Stack } from "@mui/material";

export default function UserPlanLoader ({ times = 10 }) {
  return (
    <Stack spacing={4}>
      <Skeleton variant="rounded" width={'50%'} height={30} />
      <Stack spacing={3}>
        {
          Array.from(Array(times).keys()).map((t, index) => {
            const isOdd = index % 2 === 0
            return (
              <Stack direction={"row"} key={index} justifyContent={'space-between'} alignItems={'center'}>
                <Skeleton variant="rounded" width={isOdd ? '60%' : '48%'} height={16} />
                <Skeleton variant="rounded" width={isOdd ? '16%' : '22%'} height={16} />
              </Stack>
            )
          })
        }
      </Stack>
    </Stack>
  )
}