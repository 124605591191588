import React, { useState } from "react";
import "../components.css";
import { Redirect } from "react-router-dom";
import InsertForm from "../InsertForm.js";
import ManagerForm from "../ManagerForm.js";
import DriverForm from "../DriverForm.js";
import CompetitionForm from "../CompetitionForm.js";
import { Container, Form } from "react-bootstrap";
import Settings1 from "./Settings1.js";

export default function InsertData() {
  const [selectedComponent, setSelectedComponent] = useState("DriverForm");

  const handleSelect = (event) => {
    setSelectedComponent(event.target.value);
  };

  return (
    <>
      {localStorage.getItem("access_token") ? (
        <Container>
          <Form.Select value={selectedComponent} onChange={handleSelect}>
            {/* <option value="InsertForm">Insert Driver</option> */}
            <option value="DriverForm">Insert Driver </option>
            <option value="CompetitionForm">Insert Competition  </option>
            <option value="Settings1">Insert Manager</option>
          </Form.Select>
          {selectedComponent === "DriverForm" && <DriverForm />}
          {selectedComponent === "CompetitionForm" && <CompetitionForm />}
          {selectedComponent === "Settings1" && <Settings1 />}
        </Container>
      ) : (
        <Redirect to="/sign-in" />
      )}
    </>
  );
}
