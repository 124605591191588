import React, { useEffect, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import { Dialog, DialogTitle, Fab, Stack, IconButton, DialogContent, List, ListItem, ListItemText, LinearProgress, Button, Typography, CircularProgress, Box } from '@mui/material';
import { keyframes } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from 'antd';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import myUserStore from "../../stores/UserStore";
import { observer } from "mobx-react-lite";


const fadeIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const FloatingUpload = ({ uploadData, isRacingSpaceRoute, handleStopUpload = () => { }, closeFab = () => { } }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFabVisible, setIsFabVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isUploadStopped, setIsUploadStopped] = useState(false); // Stato per gestire lo stop

  useEffect(() => {
    // Rileva se il dispositivo ha larghezza inferiore a 1150px
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1150);
    };

    // Imposta lo stato iniziale e aggiunge un listener per i cambiamenti di larghezza
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const hasError = uploadData.some((file) => file.mega === 'error');

  // Calcola la progressione totale dei file
  const totalProgress = uploadData.reduce((acc, file) => acc + file.progress, 0) / uploadData.length || 0;

  useEffect(() => {
    // Ritarda l'apparizione del bottone con un'animazione
    const timer = setTimeout(() => {
      setIsFabVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleButtonClick = () => {
    setIsDialogOpen(true);
  };

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleStopAllUploads = () => {
    window.location.reload();
    if (isDialogOpen) {
      setIsUploadStopped(true);
      

      const hasError = uploadData.some((file) => file.mega === 'error');
      if (!hasError) {
        const stop = uploadData.map(file => ({ name: file.name, stop: true }));
        //myUserStore.setStopUpload(stop)
       handleStopUpload(uploadData.map(file => ({ name: file.name, stop: true })));

      }

      closeFab();
    }
  };

  return (
    <>
      <Dialog open={isDialogOpen} onClose={toggleDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Files Ready to Upload</DialogTitle>
        <DialogContent>
          {uploadData.length > 0 ? (
            <List>
              {uploadData.map((file, index) => {
                const remainingSeconds = Math.ceil(file.remainingTime); // Tempo rimanente in secondi
                const isLast = uploadData.length - 1 === index
                const isError = file.mega === 'error';

                const timeFormatted =
                  remainingSeconds >= 60
                    ? `${Math.floor(remainingSeconds / 60)} min`
                    : `${remainingSeconds} s`; // Converte in minuti o secondi


                return (
                  <>
                    <ListItem key={index} disablePadding>
                      <Stack spacing={0} sx={{ width: '100%' }}>
                        <Typography variant='subtitle1' fontWeight={500}>{file.name}</Typography>
                        <Stack direction={"row"} spacing={1.5} alignItems={'center'}>
                          <LinearProgress
                            variant="determinate"
                            value={isError ? file.progress : file.progress}
                            sx={{
                              height: '10px',
                              borderRadius: 12,
                              width: '100%',
                              backgroundColor: isError ? 'red' : 'none',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: isError ? 'red' : 'none',
                              },
                            }}
                          />
                          <Typography variant="body2" color="textSecondary">{`${file.progress}%`}</Typography>
                          {/* <IconButton
                          aria-label="stop-upload"
                          onClick={() => handleStopUpload([{ name: file.name, stop: true }])}
                          sx={{
                            color: 'red',
                            ml: 1,
                          }}
                        >
                          <CloseIcon />
                        </IconButton> */}
                        </Stack>
                        {isError ? (
                          <Typography variant='body2' color="error" sx={{ display: 'flex', alignItems: 'center' }}>
                            <WifiOffIcon sx={{ mr: 1 }} />
                            Upload failed due to network issues
                          </Typography>
                        ) : (
                          <Typography variant='body2'>
                            Uploaded: {file.mega} MB of {(file.size / (1024 * 1024)).toFixed(2)} MB
                          </Typography>
                        )}                        {/* - {file.speed} MB/s */}
                      </Stack>
                    </ListItem>
                    {uploadData.length > 1 && !isLast ? <Divider /> : null}
                  </>
                );
              })}
            </List>
          ) : (
            <Typography>No files selected for upload.</Typography>
          )}
        </DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          {!hasError && (
            <Button onClick={toggleDialog} variant="outlined">
              Close
            </Button>
          )}
          <Button onClick={handleStopAllUploads} variant="contained" color="error">
            {hasError ? 'Exit' : 'Stop Upload'}
          </Button>
        </Box>
      </Dialog>

      {isFabVisible && !isUploadStopped && (
        <Box
          sx={{
            position: isRacingSpaceRoute && isSmallScreen ? 'absolute' : 'fixed',
            bottom: isRacingSpaceRoute && isSmallScreen ? 'auto' : 35,
            right: isRacingSpaceRoute && isSmallScreen ? 'auto' : 35,
            top: isRacingSpaceRoute && isSmallScreen ? '10px' : 'auto',
            left: isRacingSpaceRoute && isSmallScreen ? '50%' : 'auto',
            transform: isRacingSpaceRoute && isSmallScreen ? 'translateX(-50%)' : 'none',
            zIndex: isRacingSpaceRoute && isSmallScreen ? 1300 : 1000,
            width: 60,
            height: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            variant="determinate"
            value={totalProgress}
            size={55}
            thickness={3}
            onClick={handleButtonClick}
            sx={{
              position: 'absolute',
              color: hasError ? 'red' : (totalProgress === 100 ? 'green' : 'blue'),
              zIndex: 30000000000,
            }}
          />
          {/* Commentato il pulsante upload */}
          <Fab
            color="primary"
            aria-label="upload"
            onClick={handleButtonClick}
            sx={{
              width: 50,
              height: 50,
              backgroundColor: '#ffffff',
              color: '#000000',
              border: '1px solid #000000',
              animation: `${fadeIn} 0.5s ease-out`,
              position: 'relative',
              zIndex: 1300,
            }}
          >
            <UploadIcon />
          </Fab>
        </Box>
      )}
    </>
  );
};

export default observer (FloatingUpload);
