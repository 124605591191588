import * as React from 'react';

import { FormControl, Grid, Hidden, IconButton, InputLabel, Select } from '@mui/material';
import Mmenu, { MenuProps } from '@mui/material/Menu'
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import EditableCell from "../EditableCell";
import EditableCellNumeric from "../EditableCellNumeric";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuItem from '@mui/material/MenuItem';
import { Modal } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import RemoveIcon from '@mui/icons-material/Remove';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import axios from "axios";
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { toast } from "react-toastify";

function PageHeader({ 
    drivers,
    onChange = () => {}, 
    race,
    day,
    competitions, 
    onDayChange = () => {}, 
    onRaceChange = () => {}, 
    competitionLoading, 
    selectedDrivers = [], 
    lastSelectedDriver = null 
  }) {


    useEffect(() => {
        console.log('Drivers Archive:', drivers);
        console.log('Race Archive:', race);
        console.log('Day Archive:', day);
        console.log('Competitions Archive:', competitions);
      }, [drivers, race, day, competitions]);
      

  

  }
  

function Row(props) {
  const { rows, onDelete, updateRows } = props;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1);
  const [show, setShow] = useState(false);
  const selectedTeam = myUserStore.getSelectedTeam;

  const updateValue = (value, field, id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/change_data/?value=${value}&field=${field}&id=${id}&team_id=${selectedTeam.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
      .then(() => {
        // toast.success("Value updated successfully!", {
        //   position: "top-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        // });

        const updatedRows = rows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        );
        updateRows(updatedRows);
      })
      .catch((error) => {
        toast.error("Failed to update the value!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const deleteValue = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/delete_data/?id=${id}&team_id=${selectedTeam.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
      .then(() => {
        // toast.success("Entry deleted successfully!", {
        //   position: "top-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        // });
        handleClose();
        onDelete(id);
      })
      .catch((error) => {
        toast.error("Failed to delete the entry!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const mapperYN = (value) => (value === 1 ? "Yes" : "No");

  const isDriver = selectedTeam.role === "DRV";
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
  const mySurname = parsedUserData.surname;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }} onClick={() => setOpen(!open)}>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => deleteValue(id)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {rows[0].surname}
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& > *": { borderBottom: "none" } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Session Number</TableCell>
                    <TableCell>Lap Time</TableCell>
                    <TableCell align="right">Chassis Number</TableCell>
                    <TableCell align="right">Engine Number</TableCell>
                    <TableCell>Tires New</TableCell>
                    <TableCell>Hot Pressure</TableCell>
                    <TableCell align="right">Cold Pressure</TableCell>
                    <TableCell align="right">Sprocket</TableCell>
                    <TableCell>Pinion</TableCell>
                    <TableCell align="right">Min RPM</TableCell>
                    <TableCell align="right">Camber</TableCell>
                    <TableCell align="right">Caster</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((rowData, index) => (
                    <TableRow key={index} sx={{ "& > *": { borderBottom: "none" } }}>
                      <TableCell style={{ verticalAlign: "middle", borderBottom: "none" }}>
                        <RemoveIcon size="1.5rem" className="remove-icon" onClick={() => handleShow(rowData.id)} />
                      </TableCell>
                      {!(!isDriver || mySurname === rows[0].surname) && (
                        <>
                          <TableCell>{rowData.session_number}</TableCell>
                          <TableCell>{rowData.laptime}</TableCell>
                          <TableCell align="right">{rowData.chassis_number}</TableCell>
                          <TableCell>{rowData.engine_number}</TableCell>
                          <TableCell align="right">{mapperYN(rowData.tires_new)}</TableCell>
                          <TableCell>{rowData.pressure_hot}</TableCell>
                          <TableCell align="right">{rowData.pressure_cold}</TableCell>
                          <TableCell>{rowData.crown}</TableCell>
                          <TableCell align="right">{rowData.pinion}</TableCell>
                          <TableCell>{rowData.min_rpm}</TableCell>
                          <TableCell align="right">{rowData.camber}</TableCell>
                          <TableCell align="right">{rowData.caster}</TableCell>
                        </>
                      )}

                      {(!isDriver || mySurname === rows[0].surname) && (
                        <>
                          <EditableCellNumeric
                            value={rowData.session_number}
                            onSave={updateValue}
                            field="session_number"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCell
                            value={rowData.laptime}
                            onSave={updateValue}
                            field="laptime"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCell
                            align="right"
                            value={rowData.chassis_number}
                            onSave={updateValue}
                            field="chassis_number"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCell
                            value={rowData.engine_number}
                            onSave={updateValue}
                            field="engine_number"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCell
                            value={mapperYN(rowData.tires_new)}
                            onSave={updateValue}
                            field="tires_new"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCellNumeric
                            value={rowData.pressure_hot}
                            onSave={updateValue}
                            field="pressure_hot"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCellNumeric
                            align="right"
                            value={rowData.pressure_cold}
                            onSave={updateValue}
                            field="pressure_cold"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCellNumeric
                            value={rowData.crown}
                            onSave={updateValue}
                            field="crown"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCellNumeric
                            align="right"
                            value={rowData.pinion}
                            onSave={updateValue}
                            field="pinion"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCellNumeric
                            value={rowData.min_rpm}
                            onSave={updateValue}
                            field="min_rpm"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCellNumeric
                            align="right"
                            value={rowData.camber}
                            onSave={updateValue}
                            field="camber"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                          <EditableCellNumeric
                            align="right"
                            value={rowData.caster}
                            onSave={updateValue}
                            field="caster"
                            id={rowData.id}
                            style={{ borderBottom: "none" }}
                          />
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CollapsibleTable = ({race1,drivers1,day1}) => {
    const [drivers, setDrivers] = useState([]);
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [racingData, setRacingData] = useState([]);
    const [race, setRace] = useState("");
    const [day, setDay] = useState(1);
    const [competitions, setCompetitions] = useState([]);
    const [competitionLoading, setCompetitionLoading] = useState(false);
  
    const onDriverChange = (selectedDrivers) => {
      setSelectedDrivers(selectedDrivers);
    };
  
    const onDayChange = (selectedDay) => {
      setDay(selectedDay);
    };
  
    const onRaceChange = (race) => {
      setRace(race);
      setSelectedDrivers([]);
    };
  
    const selectedTeam = myUserStore.getSelectedTeam;
    const showPersonalButton = myUserStore.isPersonalSpace;
  
    useEffect(() => {
      getDrivers(false);
    }, [selectedTeam]);
  
    const getDrivers = async (filter, racingData) => {
      axios
        .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        })
        .then((res) => {
          if (showPersonalButton) {
            setDrivers(res.data);
            setSelectedDrivers([res.data[0].email]);
          } else {
            setDrivers(res.data);
          }
  
          if (filter) {
            const racingDataEmails = racingData.map((item) => item.email);
            const filteredDrivers = res.data.filter((driver) => racingDataEmails.includes(driver.email));
            setDrivers(filteredDrivers);
          }
        })
        .catch((error) => {
          console.error("Axios Error:", error);
        });
    };
  
    const groupedData = racingData.reduce((acc, curr) => {
      if (!acc[curr.email]) {
        acc[curr.email] = [];
      }
      acc[curr.email].push(curr);
      return acc;
    }, {});
  
    useEffect(() => {
      setCompetitionLoading(true);
      const fetchCompetitions = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`,  {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          });
          const data = response.data;
          setCompetitions(data);
          setCompetitionLoading(false);
  
          if (data.length > 0) {
            setRace(data[data.length - 1].id);
          }
        } catch (error) {
          setCompetitionLoading(false);
          console.error("Error fetching competitions:", error);
        }
      };
  
      fetchCompetitions();
    }, [selectedTeam]);
  
    const getDataArchive = () => {
      axios
        .get(`${process.env.REACT_APP_API_DOMAIN}/get_data_archive`, {
          params: {
            email: drivers1.join(","),
            race:race1,
            day:day1,
            team_id: selectedTeam.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        })
        .then((response) => {
          const data = response.data;
          if (data !== null) {
            const sortedData = data.sort((a, b) => a.session_number - b.session_number);
            setRacingData(sortedData);
            if (selectedDrivers.length === 0) {
              getDrivers(true, sortedData);
            }
          }
        })
        .catch((error) => {
          console.error("Query Error:", error.response ? error.response.data : error.message);
        });
    };
    const history = useHistory();
  
    useEffect(() => {
      getDataArchive();
      getDrivers(false);
      

    }, [selectedTeam]);
  
    useEffect(() => {
      if (race !== "") {
        getDataArchive();
      }
    }, [ drivers1, race1, day1]);
  
    const handleDeleteRow = (id) => {
      setRacingData((prevRacingData) =>
        prevRacingData.filter((row) => row.id !== id)
      );
    };
  
    const updateRows = (updatedRows) => {
      setRacingData((prevRacingData) =>
        prevRacingData.map((row) => updatedRows.find((r) => r.id === row.id) || row)
      );
    };
    console.log('Drivers Archive Collpasable:', drivers1);
    console.log('Race Archive Collpasable:', race1);
    console.log('Day Archive Collpasable:', day1);
  
    return (
      day1 && race1 && drivers1
        ?
      (<React.Fragment>
        <PageHeader
          drivers={drivers1}
          onChange={onDriverChange}
          race={race1}
          onDayChange={onDayChange}
          onRaceChange={onRaceChange}
          day={day1}
          competitions={competitions}
          competitionLoading={competitionLoading}
        />
         {racingData?.length === 0 ? (
  <div className="grid-demo-no-data-container" style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
    <p className="grid-demo-no-data-text" style={{ marginBottom: '20px' }}>No data inserted yet!</p>
    <Chip
      label="Insert data"
      onClick={() => history.push('/racing-space/racing-data')}
      style={{ cursor: 'pointer', backgroundColor: '#007BFF', color: 'white' }}
    />
  </div>
) : (
  <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow></TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(groupedData).map((email, index) => (
          <Row
            key={index}
            rows={groupedData[email]}
            onDelete={handleDeleteRow}
            updateRows={updateRows}
          />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)}
      </React.Fragment>)
      :
      (  <div className="grid-demo-no-data-container">
        <p className="grid-demo-no-data-text">Please select a day</p>
        <i className="grid-demo-info-icon fas fa-info-circle"></i>
      </div>)
      
    );
  };
  
  




export default observer(CollapsibleTable);