import '@fontsource-variable/inter';
import '@fontsource/roboto';

import { ThemeProvider, createTheme } from "@mui/material/styles";

import React from "react";

const TITLE_FONT = '"Inter", sans-serif'
const TEXT_FONT = '"Roboto", sans-serif'

const ThemeWrapper = ({ children }) => {
  let theme = createTheme({
    palette: {
      common: {
        white: '#fff',
        black: '#000',
      },
      light: {
        main: "#fff",
        light: "#fff",
        contrastText: "#000",
        dark: '#cecece'
      },
      secondary: {
        main: "#6F42C1",
        dark: "#4d2e87",
        light: "#8b67cd",
        contrastText: "#fff"
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '24px',
            textTransform: 'none',
            fontWeight: 700
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "24px",
          },
        },
      }
    },
    typography: {
      fontFamily: [TEXT_FONT, TITLE_FONT].join(","),
      h1: {
        fontFamily: TITLE_FONT,
      },
      h2: {
        fontFamily: TITLE_FONT,
      },
      h3: {
        fontFamily: TITLE_FONT,
      },
      h4: {
        fontFamily: TITLE_FONT,
      },
      h5: {
        fontFamily: TITLE_FONT,
      },
      h6: {
        fontFamily: TITLE_FONT,
      }
    }
  });

  theme = createTheme(theme, {
    components: {
      MuiChartsTooltip: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down("sm")]: {
              maxWidth: 200,
              "& .MuiChartsTooltip-root" : {
                margin: 0
              }
            }
          }
        }
      }
    }
  })

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
