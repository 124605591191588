import "react-toastify/dist/ReactToastify.css";

import { Box, Button, CircularProgress, Container, Typography } from '@mui/material'
import React, { useState } from "react"
import axios from "axios";

import HttpProvider from 'services/HttpProvider'
import NewLogo from 'components/NewLogo.png'
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ActivateAccount({ }) {

  const [loading, setLoading] = useState(false)
  const history = useHistory();
  let query = useQuery();
  let token = query.get('token');

  const handleAddMember = async (user_id, team_id) => {
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/add_member/?role=MGR&team_id=${parseInt(team_id)}&status=ACTIVE&user_id=${user_id}`;
    await axios.post(
      apiUrl,
      {
        role: "DRV",
        team_id: parseInt(team_id),
        status: "ACTIVE",
        user_id: user_id
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      }
    ).then((response) => {
      // handle success
      return response
    }).catch((error) => {
      console.error("Error Api", error);
    });
  };

  const getUser = (access_token) => {
    return HttpProvider.get("/users/me/", {
      headers: {
        Authorization: `Bearer ${access_token}`,
        accept: "application/json",
      }
    }).then(async (userResponse) => {
      debugger
      const userData = userResponse.data;
      await getPendingTeams(access_token, userData.user_id);
      
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("user_data", JSON.stringify(userData));
      return userData
    })
  }

  const getPendingTeams = (access_token, userData) => {
    axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_pending_teams/`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          accept: "application/json",
        }
      }

    ).then((res) => {
      debugger
       
      if (res.data.teams?.length > 0){
        handleAddMember(userData, res.data.teams[0]);
      }

      console.log("Teams pending", res.data)
      return res.data.teams[0]
    }).catch((error) => {
      console.error("Axios Error:", error);
    });
  }

  const activateAccount = () => {
    debugger
    setLoading(true)
    
    return HttpProvider.post('/activate_account/', null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      }
    })
      .then((res) => {
        // redirect to login
        // the user is not enabled
        toast.success("Account successfully activated!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        return getUser(token).then(() => history.push("/pricing-sign-up"))
      })
      .catch((err) => {
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.error('something went wrong', err)
        return err
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Container
      maxWidth={false}
      style={{
        textAlign: "center",
        backgroundColor: "#000",
        minHeight: 'calc(100% - 86px)',
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            p: 4,
            backgroundColor: "#1c1c1e",
            borderRadius: 2,
            maxWidth: 400,
            width: "100%",
            margin: "0 auto"
          }}
        >
          <Typography variant="h4" className='gradient-text'>Activate your account</Typography>
          <Button fullWidth variant="contained" color="secondary" size={"large"} onClick={activateAccount} sx={{
            my: 6,
            alignItems: 'center'
          }}
            disabled={!token}>
            Activate account
            <Box ml={2} hidden={!loading} display={'flex'} alignItems={'center'}>
              <CircularProgress size={26} color='light' />
            </Box>
          </Button>
          <Box textAlign={'center'}>
            <img src={NewLogo} alt="AiMotors logo" width="120px" />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}