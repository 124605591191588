import React, { useRef } from 'react';
import { Typography, Card, Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const pricingOptions = [
  {
    title: 'Growth',
    price: '€0.00',
    postTrialPrice: '€25/month',
    description: 'For drivers racing individually looking to use AiMotor to improve their performance',
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
      'Cancel subscription at any time'
    ],
    buttonLabel: 'Start Now',
  },
  {
    title: 'Pro',
    price: '€0.00',
    postTrialPrice: '€55/month',
    description: 'For drivers seeking to use the best AiMotor AI model to compare and improve their performance within a single, exclusive group of drivers (up to 5 members)',
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'Create One Team (up to 5 members)',
      'Cancel subscription at any time'
    ],
    buttonLabel: 'Start Now',
  },
  {
    title: 'Team',
    price: '€0.00',
    postTrialPrice: '€70/month',
    description: 'For drivers looking to use the best AiMotor AI model to compare and improve their performance with multiple and large groups of drivers',
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'Create Unlimited Teams',
      'Invite Unlimited Team Members',
      'Cancel subscription at any time'
    ],
    buttonLabel: 'Start Now',
  },
];

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5d5fef',
  color: '#fff',
  borderRadius: '25px',
  padding: '10px 20px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#4a4cda',
  },
}));

const WhiteTick = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: '12px',
  height: '12px',
  borderLeft: '2px solid white',
  borderBottom: '2px solid white',
  transform: 'rotate(-45deg)',
  marginRight: '8px',
}));

const PricingComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const tableRef = useRef(null);

  const sliderOptions = {
    slidesPerView: 3,
    initialSlide: 1,
    spaceBetween: 16,
    breakpoints: {
      320: {
        centeredSlides: true,
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      900: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  };

  return (
    <Box
      sx={{
        bgcolor: '#000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
        borderRadius={2}
        width="100%"
      >
        <Typography
          variant="h3"
          style={{ color: '#5d5fef', fontWeight: 'bold', marginBottom: '16px' }}
        >
          Build with AiMotor
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: '#fff',
            marginBottom: '16px',
          }}
        >
          Customized plans to give you exactly what you need. Try one of the three plans{' '}
          <b
            style={{
              background: 'linear-gradient(90deg, #7b5eff, #bd5eff)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            free for the first month
          </b>.
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: '#fff',
            fontSize: '1rem',
            marginBottom: '32px',
            textDecoration: 'underline',
          }}
        >
          You can cancel your subscription at any time!
        </Typography>
      </Box>

      <Box
        as={Swiper}
        {...sliderOptions}
        sx={{
          width: '100%',
          padding: isMobile ? '16px' : '16px 80px',
          display: 'flex',
          alignItems: 'stretch',
          
        }}
      >
        {pricingOptions.map((option, index) => (
          <SwiperSlide key={index} style={{ height: 'auto', maxHeight: '84vh', }}>
            <Card
              sx={{
                backgroundColor: option.title === 'Pro' ? 'rgba(255, 255, 255, 0.9)' : '#1e1e2d',
                color: option.title === 'Pro' ? '#000' : '#fff',
                borderRadius: '15px',
                padding: 1,
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <Typography
                variant="h5"
                style={{ fontWeight: 'bold', marginBottom: '2px' }}
              >
                {option.title}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontStyle: 'italic',
                  fontSize: '0.95rem',
                  marginBottom: '5px',
                  marginInline:15
                  
                }}
              >
                {option.description}
              </Typography>
              <Typography
                variant="h4"
                style={{ color: '#5d5fef', marginBottom: '4px' }}
              >
                {option.price}
              </Typography>
              <Typography variant="body2" style={{ marginBottom: '16px' }}>
                ({option.postTrialPrice} after)
              </Typography>
              <ul
                style={{
                  padding: 0,
                  listStyle: 'none',
                  marginBottom: '16px',
                  textAlign: 'left',
                }}
              >
                {option.features.map((feature, featureIndex) => (
                  <Typography
                    component="li"
                    key={featureIndex}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '3px',
                    }}
                  >
                    <WhiteTick /> {feature}
                  </Typography>
                ))}
              </ul>
              <StyledButton onClick={() => history.push('/sign-up')}>
                {option.buttonLabel}
              </StyledButton>
            </Card>
          </SwiperSlide>
        ))}
      </Box>

      {!isMobile && (<Button
  onClick={() => tableRef.current.scrollIntoView({ behavior: 'smooth' })}
  sx={{
    backgroundColor: '#5d5fef',
    color: '#fff',
    
    borderRadius: '25px',
    padding: '10px 20px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginTop: '40px',
    '&:hover': {
      backgroundColor: '#4a4cda',
    },
  }}
>
  Compare Plans
</Button>)}

{!isMobile && (<Box
  ref={tableRef}
  id="comparison-table"
  sx={{
    overflowX: 'auto',
    width: { xs: '80%', md: '80%' }, // Narrower width on larger screens
    mt: 8,
    mb:6,
    mx: 'auto',
  }}
>
  <table style={{ width: '100%', borderCollapse: 'collapse', color: '#fff' }}>
    <thead>
      <tr>
        <th
          style={{
            border: '1px solid #444',
            padding: '10px',
            backgroundColor: '#2d2d44',
            color: '#fff',
            textAlign: 'center',
          }}
        ></th>
        <th
          style={{
            border: '1px solid #444',
            padding: '10px',
            backgroundColor: '#2d2d44',
            textAlign: 'center',
          }}
        >
          Growth-€25/month
        </th>
        <th
          style={{
            border: '1px solid #444',
            padding: '10px',
            backgroundColor: '#2d2d44',
            textAlign: 'center',
          }}
        >
          Pro-€55/month
        </th>
        <th
          style={{
            border: '1px solid #444',
            padding: '10px',
            backgroundColor: '#2d2d44',
            textAlign: 'center',
          }}
        >
          Team-€70/month
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          colSpan={4}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Generative AI
        </td>
      </tr>
      <tr>
        <td style={{ border: '1px solid #444', padding: '10px' }}>AI model AiMotor 1</td>
        <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>✓</td>
        <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>✓</td>
        <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>✓</td>
      </tr>
      <tr>
        <td style={{ border: '1px solid #444', padding: '10px' }}>AI model AiMotor 2</td>
        <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}></td>
        <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>✓</td>
        <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>✓</td>
      </tr>
      <tr>
        <td
          colSpan={4}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Data Management
        </td>
      </tr>
      {['Data Insertion', 'Data Archive', 'Setup Analytics', 'Driver Analytics'].map(
        (feature, index) => (
          <tr key={index}>
            <td style={{ border: '1px solid #444', padding: '10px' }}>{feature}</td>
            <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>✓</td>
            <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>✓</td>
            <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>✓</td>
          </tr>
        )
      )}
      <tr>
        <td
          colSpan={4}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Racing Team
        </td>
      </tr>
      {[
        'Personal Space',
        'Join Unlimited Teams',
        'Create One Team (up to 5 members)',
        'Create Unlimited Teams',
        'Invite Unlimited Team Members',
      ].map((feature, index) => (
        <tr key={index}>
          <td style={{ border: '1px solid #444', padding: '10px' }}>{feature}</td>
          <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>
            {index < 2 ? '✓' : ''}
          </td>
          <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>
            {index < 3 ? '✓' : ''}
          </td>
          <td style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>✓</td>
        </tr>
      ))}
    </tbody>
  </table>
</Box>)}



    </Box>
  );
};

export default PricingComponent;
