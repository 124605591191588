import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { Height } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const DriverForm = () => {
  const history = useHistory();

  const [lapTime, setLapTime] = useState("");
  const [maxSpeed, setMaxSpeed] = useState(120);
  const [maxRpm, setMaxRpm] = useState(12000);
  const [minRpm, setMinRpm] = useState(12000);
  const [pinion, setPinion] = useState(12);
  const [crown, setCrown] = useState(22);
  const [caster, setCaster] = useState(12);
  const [camber, setCamber] = useState(12);
  const [category, setCategory] = useState("");
  const [hotPressure, setHotPressure] = useState(1.5);
  const [coldPressure, setColdPressure] = useState(1.1);
  const [maxExhaust, setMaxExhaust] = useState(12);
  const [minExhaust, setMinExhaust] = useState(12);
  const [axleType, setAxleType] = useState("M");
  const [axlePosition, setAxlePosition] = useState("1");
  const [oversteer, setOversteer] = useState("0");
  const [understeer, setUndersteer] = useState("0");
  const [curveEnter, setCurveEnter] = useState("1");
  const [slip, setSlip] = useState("0");
  const [heaviness, setHeaviness] = useState("0");
  const [userData, setUserData] = useState(null);
  const [race, setRace] = useState("");
  const [tiresNew, setTiresNew] = useState("0");
  const [chassisNumber, setChassisNumber] = useState("");
  const [engineNumber, setEngineNumber] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState("");
  const [sessionNumber, setSessionNumber] = useState("");
  const [sessionGroup, setSessionGroup] = useState("1");
  const [carburetor, setCarburetor] = useState("Tilltson");
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    console.log("Fetching competitions and user data...");
    axios
      .all([
        axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/get_competitions/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/get_last_race_data/?email=${driver}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        ),
      ])
      .then(
        axios.spread((competitionsRes, userDataRes) => {
          const compets = competitionsRes.data;
          const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
          const raceData = userDataRes.data;

          setCompetitions(compets);
          setUserData(parsedUserData);


          if (raceData) {
            setAxleType(raceData.axle_type);
            setAxlePosition(raceData.axle_position);
            // setSessionNumber(raceData.session_number);
            setSessionGroup(raceData.session_group);
            setCarburetor(raceData.carburetor);
            setMaxSpeed(raceData.max_speed);
            setMaxRpm(raceData.max_rpm);
            setMinRpm(raceData.min_rpm);
            setPinion(raceData.pinion);
            setCrown(raceData.crown);
            setCaster(raceData.caster);
            setCamber(raceData.camber);
            setCategory(raceData.category);
            setCarburetor(raceData.carburetor);
            setHotPressure(raceData.pressure_hot);
            setMaxExhaust(raceData.max_exhaust);
            setMinExhaust(raceData.min_exhaust);
            setOversteer(raceData.oversteer);
            setUndersteer(raceData.understeer);
            setCurveEnter(raceData.curve_enter);
            setSlip(raceData.slip);
            setHeaviness(raceData.heaviness);
            setTiresNew(raceData.tires_new);
            setChassisNumber(raceData.chassis_number);
            setEngineNumber(raceData.engine_number);
            setColdPressure(raceData.pressure_cold);
            setRace(raceData.race);
          }
        })
      )
      .catch((error) => {
        console.error("Error while fetching data:", error);
      });
  }, [driver]);

  

  const performQuery = () => {
    if (lapTime === "") {
      toast.error("Please insert the lap time!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    } else {
      setLoading(true);
      const currentDate = new Date();
      let apiUrl = `${process.env.REACT_APP_API_DOMAIN}/insert_race_data/?axle_position=${axlePosition}&axle_type=${axleType}&camber=${camber}&caster=${caster}&carburetor=${carburetor}&category=${category}&chassis_number=${chassisNumber}&crown=${crown}&curve_enter=${curveEnter}&email=${userData.email}&heaviness=${heaviness}&laptime=${lapTime}&max_rpm=${maxRpm}&min_rpm=${minRpm}&max_speed=${maxSpeed}&oversteer=${oversteer}&pinion=${pinion}&pressure_cold=${coldPressure}&pressure_hot=${hotPressure}&team=${userData.team}&understeer=${understeer}&engine_number=${engineNumber}&max_exhaust=${maxExhaust}&min_exhaust=${minExhaust}&slip=${slip}&tires_new=${tiresNew}&session_number=${sessionNumber}&session_group=${sessionGroup}&comment=${comment}&race=${race}`;
      if (userData) {
        // const apiUrl = `https://aimotor.eu/api/insert_data/?axle_position=${axlePosition}&axle_type=${axleType}&camber=${camber}&caster=${caster}&category=${category}&crown=${crown}&curve_enter=${curveEnter}&email=${userData.email}&heaviness=${heaviness}&laptime=${lapTime}&max_rpm=${maxRpm}&max_speed=${maxSpeed}&oversteer=${oversteer}&pinion=${pinion}&pression_cold=${coldPressure}&pression_hot=${hotPressure}&rain=${rain}&slip=${slip}&team=${userData.team}&temperature=${temperature}&tires_type=${tiresType}&track=${location}&understeer=${understeer}&chassis_number=${chassisNumber}&engine_number=${engineNumber}&max_exhaust=${maxExhaust};&min_exhaust=${minExhaust};&session_name=${sessionName}&session_type=${sessionType};&slip=${slip};&tyers_new=${tiresNew}`;
        if (JSON.parse(localStorage.getItem("user_data")).role == "MGR") {
          apiUrl = `${process.env.REACT_APP_API_DOMAIN}/insert_race_data/?axle_position=${axlePosition}&axle_type=${axleType}&camber=${camber}&caster=${caster}&carburetor=${carburetor}&category=${category}&chassis_number=${chassisNumber}&crown=${crown}&curve_enter=${curveEnter}&email=${driver}&heaviness=${heaviness}&laptime=${lapTime}&max_rpm=${maxRpm}&min_rpm=${minRpm}&max_speed=${maxSpeed}&oversteer=${oversteer}&pinion=${pinion}&pressure_cold=${coldPressure}&pressure_hot=${hotPressure}&team=${userData.team}&understeer=${understeer}&engine_number=${engineNumber}&max_exhaust=${maxExhaust}&min_exhaust=${minExhaust}&slip=${slip}&tires_new=${tiresNew}&session_number=${sessionNumber}&session_group=${sessionGroup}&comment=${comment}&race=${race}`;
        }
        // http://localhost:8000/
        // http://104.248.45.175:8000
        axios
          .post(
            apiUrl,
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                accept: "application/json",
              },
            }
          )
          .then((response) => {
            const data = response.data;
            history.push("/view-data");
            toast.success("Data inserted correctly!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
            });
          })
          .catch((error) => {
            toast.error("Data not inserted correctly!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
            });
            console.error("Query Error:", error);
          });
      } else {
        console.error("userData is null");
      }
    }
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setDrivers(res.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          performQuery();
        }}
      >
        <Row>
          <Col xs={12} className="mb-3">
            <h3>
              If you do not know about some parameters, please ask your
              telemetry engineer!
            </h3>
          </Col>
          {JSON.parse(localStorage.getItem("user_data")).role == "MGR" && (
            <Col xs={12} sm={6} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Select Driver:</Form.Label>
                <Form.Select
                  name="driver"
                  value={driver}
                  onChange={(event) => setDriver(event.target.value)}
                  required
                  MenuProps={MenuProps}
                >
                  <option key="placeholder" value="">
                    Select Driver
                  </option>
                  {drivers.map((driver) => (
                    <option key={driver["email"]} value={driver["email"]}>
                      {driver["full_name"]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          )}

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Race Weekend:</Form.Label>
              <Stack direction="horizontal" gap={1}>
                <Form.Select
                  name="track"
                  value={race}
                  placeholder="Select Race"
                  onChange={(e) => {
                    setRace(e.target.value);
                  }}
                  required
                >
                  <option value="">Select Race</option>
                  {competitions.map((competition) => (
                    <option
                      key={competition["id"]} // Assuming "id" is unique for each competition
                      value={competition["id"]}
                    >
                      {competition["competition"]} ({competition["track"]})
                    </option>
                  ))}
                </Form.Select>
              </Stack>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Select Category:</Form.Label>
              <Form.Select
                name="category"
                value={category}
                onChange={(event) => {
                  console.log("Selected Category:", event.target.value);
                  setCategory(event.target.value);
                }}
                required
              >
                <option value="">Select Category</option>
                <option value="MINI">Mini</option>
                <option value="JUNIOR">Junior</option>
                <option value="SENIOR">Senior</option>
                <option value="SHIFTER">Shifter</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Select the Session Number:</Form.Label>
              <Form.Select
                name="sessionNumber"
                value={sessionNumber}
                onChange={(event) => setSessionNumber(event.target.value)}
                required
              >
                <option value="">Select Session Number</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Select the Session Group:</Form.Label>
              <Form.Select
                name="sessionGroup"
                value={sessionGroup}
                onChange={(event) => setSessionGroup(event.target.value)}
                required
              >
                <option value="">Session Group</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="F">F</option>
                <option value="G">G</option>
                <option value="H">H</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Number of chassis:</Form.Label>
              <Form.Control
                type=" text"
                name="chassisNumber"
                value={chassisNumber}
                onChange={(event) => setChassisNumber(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Number of engine:</Form.Label>
              <Form.Control
                type="text"
                name="engineNumber"
                value={engineNumber}
                onChange={(event) => setEngineNumber(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="bestLapTimeInput">
              <Form.Label>Lap Time(Minutes:Sec:Millisec):</Form.Label>
              <Form.Control
                required
                type="text"
                name="lapTime"
                value={lapTime}
                onChange={(event) => setLapTime(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="maxSpeedInput">
              <Form.Label>Max Speed (km/h):</Form.Label>
              <Form.Control
                type="number"
                name="maxSpeed"
                value={maxSpeed}b
                onChange={(event) => setMaxSpeed(event.target.value)}
                min="0"
                max="200"
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="MaxRpmInput">
              <Form.Label>MaxRpm:</Form.Label>
              <Form.Control
                type="number"
                name="maxRpm"
                value={maxRpm}
                onChange={(event) => setMaxRpm(event.target.value)}
                min="10000"
                max="20000"
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="MinRpmInput">
              <Form.Label>MinRpm:</Form.Label>
              <Form.Control
                type="number"
                name="minRpm"
                value={minRpm}
                onChange={(event) => setMinRpm(event.target.value)}
                min="100"
                max="20000"
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="pinionInput">
              <Form.Label>Pinion:</Form.Label>
              <Form.Control
                type="number"
                name="pinion"
                value={pinion}
                onChange={(event) => setPinion(event.target.value)}
                min="0"
                max="20"
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="crownInput">
              <Form.Label>Sprocket:</Form.Label>
              <Form.Control
                type="number"
                name="crown"
                value={crown}
                onChange={(event) => setCrown(event.target.value)}
                min="0"
                max="150"
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="camberInput">
              <Form.Label>Camber:</Form.Label>
              <Form.Control
                type="number"
                name="camber"
                value={camber}
                onChange={(event) => setCamber(event.target.value)}
                min="-60"
                max="60"
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="casterInput">
              <Form.Label>Caster:</Form.Label>
              <Form.Control
                type="number"
                name="caster"
                value={caster}
                onChange={(event) => setCaster(event.target.value)}
                min="-60"
                max="60"
                required
              />
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="carburetor">
              <Form.Label>Carburetor:</Form.Label>
              <Form.Select
                name="carburetor"
                value={carburetor}
                onChange={(event) => setCarburetor(event.target.value)}
                required
                placeholder="Carburetor"
              >
                <option value="">Carburetor</option>
                <option value="1">Tilltson (Junior)</option>
                <option value="2">Ibea F3 </option>
                <option value="3">Ibea F6 </option>
                <option value="4">TM </option>
                <option value="5">Tilltson New (Senior)</option>
                <option value="6">Tilltson Old (Senior)</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="pressureInput">
              <Form.Label>Hot Pressure (00.00):</Form.Label>
              <Form.Control
                type="number"
                name="hotPressure"
                value={hotPressure}
                onChange={(event) => setHotPressure(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="pressureInput">
              <Form.Label>Cold Pressure(00.00):</Form.Label>
              <Form.Control
                type="number"
                name="coldPressure"
                value={coldPressure}
                onChange={(event) => setColdPressure(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="axleposition">
              <Form.Label>Axle Position:</Form.Label>
              <Form.Select
                name="axlePosition"
                value={axlePosition}
                onChange={(event) => setAxlePosition(event.target.value)}
                required
              >
                <option value="0">Low</option>
                <option value="1">Medium</option>
                <option value="2">High</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="axleType">
              <Form.Label>Axle Type:</Form.Label>
              <Form.Select
                name="axleType"
                value={axleType}
                onChange={(event) => setAxleType(event.target.value)}
                required
              >
                <option value="S">Soft</option>
                <option value="M">Medium</option>
                <option value="H">Hard</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="pressureInput">
              <Form.Label>Maximum exhaust temperature(Celsius):</Form.Label>
              <Form.Control
                type="number"
                name="maxExhaust"
                value={maxExhaust}
                onChange={(event) => setMaxExhaust(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="pressureInput">
              <Form.Label>Minimum exhaust temperature(Celsius):</Form.Label>
              <Form.Control
                type="number"
                name="minExhaust"
                value={minExhaust}
                onChange={(event) => setMinExhaust(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="newTires">
              <Form.Label>New tires?</Form.Label>
              <Form.Select
                name="newTires"
                value={tiresNew}
                onChange={(event) => setTiresNew(event.target.value)}
                required
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="turnEntrySelect">
              <Form.Label>
                During the Turn, How Does it Enter the Curve?
              </Form.Label>
              <Form.Select
                name="curveEnter"
                value={curveEnter}
                onChange={(event) => setCurveEnter(event.target.value)}
                required
              >
                <option value="0">Early</option>
                <option value="2">Late</option>
                <option value="1">Neutral</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="oversteerSelect">
              <Form.Label>Do You Have Oversteer?</Form.Label>
              <Form.Select
                name="oversteer"
                value={oversteer}
                onChange={(event) => setOversteer(event.target.value)}
                required
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="understeerSelect">
              <Form.Label>Do You Have Understeer?</Form.Label>
              <Form.Select
                name="understeer"
                value={understeer}
                onChange={(event) => setUndersteer(event.target.value)}
                required
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="kartSlipSelect">
              <Form.Label>
                Does the Kart Slip in the middle of the curve?
              </Form.Label>
              <Form.Select
                name="kartSlip"
                value={slip}
                onChange={(event) => setSlip(event.target.value)}
                required
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="kartSlipSelect">
              <Form.Label>Does the Kart feel heavy to drive?</Form.Label>
              <Form.Select
                name="heaviness"
                value={heaviness}
                onChange={(event) => setHeaviness(event.target.value)}
                required
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="kartSlipSelect">
              <Form.Label>Additional Notes? (optional)</Form.Label>
              <Form.Control
                as="textarea"
                name="comment"
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                rows={3} // Adjust rows as needed for desired height
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3" controlId="kartSlipSelect">
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
export default DriverForm;
