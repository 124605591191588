import "react-toastify/dist/ReactToastify.css";

import { Engineering, Margin } from "@mui/icons-material";
import { FormControl, FormGroup, Grid, Hidden, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Mmenu, { MenuProps } from '@mui/material/Menu'
import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import DoneIcon from '@mui/icons-material/Done';
import DriverForm from "../DriverForm";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilledInput from '@mui/material/FilledInput';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ViewDataComponent from '../ViewDataComponent';
import axios from "axios";
import { block } from "marked";
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { styled } from '@mui/material/styles';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';

const MIN_SEARCHABLE_ITEMS = 7;

const nullData = [
  {
    axle_position: "",
    axle_type: "",
    camber: "",
    carburetor: "",
    caster: "",
    chassis_number: "",
    crown: "",
    curve_enter: "1",
    engine_number: "",
    heaviness: "0",
    laptime: "",
    max_rpm: "",
    max_speed: "",
    min_rpm: "",
    oversteer: "0",
    pinion: "",
    pressure_cold: "",
    pressure_hot: "",
    slip: "0",
    tires_new: "0",
    understeer: "0",

  }
]

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const InsertDriver = () => {

  const [raceData2, setRaceData2] = useState([]);
  const [raceEngine, setRaceEngine] = useState([]);
  const [raceChassis, setRaceChassis] = useState([]);
  const [raceQuestions, setRaceQuestions] = useState([]);
  const [EditEngine, setEditEngine] = useState(false);
  const [EditChassis, setEditChassis] = useState(false);
  const [EditQuestions, setEditQuestions] = useState(false);
  const [OversteerSelect, setOversteerSelect] = useState(false);
  const [Tires, setTires] = useState(false);
  const [UndersteerSelect, setUndersteerSelect] = useState(false);
  const [Slipping, setSlipping] = useState(false);
  const [Heavy, setHeavy] = useState(false);
  const [Curve, setCurve] = useState(-1);
  const [selectedDriverEmail, setSelectedDriverEmail] = useState(null);
  const [editEngine1, setEditEngine1] = useState(false);
  const [empty, setEmpty] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [insertCarb, setInsertCarb] = useState(false);
  const [competitionLoading, setCompetitionLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);



  const history = useHistory();
  const [selectedDriverApi, setSelectedDriverApi] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [selectedChassisDriver, setSelectedChassisDriver] = useState(null);
  const [selectedQuestionsDriver, setSelectedQuestionsDriver] = useState(null);
  const [lapTime, setLapTime] = useState("");
  const [maxSpeed, setMaxSpeed] = useState(120);
  const [maxRpm, setMaxRpm] = useState(12000);
  const [minRpm, setMinRpm] = useState([1200]); // cambiato in array 
  const [pinion, setPinion] = useState(12);
  const [crown, setCrown] = useState([22]);
  const [caster, setCaster] = useState(12);
  const [camber, setCamber] = useState(12);
  const [category, setCategory] = useState("");
  const [hotPressure, setHotPressure] = useState(1.5);
  const [coldPressure, setColdPressure] = useState(1.1);
  const [maxExhaust, setMaxExhaust] = useState(12);
  const [minExhaust, setMinExhaust] = useState(12);
  const [axleType, setAxleType] = useState("M");
  const [axlePosition, setAxlePosition] = useState("1");
  const [oversteer, setOversteer] = useState("0");
  const [understeer, setUndersteer] = useState("0");
  const [curveEnter, setCurveEnter] = useState("1");
  const [slip, setSlip] = useState("0");
  const [heaviness, setHeaviness] = useState("0");
  const [userData, setUserData] = useState(null);
  const [tiresNew, setTiresNew] = useState("0");
  const [chassisNumber, setChassisNumber] = useState("");
  const [engineNumber, setEngineNumber] = useState("da togliere solo prova");
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState([]);
  const [upDriver, setUpDriver] = useState([]);
  const [sessionNumber, setSessionNumber] = useState("");
  const [selectedSessionNumber, setSelectedSessionNumber] = useState("");
  const [sessionGroup, setSessionGroup] = useState("1");
  const [carburetor, setCarburetor] = useState("Tilltson");
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [competitions, setCompetitions] = useState([]);
  const [minRpmInput, setMinRpmInput] = useState('');
  const [fields, setEngineFields] = useState([]);
  const [chassisfields, setChassisFields] = useState([]);
  const [questionsfields, setQuestionsFields] = useState([]);
  const [selectedDay, setSelectedDay] = useState(false);
  const [teamCarburetors, setTeamCarburetors] = useState([]);
  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  const [lastSelectedDriver, setLastSelectedDriver] = useState(null);
  const [selectOpen, setSelectOpen] = useState(false);
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;
  const isDriver = selectedTeam.role == "DRV"

  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
  const myEmail = parsedUserData.email
  console.log("is driver:", isDriver)
  const handleChipClick = (surname) => {
    setSelectedDriverEmail(surname);
  };

  const handleInputClick = (event) => {

    setInsertCarb(!insertCarb)
  };

  const handleEditRace = () => {
    setEditMode((prevMode) => !prevMode);
  };

  const handleDeleteCompetition = (competitionId) => {
    axios
      .delete(`${process.env.REACT_APP_API_DOMAIN}/remove_competition/`, {
        params: { competition_id: competitionId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        // toast.success("Competition deleted successfully!");
        setCompetitions((prev) =>
          prev.filter((competition) => competition.id !== competitionId)
        );
      })
      .catch((error) => {
        toast.error("Failed to delete competition!");
        console.error(error);
      });
  };


  const handleButtonClick = () => {

    setSelectedDriverEmail(
      driver.length > 0 ? driver[0] : null // Reset to first driver's email
    );
    setEditEngine(prevState => !prevState);
    // Toggle EditEngine state
    if (raceData2.length > 0) {
      handleDriverButtonClick(raceData2[0].email);
    } else {
      setSelectedDriver(null)
    }


  };
  const handleChassisClick = () => {

    setSelectedDriverEmail(
      driver.length > 0 ? driver[0] : null // Reset to first driver's email
    );
    setEditChassis(prevState => !prevState);
    // Toggle EditEngine state
    if (raceData2.length > 0) {
      handleDriverChassisButtonClick(raceData2[0].email);
    } else {
      setSelectedChassisDriver(null)
    }
  };

  const handleQuestionsClick = () => {

    setSelectedDriverEmail(
      driver.length > 0 ? driver[0] : null // Reset to first driver's email
    );
    setEditQuestions(prevState => !prevState);
    // Toggle EditEngine state
    if (raceData2.length > 0) {
      handleDriverQuestionsButtonClick(raceData2[0].email);
    } else {
      setSelectedQuestionsDriver(null)
    }

  };


  console.log("RaceData 2 ", raceData2);
  console.log("RaceEngine", raceEngine)
  console.log("RaceChassis", raceChassis)
  console.log("RaceQuestions", raceQuestions)


  const handleEarlyClick = () => {
    const index = raceData2.findIndex(driver => driver.email === selectedQuestionsDriver.email);
    raceData2[index].curve_enter = "0";
    selectedQuestionsDriver.curve_enter = "0";
    raceQuestions[index]["CURVE ENTER"] = '0';
    setCurve(0);

  };
  const handleNeutralClick = () => {
    const index = raceData2.findIndex(driver => driver.email === selectedQuestionsDriver.email);
    raceData2[index].curve_enter = "1";
    selectedQuestionsDriver.curve_enter = "1";
    raceQuestions[index]["CURVE ENTER"] = '1';
    setCurve(1);
  };
  const handleLateClick = () => {
    const index = raceData2.findIndex(driver => driver.email === selectedQuestionsDriver.email);
    raceData2[index].curve_enter = "2";
    selectedQuestionsDriver.curve_enter = "2";
    raceQuestions[index]["CURVE ENTER"] = '2';
    setCurve(2);
  };

  const handleButton1Click = () => {

    setEditChassis(!EditChassis);
  };
  const handleButton2Click = () => {
    setEditQuestions(!EditQuestions);
  };
  const handleOVClick = () => {
    const index = raceData2.findIndex(driver => driver.email === selectedQuestionsDriver.email);
    if (!OversteerSelect) {
      raceData2[index].oversteer = "1";
      raceQuestions[index]["OVERSTEER"] = '1';
      selectedQuestionsDriver.oversteer = "1";
    } else {
      raceData2[index].oversteer = "0";
      raceQuestions[index]["OVERSTEER"] = '0';
      selectedQuestionsDriver.oversteer = "0";
    }
    setOversteerSelect(!OversteerSelect);

  };
  const handleTiresClick = () => {
    const index = raceData2.findIndex(driver => driver.email === selectedQuestionsDriver.email);
    if (!Tires) {
      raceData2[index].tires_new = "1";
      raceQuestions[index]["NEW TIRES"] = '1';
      selectedQuestionsDriver.tires_new = "1";
    } else {
      raceData2[index].tires_new = "0";
      selectedQuestionsDriver.tires_new = "0";
      raceQuestions[index]["NEW TIRES"] = '0';
    }
    setTires(!Tires);
  };
  console.log("The race is: ", race)
  const handleUSClick = () => {
    const index = raceData2.findIndex(driver => driver.email === selectedQuestionsDriver.email);
    if (!UndersteerSelect) {
      raceData2[index].understeer = "1";
      raceQuestions[index]["UNDERSTEER"] = '1';
      selectedQuestionsDriver.understeer = "1";
    } else {
      raceData2[index].understeer = "0";
      raceQuestions[index]["UNDERSTEER"] = '0';
      selectedQuestionsDriver.understeer = "0";
    }
    setUndersteerSelect(!UndersteerSelect);
  };
  const handleSlipClick = () => {
    const index = raceData2.findIndex(driver => driver.email === selectedQuestionsDriver.email);
    if (!Slipping) {
      raceData2[index].slip = "1";
      raceQuestions[index]["KART SLIPPING"] = '1';
      selectedQuestionsDriver.slip = "1";
    } else {
      raceData2[index].slip = "0";
      raceQuestions[index]["KART SLIPPING"] = '0';
      selectedQuestionsDriver.slip = "0";
    }
    setSlipping(!Slipping);
  };
  const handleHeavyClick = () => {
    const index = raceData2.findIndex(driver => driver.email === selectedQuestionsDriver.email);
    if (!Heavy) {
      raceData2[index].heaviness = "1";
      raceQuestions[index]["HEAVINESS"] = '1';
      selectedQuestionsDriver.heaviness = "1";
    } else {
      raceData2[index].heaviness = "0";
      raceQuestions[index]["HEAVINESS"] = '0';
      selectedQuestionsDriver.heaviness = "0";
    }
    setHeavy(!Heavy);
  };






  const textStyle = {
    color: 'white', // Colore del testo
    fontFamily: 'Arial, sans-serif', // Tipo di carattere
    fontSize: '106px', // Dimensioni del testo
    margin: '10px 0' // Margine superiore e inferiore
  };

  const handleCarbInsert = (event) => {
    const inputValue = event.target.value;
  }




  const handleLapTimeChange = (event, email) => {
    const inputValue = event.target.value;
    setLapTime(inputValue); // Aggiorna lo stato di lapTime

    // Trova l'indice dell'elemento in raceData2 corrispondente all'email del driver
    const driverIndex = raceData2.findIndex(driver => driver.email === email);

    if (driverIndex !== -1) { // Assicurati che l'indice sia valido
      // Crea una copia della lista raceData2
      const updatedRaceData2 = [...raceData2];

      // Aggiorna il valore di lap_time nell'elemento corrispondente
      updatedRaceData2[driverIndex].laptime = inputValue;

      // Aggiorna lo stato di raceData2 con la nuova lista aggiornata
      setRaceData2(updatedRaceData2);
    }
  };

  const isValidLapTime = (value) => {
    // Regex per verificare il formato "nn:nn:nn"
    const lapTimeRegex = /^\d{2}:\d{2}:\d{2}$/;
    const lapTimeRegex1 = /^\d{1}:\d{2}:\d{2}$/;
    const lapTimeRegex2 = /^\d{1}.\d{2}.\d{2}$/;
    const lapTimeRegex3 = /^\d{2}.\d{2}.\d{2}$/;
    const lapTimeRegex4 = /^\d{2}:\d{2}$/;
    const lapTimeRegex5 = /^\d{1}:\d{2}$/;
    const lapTimeRegex6 = /^\d{2}.\d{2}$/;
    const lapTimeRegex7 = /^\d{1}.\d{2}$/;
    const lapTimeRegex8 = /^\d{1}.\d{1}$/;
    const lapTimeRegex9 = /^\d{2}.\d{1}$/;
    const lapTimeRegex10 = /^\d{1}:\d{1}$/;
    const lapTimeRegex11 = /^\d{2}:\d{1}$/;
    return (lapTimeRegex.test(value) || lapTimeRegex1.test(value) || lapTimeRegex2.test(value) || lapTimeRegex3.test(value) || lapTimeRegex4.test(value) || lapTimeRegex5.test(value) || lapTimeRegex6.test(value) || lapTimeRegex7.test(value) || lapTimeRegex8.test(value) || lapTimeRegex9.test(value) || lapTimeRegex10.test(value) || lapTimeRegex11.test(value));
  };
  console.log("Laptime validation", isValidLapTime);


  useEffect(() => {
    console.log("Fetching competitions and user data...");
    setCompetitionLoading(true);
    const driverString = driver.join(',');
    axios
      .all([
        axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/get_last_race_data/?email=${driverString}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        ),
      ])


      .then(

        axios.spread((competitionsRes, userDataRes) => {

          console.log("Competitions response:", competitionsRes.data);
          console.log("User data response:", userDataRes.data);

          const compets = competitionsRes.data;
          const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
          const raceData = userDataRes.data;

          console.log("Parsed user data:", parsedUserData);
          console.log("Race data:", raceData);

          setCompetitions(compets);
          setUserData(parsedUserData);
          setCompetitionLoading(false);

          if (raceData) {
            setAxleType(raceData.axle_type);
            setAxlePosition(raceData.axle_position);
            //setSessionNumber(raceData.session_number);
            setSessionGroup(raceData.session_group);
            setCarburetor(raceData.carburetor);
            setMaxSpeed(raceData.max_speed);
            setMaxRpm(raceData.max_rpm);
            setMinRpm([raceData.min_rpm]); // ho cambiato e messo un array 
            setPinion(raceData.pinion);
            setCrown([raceData.crown]);
            setCaster(raceData.caster);
            setCamber(raceData.camber);
            setCategory(raceData.category);
            setCarburetor(raceData.carburetor);
            setHotPressure(raceData.pressure_hot);
            setMaxExhaust(raceData.max_exhaust);
            setMinExhaust(raceData.min_exhaust);
            setOversteer(raceData.oversteer);
            setUndersteer(raceData.understeer);
            setCurveEnter(raceData.curve_enter);
            setSlip(raceData.slip);
            setHeaviness(raceData.heaviness);
            setTiresNew(raceData.tires_new);
            setChassisNumber(raceData.chassis_number);
            setEngineNumber(raceData.engine_number);
            setColdPressure(raceData.pressure_cold);
            setRaceData2(raceData.map((item) => {



              const isEmailInRaceData = raceData2.some(raceItem => raceItem.email === item.email);
              if (!isEmailInRaceData) {
                const { min_exhaust, max_exhaust, ...rest } = item;
                const keys = Object.keys(rest);
                if (keys.length === 1) {
                  const nullDataValues = nullData.reduce((acc, curr) => ({ ...acc, ...curr }), {});
                  return { ...rest, ...nullDataValues };
                } else {
                  return rest;
                }
              } else {
                const previousData = raceData2.find(raceItem => raceItem.email === item.email);
                const keys = Object.keys(previousData);
                const isEmpty = keys.some(key => previousData[key] === "");
                if (isEmpty) {
                  const { min_exhaust, max_exhaust, ...rest } = item;
                  const nullDataValues = nullData.reduce((acc, curr) => ({ ...acc, ...curr }), {});
                  return { ...rest, ...nullDataValues };
                } else {
                  return item;
                }

              }
            }));

            setRaceEngine(raceData.map(data => {
              return {
                email: data.email,
                surname: data?.surname || data.full_name,
                'Max RPM': data.max_rpm,
                'Min RPM': data.min_rpm,
                ' Max Speed': data.max_speed,
                'Pinion': data.pinion,
                'Carburetor': data.carburetor,
                'Sprocket': data.crown,
                'Engine Number': data.engine_number,


              }

            }));
            setRaceChassis(raceData.map(data => {
              return {
                email: data.email,
                surname: data?.surname || data.full_name,
                'Number of Chassis': data.chassis_number,
                'Hot Pressure': data.pressure_hot,
                'Cold Pressure': data.pressure_cold,
                'Axle Position': data.axle_position,
                'Axle Type': data.axle_type,
                'Camber': data.camber,
                'Caster': data.caster,

              }

            }));
            setRaceQuestions(raceData.map(data => {
              return {
                email: data.email,
                surname: data?.surname || data.full_name,
                'OVERSTEER': data.oversteer,
                'UNDERSTEER': data.understeer,
                'KART SLIPPING': data.slip,
                'CURVE ENTER': data.curve_enter,
                'HEAVINESS': data.heavyness,
                'NEW TIRES': data.tires_new,


              }

            }));



          }
        })
      )
      .catch((error) => {
        setCompetitionLoading(false)
        console.error("Error while fetching data:", error);
      });
  }, [, driver, upDriver, selectedTeam]);

  useEffect(() => {
    // Aggiornare l'array dei campi del motore
    const fieldsToExclude = ['email', 'surname'];
    if (raceEngine.length > 0) {
      setEngineFields(Object.keys(raceEngine[0]).filter(field => !fieldsToExclude.includes(field)));
    }
  }, [raceEngine]);

  useEffect(() => {
    // Aggiornare l'array dei campi del motore
    const fieldsToExclude = ['email', 'surname'];
    if (raceChassis.length > 0) {
      setChassisFields(Object.keys(raceChassis[0]).filter(field => !fieldsToExclude.includes(field)));
    }
  }, [raceChassis]);

  useEffect(() => {
    // Aggiornare l'array dei campi del motore
    const fieldsToExclude = ['email', 'surname'];
    if (raceQuestions.length > 0) {
      setQuestionsFields(Object.keys(raceQuestions[0]).filter(field => !fieldsToExclude.includes(field)));
    }

  }, [raceQuestions]);

  console.log("driver selezionati", driver);

  const prepairQuery = () => {
    setSubmitted(true)

    raceData2.forEach((raceData) => {
      performQuery(raceData)
    })
  }


  const performQuery = (singleRace) => {
    setIsLoading(true)
    if (lapTime === "") {
      toast.error("Please insert the lap time!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      setIsLoading(false)
    }
    if (sessionNumber === "") {
      toast.error("Please insert the session number", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      setIsLoading(false)
    }
    if (raceData2.some(item => {
      const emptyAttribute = Object.keys(item).find(key => item[key] === "" || ((key == "axle_position" || key == "camber" || key == 'caster' || key == 'crown' || key == 'max_rpm' || key == 'max_speed' || key == 'min_rpm' || key == 'pinion' || key == 'pressure_cold' || key == 'pressure_hot') && isNaN(item[key])));
      const emptyCurrent = Object.keys(singleRace).find(key => singleRace[key] === "" || ((key == "axle_position" || key == "camber" || key == 'caster' || key == 'crown' || key == 'max_rpm' || key == 'max_speed' || key == 'min_rpm' || key == 'pinion' || key == 'pressure_cold' || key == 'pressure_hot') && isNaN(singleRace[key])));
      if (emptyAttribute) {
        if (emptyCurrent) {
          toast.error(`Empty value found in "${emptyAttribute}" attribute` + (!isDriver ? ` of ${singleRace.full_name}` : ''), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
          setIsLoading(false)
        }

        setEmpty(emptyAttribute);

        return true; // Restituisci true se trovi un valore vuoto
      }
      return false; // Restituisci false se non trovi valori vuoti
    })) {
    } else {
      setLoading(true);
      const currentDate = new Date();
      let apiUrl = `${process.env.REACT_APP_API_DOMAIN}/insert_race_data/?axle_position=${singleRace.axle_position}&team_id=${selectedTeam.id}&axle_type=${singleRace.axle_type}&camber=${singleRace.camber}&caster=${singleRace.caster}&carburetor=${singleRace.carburetor}&category=${singleRace.category}&chassis_number=${singleRace.chassis_number}&crown=${singleRace.crown}&curve_enter=${singleRace.curve_enter}&email=${singleRace.email}&heaviness=${singleRace.heaviness}&laptime=${singleRace.laptime}&max_rpm=${singleRace.max_rpm}&min_rpm=${singleRace.min_rpm}&max_speed=${singleRace.max_speed}&oversteer=${singleRace.oversteer}&pinion=${singleRace.pinion}&pressure_cold=${singleRace.pressure_cold}&pressure_hot=${singleRace.pressure_hot}&understeer=${singleRace.understeer}&engine_number=${singleRace.engine_number}&max_exhaust=${0}&min_exhaust=${0}&slip=${singleRace.slip}&tires_new=${singleRace.tires_new}&session_number=${sessionNumber}&session_group=${"mock value"}&comment=${comment}&race=${race}`;
      if (userData) {
        // const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/insert_data/?axle_position=${axlePosition}&axle_type=${axleType}&camber=${camber}&caster=${caster}&category=${category}&crown=${crown}&curve_enter=${curveEnter}&email=${userData.email}&heaviness=${heaviness}&laptime=${lapTime}&max_rpm=${maxRpm}&max_speed=${maxSpeed}&oversteer=${oversteer}&pinion=${pinion}&pression_cold=${coldPressure}&pression_hot=${hotPressure}&rain=${rain}&slip=${slip}&team=${userData.team}&temperature=${temperature}&tires_type=${tiresType}&track=${location}&understeer=${understeer}&chassis_number=${chassisNumber}&engine_number=${engineNumber}&max_exhaust=${maxExhaust};&min_exhaust=${minExhaust};&session_name=${sessionName}&session_type=${sessionType};&slip=${slip};&tyers_new=${tiresNew}`;
        if (JSON.parse(localStorage.getItem("user_data")).role == "MGR") {
          apiUrl = `${process.env.REACT_APP_API_DOMAIN}/insert_race_data/?axle_position=${singleRace.axle_position}&team_id=${selectedTeam.id}&axle_type=${singleRace.axle_type}&camber=${singleRace.camber}&caster=${singleRace.caster}&carburetor=${singleRace.carburetor}&category=${singleRace.category}&chassis_number=${singleRace.chassis_number}&crown=${singleRace.crown}&curve_enter=${singleRace.curve_enter}&email=${singleRace.email}&heaviness=${singleRace.heaviness}&laptime=${singleRace.laptime}&max_rpm=${singleRace.max_rpm}&min_rpm=${singleRace.min_rpm}&max_speed=${singleRace.max_speed}&oversteer=${singleRace.oversteer}&pinion=${singleRace.pinion}&pressure_cold=${singleRace.pressure_cold}&pressure_hot=${singleRace.pressure_hot}&understeer=${singleRace.understeer}&engine_number=${singleRace.engine_number}&max_exhaust=${0}&min_exhaust=${0}&slip=${singleRace.slip}&tires_new=${singleRace.tires_new}&session_number=${sessionNumber}&session_group=${"mock value"}&comment=${comment}&race=${race}`;
        }
        // http://localhost:8000/
        // http://104.248.45.175:8000
        axios
          .post(
            apiUrl,
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                accept: "application/json",
              },
            }
          )
          .then((response) => {
            const data = response.data;

            //history.push("/racing-space/archive");

            toast.success("Data inserted correctly!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
            });
            setIsLoading(false)
          })
          .catch((error) => {
            setIsLoading(false)
            toast.error("Data not inserted correctly!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
            });
            console.error("Query Error:", error);
          });
      } else {
        console.error("userData is null");
      }
    }
  };
  const [show, setShow] = useState(false);
  const [emailName, setEmailName] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fieldsToExclude = ['email', 'surname']; // Add any other fields you want to exclude



  const [selectedDriverFullName, setSelectedDriverFullName] = useState('');
  const [value, setValue] = React.useState(0);
  // const handleChange = (event) => {
  //   // If multiple options can be selected, event.target.value will be an array
  //   const selectedDrivers = event.target.value;
  //   setDriver(selectedDrivers);
  // };
  const handleCommentChange = (event, email) => {
    const inputValue = event.target.value;
    setComment(event.target.value);
    // Trova l'indice dell'elemento in raceData2 corrispondente all'email del driver
    const driverIndex = raceData2.findIndex(driver => driver.email === email);

    if (driverIndex !== -1) { // Assicurati che l'indice sia valido
      // Crea una copia della lista raceData2
      const updatedRaceData2 = [...raceData2];

      // Aggiorna il valore del commento nell'elemento corrispondente
      updatedRaceData2[driverIndex].comment = inputValue;

      // Aggiorna lo stato di raceData2 con la nuova lista aggiornata
      setRaceData2(updatedRaceData2);
    }
  };

  const handleChange = (event) => {
    const selectedDrivers = event.target.value;
    console.log("this are the selected drievrs", selectedDrivers);
    const { value } = event.target;
    if (value.length > 0) {
      setLastSelectedDriver(value[value.length - 1]);
    } else {
      setLastSelectedDriver(null);
    }
    selectedDrivers.forEach((driverr, index) => {
      const selectedDriverFullName = driverr; // Extract the selected driver's full name

      setSelectedDriverFullName(selectedDriverFullName); // Set the selected driver's full name
      {/*{driver.oversteer== "0" ? setOversteerSelect(false): setOversteerSelect(true)}
      {driver.understeer== "0" ? setUndersteerSelect(false): setUndersteerSelect(true)}
      {driver.slip== "0" ? setSlipping(false): setSlipping(true)}
      {driver.tires_new== "0" ? setTires(false): setTires(true)}
      {driver.heaviness== "0" ? setHeavy(false): setHeavy(true)}
      {driver.curve_enter== "0" && setCurve(0)}
      {driver.curve_enter== "1" && setCurve(1)}
    {driver.curve_enter== "2" && setCurve(2)}*/}

    })
    if (driver && (selectedDrivers.length > driver?.length)) {
      setUpDriver(selectedDrivers)
    }
    setDriver(selectedDrivers);

    // For simplicity, let's assume only one driver can be selected at a time
    {/*} const selectedDriverFullName = selectedDrivers[0]; // Extract the selected driver's full name
    setDriver(selectedDrivers);
    setSelectedDriverFullName(selectedDriverFullName); // Set the selected driver's full name
      {selectedDrivers[0].oversteer== "0" ? setOversteerSelect(false): setOversteerSelect(true)}
      {selectedDrivers[0].understeer== "0" ? setUndersteerSelect(false): setUndersteerSelect(true)}
      {selectedDrivers[0].slip== "0" ? setSlipping(false): setSlipping(true)}
      {selectedDrivers[0].tires_new== "0" ? setTires(false): setTires(true)}
      {selectedDrivers[0].heaviness== "0" ? setHeavy(false): setHeavy(true)}
      {selectedDrivers[0].curve_enter== "0" && setCurve(0)}
      {selectedDrivers[0].curve_enter== "1" && setCurve(1)}
  {selectedDrivers[0].curve_enter== "2" && setCurve(2)}*/}
  };

  const handleDoneClick = (event) => {
    // Chiudi il menu della Select
    console.log("ciaoo")
    event.stopPropagation();
    setSelectOpen(false);

  };


  const handleDriverButtonClick = (surname) => {

    console.log("Driver Name:", surname);
    const selectedDriverData = raceData2.find(driver => {
      console.log("Driver Email:", driver.email);
      console.log("Full Name:", surname);
      return driver.email === surname;
    });
    console.log("Driver data:", selectedDriverData);
    const maxRpmInput = document.getElementById('max-rpm');
    if (maxRpmInput && selectedDriverData !== undefined) {
      maxRpmInput.value = selectedDriverData.max_rpm;
    }

    setSelectedDriver(selectedDriverData !== undefined ? selectedDriverData : " ");

    // Log the selectedDriverData and selectedDriver

  };
  console.log("Array driver", driver)
  const handleDriverChassisButtonClick = (surname) => {
    console.log("Driver Name:", surname);
    const selectedDriverData = raceData2.find(driver => {
      console.log("Driver Email:", driver.email);
      console.log("Full Name:", surname);
      return driver.email === surname;
    });
    const chassisInput = document.getElementById('chassis-number');
    if (chassisInput && selectedDriverData !== undefined) {
      chassisInput.value = selectedDriverData.chassis_number;
    }
    // Set the selectedDriver state to the found driver object

    setSelectedChassisDriver(selectedDriverData !== undefined ? selectedDriverData : "");

    // Log the selectedDriverData and selectedDriver

  };

  const handleDriverQuestionsButtonClick = (surname) => {
    console.log("Driver Name:", surname);
    const selectedDriverData = raceData2.find(driver => {
      console.log("Driver Email:", driver.email);
      console.log("Full Name:", surname);
      return driver.email === surname;
    });
    const slipInput = document.getElementById('slip');
    if (slipInput && selectedDriverData !== undefined) {
      slipInput.value = selectedDriverData.slip;
    }
    // Set the selectedDriver state to the found driver object

    setSelectedQuestionsDriver(selectedDriverData !== undefined ? selectedDriverData : "");
    if (selectedDriverData !== undefined) {
      { selectedDriverData.oversteer == "0" ? setOversteerSelect(false) : setOversteerSelect(true) }
      { selectedDriverData.understeer == "0" ? setUndersteerSelect(false) : setUndersteerSelect(true) }
      { selectedDriverData.slip == "0" ? setSlipping(false) : setSlipping(true) }
      { selectedDriverData.tires_new == "0" ? setTires(false) : setTires(true) }
      { selectedDriverData.heaviness == "0" ? setHeavy(false) : setHeavy(true) }
      { selectedDriverData.curve_enter == "0" && setCurve(0) }
      { selectedDriverData.curve_enter == "1" && setCurve(1) }
      { selectedDriverData.curve_enter == "2" && setCurve(2) }
    }


    // Log the selectedDriverData and selectedDriver

  };

  console.log("Selected Driver Api:", selectedDriverApi);
  console.log("Selected Driver Engine :", selectedDriver);
  console.log("Selected Driver Chassis :", selectedChassisDriver);
  console.log("Selected Driver Questions :", selectedQuestionsDriver);
  console.log("Session number:", sessionNumber);





  //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        if (showPersonalButton) {
          setDrivers(res.data)
          setDriver([res.data[0].email])

        } else {
          setDrivers(res.data)
          if (isDriver) {
            const myDriver = res.data.find(item => item.email === myEmail);
            setDriver([myDriver?.email])
          }

        }

        console.log("Drivers API Response:", res.data.filter(driver => driver.role === "DRV"));
        setEmailName(res.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [, selectedTeam]);


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_team_carburetors`, {
        params: {
          team_id: selectedTeam.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {

        setTeamCarburetors(res.data)
        console.log("team carburetors", res.data)
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [, selectedTeam]);

  const ITEM_HEIGHT = 80;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  console.log("session", sessionNumber);



  const handleInputChange = (event, setterFunction) => {
    const newValue = [event.target.value];
    setterFunction(newValue);
  };

  const handleDataChange = (e, key, isNum = false) => {
    const value = isNum ? parseFloat(e.target.value) : e.target.value
    if (selectedDriver) {
      const updatedRaceData = raceData2.map(driver => {
        if (driver.email === selectedDriver.email) {
          return {
            ...driver,
            [key]: value,


          };
        } setMaxRpm(e.target.value);
        return driver;
      });

      const updatedSelectedDriver = {
        ...selectedDriver,
        [key]: value,
      };
      setRaceData2(updatedRaceData)
      setRaceEngine(updatedRaceData.map(data => {
        return {
          email: data.email,
          surname: data.surname,
          'Max RPM': data.max_rpm,
          'Min RPM': data.min_rpm,
          ' Max Speed': data.max_speed,
          'Pinion': data.pinion,
          'Carburetor': data.carburetor,
          'Sprocket': data.crown,
          'Engine Number': data.engine_number,
        };
      }));

      setSelectedDriver(updatedSelectedDriver);
    }
  };
  const handleChassisChange = (e, key, isNum = false) => {
    const value = isNum ? parseFloat(e.target.value) : e.target.value
    if (selectedChassisDriver) {
      const updatedRaceData = raceData2.map(driver => {
        if (driver.email === selectedChassisDriver.email) {
          return {
            ...driver,
            [key]: value,


          };
        } setMaxRpm(e.target.value);
        return driver;
      });

      const updatedSelectedDriver = {
        ...selectedChassisDriver,
        [key]: value,
      };
      setRaceData2(updatedRaceData)
      setRaceChassis(updatedRaceData.map(data => {
        return {
          email: data.email,
          surname: data.surname,
          'Number of Chassis': data.chassis_number,
          'Hot Pressure': data.pressure_hot,
          'Cold Pressure': data.pressure_cold,
          'Axle Position': data.axle_position,
          'Axle Type': data.axle_type,
          'Camber': data.camber,
          'Caster': data.caster,


        };
      }));
      setSelectedChassisDriver(updatedSelectedDriver);



    }
  };
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const [open, setOpen] = React.useState("");
  const handleTooltipClose = () => {
    setOpen("");
  };

  const handleTooltipOpen = (id) => {
    setOpen(id);
  };

  const handleSessionClick = (sessionNumber) => {
    setSessionNumber(sessionNumber);
    setSelectedSessionNumber(sessionNumber);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedDay(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSelectDay = (day) => {
    console.log('Selected day:', day);
    setDay(day);
    handleMenuClose();
  };


  return (
    <>
      <div className="insert-container">




        <Root style={{ marginTop: '20px' }}>
          <Box sx={{ flexGrow: 1 }}>

            <Grid container spacing={(showPersonalButton || isDriver) ? 4 : 3} sx={{ flexWrap: 'wrap' }}>

              {/*<Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="component-outlined" sx={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>LAPTIME</InputLabel>
            <OutlinedInput
              id="component-outlined"
              defaultValue={lapTime}
              onChange={handleLapTimeChange}
              label="Min RPM"
            />
          </FormControl>
        </Grid>*/}
              {/* ========== RACE DROPDOWN ========== */}
              <Grid item xs={12} sm={6} md={6} lg={(showPersonalButton || isDriver) ? 4 : 3}>
                <FormControl fullWidth>
                  {competitions.length >= MIN_SEARCHABLE_ITEMS ? (
                    /* ===================================================
                       AUTOCOMPLETE for >=7 Races
                    =================================================== */
                    <Autocomplete
                      // Let it close on outside click:
                      disableCloseOnSelect={false}
                      options={competitions}
                      getOptionLabel={(option) => `${option.competition} (${option.track})`}
                      value={competitions.find((c) => c.id === race) || null}
                      onChange={(event, newValue) => {
                        setRace(newValue ? newValue.id : "");
                      }}
                      // **Important**: exit edit mode when user clicks away
                      onClose={() => setEditMode(false)}
                      renderOption={(props, option) => (
                        <Box
                          {...props}
                          sx={{ display: "flex", alignItems: "center", width: "100%" }}
                        >
                          {/* Name on the left */}
                          <span style={{ marginRight: "auto" }}>
                            {option.competition} ({option.track})
                          </span>

                          {/* Bin on the right if editMode */}
                          {editMode && (
                            <DeleteIcon
                              sx={{ color: "red", cursor: "pointer" }}
                              style={{ marginLeft: "auto" }}
                              onMouseDown={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleDeleteCompetition(option.id);
                              }}
                            />
                          )}
                        </Box>
                      )}
                      PaperComponent={(paperProps) => (
                        <Paper
                          {...paperProps}
                          elevation={3}
                          onMouseDown={(e) => e.stopPropagation()}
                          style={{ backgroundColor: "#fff" }}
                        >
                          {paperProps.children}
                          {/* Insert Competition & Edit side by side */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: 2,
                              mt: 2,
                              mb: 1,
                            }}
                          >
                            <Button
                              variant="contained"
                              onMouseDown={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                history.push("/racing-space/insert-competition");
                              }}
                            >
                              Insert Competition
                            </Button>

                            {competitions.length > 0 && (
                              <Button
                                variant="contained"
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setEditMode(!editMode);
                                }}
                                // Show pencil or close depending on editMode
                                startIcon={editMode ? <CloseIcon /> : <EditIcon />}
                              >
                                {editMode ? "Stop Editing" : "Edit"}
                              </Button>
                            )}
                          </Box>
                        </Paper>
                      )}
                      noOptionsText="No competitions found."
                      renderInput={(params) => (
                        <TextField {...params} label="Race" placeholder="Search race..." />
                      )}
                    />
                  ) : (
                    /* ===================================================
                       SELECT for <7 Races
                    =================================================== */
                    <FormControl fullWidth>
                      <InputLabel id="race-select-label">Race</InputLabel>
                      <Select
                        labelId="race-select-label"
                        id="race-select"
                        value={race}
                        label="Race"
                        onChange={(e) => {
                          setRace(e.target.value);
                        }}
                        // **Important**: exit edit mode when user clicks away
                        onClose={() => setEditMode(false)}
                        MenuProps={{
                          PaperProps: {
                            onMouseDown: (e) => e.stopPropagation(),
                          },
                        }}
                      >
                        {/* List of competitions, with bins if editMode */}
                        {competitions.map((competition) => (
                          <MenuItem key={competition.id} value={competition.id}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {/* Name on the left */}
                              <span style={{ marginRight: "auto" }}>
                                {competition.competition} ({competition.track})
                              </span>

                              {/* Bin on the right if editMode */}
                              {editMode && (
                                <DeleteIcon
                                  sx={{ color: "red", cursor: "pointer" }}
                                  style={{ marginLeft: "auto" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteCompetition(competition.id);
                                  }}
                                />
                              )}
                            </Box>
                          </MenuItem>
                        ))}

                        {/* Insert Competition & Edit side by side */}
                        <MenuItem disableRipple sx={{ justifyContent: "center" }}>
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                history.push("/racing-space/insert-competition");
                              }}
                            >
                              Insert Competition
                            </Button>
                            {competitions.length > 0 && (
                              <Button
                                variant="contained"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditMode(!editMode);
                                }}
                                // Show pencil or close depending on editMode
                                startIcon={editMode ? <CloseIcon /> : <EditIcon />}
                              >
                                {editMode ? "Stop Editing" : "Edit"}
                              </Button>
                            )}
                          </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </FormControl>
              </Grid>

              {/* ========== DRIVER DROPDOWN (ONLY if not personal/driver) ========== */}
              {!(showPersonalButton || isDriver) && (
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <FormControl fullWidth>
                    {drivers.length >= MIN_SEARCHABLE_ITEMS ? (
                      /* =====================================
                         AUTOCOMPLETE (multiple) for >=7 Drivers
                      ===================================== */
                      <Autocomplete
                        multiple
                        disableCloseOnSelect={true}
                        options={drivers}
                        getOptionLabel={(option) => option.surname}
                        value={drivers.filter((d) => driver.includes(d.email))}
                        onChange={(event, newValue) => {
                          setDriver(newValue.map((obj) => obj.email));
                        }}
                        // no bins & no edit mode => no onClose logic needed
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Driver"
                            placeholder="Search driver..."
                          />
                        )}
                      />
                    ) : (
                      /* =====================================
                         SELECT multiple for <7 Drivers
                         (No Edit button, No Insert Driver button)
                      ===================================== */
                      <FormControl fullWidth>
                        <InputLabel id="driver-select-label">Driver</InputLabel>
                        <Select
                          labelId="driver-select-label"
                          id="driver-select"
                          multiple
                          value={driver}
                          label="Driver"
                          onChange={handleChange}
                          open={selectOpen}
                          onClose={() => setSelectOpen(false)}
                          onOpen={() => setSelectOpen(true)}
                          renderValue={(selected) => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                              {selected.map((value) => {
                                const found = emailName.find((dataItem) => dataItem.email === value);
                                return <Chip key={value} label={found ? found.surname : value} />;
                              })}
                            </Box>
                          )}
                        >
                          {/* Plain list, no bins, no buttons */}
                          {drivers.map((drv) => (
                            <MenuItem key={drv.id} value={drv.email}>
                              {drv.surname}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={6} lg={(showPersonalButton || isDriver) ? 4 : 3}  >
                <Accordion defaultExpanded={driver.length > 0} sx={{ position: 'sticky', top: 0 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ height: 60 }}
                  >
                    <Typography>LAPTIME  (min.sec.hund)  </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>

                      {driver.map((value, index) => {

                        const selectedName = emailName.find(dataItem => dataItem.email === value);
                        const name = selectedName ? selectedName.surname : '';
                        return (
                          <>
                            <FormControl variant="outlined" sx={index !== 0 ? { marginTop: 1 } : {}} fullWidth>
                              <InputLabel htmlFor="component-outlined" sx={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>{name} (ex: 1.53.99)</InputLabel>
                              <OutlinedInput
                                id="component-outlined"
                                defaultValue={""}
                                error={lapTime == "" && submitted}
                                onChange={(event) => handleLapTimeChange(event, value)}
                                label={`${name} (ex: 00.53.99)-`}
                              />
                            </FormControl>
                          </>
                        );
                      })}


                    </>

                  </AccordionDetails>
                </Accordion>

              </Grid>


              <Grid item xs={12} sm={6} md={6} lg={(showPersonalButton || isDriver) ? 4 : 3} >
                <p1 style={{ color: 'black', fontSize: '18px', fontWeight: 'lighter' }}>
                  Session Number:
                </p1>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {[1, 2, 3, 4, 5, 6].map((sessionNumber) => (
                    <Chip onClick={() => handleSessionClick(sessionNumber)} key={sessionNumber} label={sessionNumber} color="default" size="medium" variant={sessionNumber === selectedSessionNumber ? "contained" : "outlined"} />
                  ))}
                </Box>
              </Grid>

            </Grid>
          </Box>


          <hr style={{ color: 'black' }} aria-hidden="true" />

          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', }}>


            <p1 xs={12} style={{ fontSize: '18px', bottom: '-20px' }}>ENGINE</p1>
            {!editEngine1 ? (
              <Button
                variant="contained"
                onClick={() => {
                  handleButtonClick();
                  setEditEngine1(true);
                }}
                style={{ alignSelf: 'flex-end' }}
              >
                Edit
              </Button>
            ) : (

              <Grid container spacing={1} style={{ marginBottom: 20 }}>
                <Grid container item xs justifyContent={'center'} style={{ gap: "2%" }}>
                  {driver.map((surname) => {
                    const selectedName = emailName.find(dataItem => dataItem.email === surname);
                    const name = selectedName ? selectedName.surname : '';
                    return (
                      <Grid item key={surname} style={{ justifyContent: 'center', }}>
                        <Chip
                          variant={selectedDriver ? (surname === selectedDriver.email ? "contained" : "outlined") : "outlined"}
                          onClick={() => handleDriverButtonClick(surname)}
                          label={name}
                          sx={{ mb: { xs: 2, lg: 0 } }}

                        >

                        </Chip>
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid item xs={'auto'}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleButtonClick();
                      setEditEngine1(false);
                    }}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    Close edit
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>


          <Grid container spacing={1}>
            {EditEngine && selectedDriver && (
              <>
                <Grid container spacing={3}  >

                  <Grid item xs={12} sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="max-rpm" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>MAX RPM</InputLabel>
                      <OutlinedInput error={(isNaN(selectedDriver.max_rpm) || selectedDriver.max_rpm == "") && submitted} id="max-rpm" type="number" inputProps={{ min: 0 }} label="MAX RPM" defaultValue={selectedDriver.max_rpm} onChange={(event) => handleDataChange(event, 'max_rpm', true)} />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="min_rpm" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>MIN RPM</InputLabel>
                      {console.log("Default Min RPM:", minRpm)}
                      <OutlinedInput error={(isNaN(selectedDriver.min_rpm) || selectedDriver.min_rpm == "") && submitted} type="number" inputProps={{ min: 0 }} id="min_rpm" label="MIN RPM" value={selectedDriver.min_rpm} onChange={(event) => handleDataChange(event, 'min_rpm', true)} />
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="max-speed" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>MAX SPEED (km/h):</InputLabel>
                      <OutlinedInput id="max-speed" error={(isNaN(selectedDriver.max_speed) || selectedDriver.max_speed == "") && submitted} type="number" inputProps={{ min: 0 }} value={selectedDriver.max_speed} label="MAX SPEED (km/h):" onChange={(event) => handleDataChange(event, 'max_speed', true)} />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>

                    {!insertCarb ? (
                      <FormControl variant="outlined" style={{ width: '210px' }} fullWidth>
                        <InputLabel htmlFor="carburetor" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>
                          CARBURETOR
                        </InputLabel>
                        <Select
                          id="carburetor"
                          name="carburetor"
                          value={selectedDriver.carburetor}
                          onChange={(event) => handleDataChange(event, 'carburetor')}
                          label="CARBURETOR"
                          required
                          error={selectedDriver.carburetor == "" && submitted}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200, // Set your desired max height here
                                overflowY: 'auto', // Ensure the dropdown scrolls when items exceed max height
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null // Make sure the menu starts from the top
                          }}

                        >


                          {teamCarburetors.map((carb, index) => (
                            <MenuItem key={index} value={carb.carburetor}>{carb.carburetor}</MenuItem>
                          ))}
                          <MenuItem sx={{ justifyContent: 'center' }}>
                            <Chip color='info' size='small' sx={{ width: '70%' }} onClick={handleInputClick} label="add" icon={<AddIcon />} />
                          </MenuItem>

                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="carburetor" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>CARBURETOR</InputLabel>
                        <OutlinedInput id="max-speed" error={selectedDriver.carburetor == "" && submitted} value={selectedDriver.carburetor} label="CARBURETOR" onChange={(event) => handleDataChange(event, 'carburetor')} />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="pinion" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>PINION</InputLabel>
                      <OutlinedInput error={(isNaN(selectedDriver.pinion) || selectedDriver.pinion == "") && submitted} id="pinion" type="number" inputProps={{ min: 0 }} value={selectedDriver.pinion} label="PINION" onChange={(event) => handleDataChange(event, 'pinion', true)} />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="crown" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>SPROCKET</InputLabel>
                      <OutlinedInput error={(isNaN(selectedDriver.crown) || selectedDriver.crown == "") && submitted} id="crown" type="number" inputProps={{ min: -50 }} value={selectedDriver.crown} label="SPROCKET" onChange={(event) => handleDataChange(event, 'crown', true)} />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="engine-number" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>ENGINE NUMBER</InputLabel>
                      <OutlinedInput id="engine-number" error={selectedDriver.engine_number == "" && submitted} value={selectedDriver.engine_number} label="ENGINE NUMBER" onChange={(event) => handleDataChange(event, 'engine_number')} />
                    </FormControl>
                  </Grid>

                </Grid>
              </>
            )}
            {!EditEngine && (
              <>
                <Grid container spacing={0.5} sx={{ marginTop: '-8px', marginLeft: '-30px', marginBottom: '-8px', maxWidth: 1200 }}>
                  {fields.map(field => {
                    // Extract unique values for the current field across all drivers
                    const uniqueValues = [...new Set(raceEngine.map(driver => driver[field]))];

                    return (

                      <Grid item xs={12} sm={6} md={4} sx={{ padding: '8px' }} key={field}>
                        <ul style={{ fontSize: '18px', fontFamily: 'sans-serif', justifyContent: 'center', margin: 0, }}>
                          <span style={{ color: 'black', fontWeight: 'bold', color: 'black' }}>{field}: </span>
                          {uniqueValues.map((value, index) => {

                            // Find the drivers with this value for the current field
                            const driversWithThisValue = raceEngine.filter(driver => driver[field] === value);
                            const driverNames = driversWithThisValue.map(driver => driver.surname).join('   , '); {/*driversWithThisValue.map(driver => driver.surname.substring(0, 4)).join(', ');*/ }

                            // If there's only one unique value for this field, show only the value
                            if ((uniqueValues.length === 1)) {
                              return <span style={{ color: 'black', fontWeight: 300, fontFamily: 'sans-serif' }} key={index}>{value}</span>;

                            }


                            // Otherwise, show the value along with the driver's names

                            return (
                              <>
                                {window.innerWidth >= 1250 && (

                                  <p6 key={index} style={{ display: 'inline-block', color: 'black' }}>
                                    <LightTooltip placement='top' title={driverNames} arrow>
                                      {index > 0} {/* Add comma for all but the first value */}
                                      <div style={{ fontSize: '18px', fontFamily: 'sans-serif', fontWeight: 'normal' }}>
                                        {value}
                                        {uniqueValues.length === index + 1 ? "" : ","}
                                      </div>
                                    </LightTooltip>

                                  </p6>

                                )}
                                {window.innerWidth < 1250 && (

                                  <p6 key={index} style={{ display: 'inline-block', color: 'black' }}>
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "chassis" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >
                                      {index > 0} {/* Add comma for all but the first value */}
                                      <div onClick={() => handleTooltipOpen("chassis" + index + field)} style={{ fontSize: '18px', fontFamily: 'sans-serif', fontWeight: 'normal' }}>

                                        {value}
                                        {uniqueValues.length === index + 1 ? "" : ","}

                                      </div>
                                    </LightTooltip>

                                  </p6>
                                )}
                              </>

                            );


                          })}
                        </ul>
                      </Grid>
                    );
                  })}
                </Grid>



              </>
            )}
          </Grid>


          <hr style={{ color: 'black' }} aria-hidden="true" />
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <p1 style={{ fontSize: '18px', bottom: '-20px' }}>CHASSIS</p1>
            {!EditChassis ? (
              <Button
                variant="contained"
                onClick={() => {
                  handleChassisClick();
                  setEditChassis(true);
                }}
                style={{ alignSelf: 'flex-end' }}
              >
                Edit
              </Button>
            ) : (
              <Grid container spacing={1} style={{ marginBottom: 20 }}>
                <Grid container item xs justifyContent={'center'} style={{ gap: "2%" }}>
                  {driver.map((surname) => {
                    const selectedName = emailName.find(dataItem => dataItem.email === surname);
                    const name = selectedName ? selectedName.surname : '';
                    return (
                      <Grid item key={surname} style={{ justifyContent: 'center', }}>
                        <Chip
                          variant={selectedChassisDriver ? (surname === selectedChassisDriver.email ? "contained" : "outlined") : "outlined"}
                          onClick={() => handleDriverChassisButtonClick(surname)}
                          label={name}
                          sx={{ mb: { xs: 1, lg: 0 } }}
                        >

                        </Chip>
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid item xs={'auto'}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleChassisClick();
                      setEditChassis(false);
                    }}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    Close edit
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
          <Grid container spacing={1}>
            {EditChassis && selectedChassisDriver && (
              <>
                <Grid container spacing={3}>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="chassis-number" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>NUMBER OF CHASSIS</InputLabel>
                      <OutlinedInput id="chassis-number" error={selectedChassisDriver.chassis_number == "" && submitted} defaultValue={selectedChassisDriver.chassis_number} onChange={(event) => handleChassisChange(event, 'chassis_number')} label="NUMBER OF CHASSIS" />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="hot-pressure" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>HOT PRESSURE (00.00):</InputLabel>
                      <OutlinedInput id="hot-pressure" error={selectedChassisDriver.pressure_hot == "" && submitted} type="number" value={selectedChassisDriver.pressure_hot} onChange={(event) => handleChassisChange(event, 'pressure_hot')} label="HOT PRESSURE (00.00):" />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="cold-pressure" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>COLD PRESSURE (00.00):</InputLabel>
                      <OutlinedInput id="cold-pressure" error={selectedChassisDriver.pressure_cold == "" && submitted} type="number" value={selectedChassisDriver.pressure_cold} onChange={(event) => handleChassisChange(event, 'pressure_cold')} label="COLD PRESSURE (00.00):" />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>


                    <FormControl variant="outlined" style={{ width: '210px' }} fullWidth>
                      <InputLabel htmlFor="axle-position" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold', }}>
                        AXLE POSITION

                      </InputLabel>
                      <Select
                        id="axle-position"
                        name="axle-position"
                        value={selectedChassisDriver?.axle_position}
                        onChange={(event) => handleChassisChange(event, 'axle_position', true)}
                        label="AXLE POSITION"
                        placeholder="Axle Position"
                        required

                      >
                        {/*<MenuItem value=""><em>Axle Position</em></MenuItem>*/}
                        <MenuItem value={0}>Low</MenuItem>
                        <MenuItem value="1">Medium</MenuItem>
                        <MenuItem value="2">High</MenuItem>

                      </Select>

                    </FormControl>


                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>

                    <FormControl variant="outlined" style={{ width: '210px' }} fullWidth>
                      <InputLabel htmlFor="axle-type" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold', }}>
                        AXLE TYPE
                      </InputLabel>
                      <Select
                        id="axle-type"
                        name="axle-type"
                        value={selectedChassisDriver?.axle_type}
                        onChange={(event) => handleChassisChange(event, 'axle_type')}
                        label="AXLE TYPE"
                        required
                        error={selectedChassisDriver?.axle_type == "" && submitted}
                      >
                        <MenuItem value=""><em>Axle Type</em></MenuItem>
                        <MenuItem value="S">Soft</MenuItem>
                        <MenuItem value="M">Medium</MenuItem>
                        <MenuItem value="H">Hard</MenuItem>

                      </Select>

                    </FormControl>





                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="camber" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>CAMBER</InputLabel>
                      <OutlinedInput id="camber" error={(isNaN(selectedChassisDriver.camber) || selectedChassisDriver.camber == "") && submitted} type="number" value={selectedChassisDriver.camber} onChange={(event) => handleChassisChange(event, 'camber')} label="CAMBER" />
                    </FormControl>

                  </Grid>
                  <Grid item sx={{ justifyContent: { xs: 'center' }, display: { xs: 'flex', sm: 'block', md: 'block' } }} xs={12} sm={6} md={4}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="caster" style={{ color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold' }}>CASTER</InputLabel>
                      <OutlinedInput type="number" error={(isNaN(selectedChassisDriver.caster) || selectedChassisDriver.caster == "") && submitted} id="caster" value={selectedChassisDriver.caster} onChange={(event) => handleChassisChange(event, 'caster')} label="CASTER" />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
            {!EditChassis && (
              <>
                <Grid container spacing={0.5} sx={{ marginTop: '-8px', marginLeft: '-30px', marginBottom: '-8px', maxWidth: 1200, }}>
                  {chassisfields.map(field => {
                    // Extract unique values for the current field across all drivers
                    const uniqueValues = [...new Set(raceChassis.map(driver => driver[field]))];

                    return (
                      <Grid item xs={12} sm={6} md={4} sx={{ padding: '8px' }} key={field}>
                        <ul style={{ fontSize: '18px', fontFamily: 'sans-serif', justifyContent: 'center', margin: 0, }}>
                          <span style={{ color: 'black', fontWeight: 'bold', color: 'black' }}>{field}: </span>
                          {uniqueValues.map((value, index) => {
                            // Find the drivers with this value for the current field

                            const driversWithThisValue = raceChassis.filter(driver => driver[field] === value);
                            const driverNames = driversWithThisValue.map(driver => driver.surname).join('   | '); {/*driversWithThisValue.map(driver => driver.surname.substring(0, 4)).join(', ');*/ }
                            const isAxPos = field == "Axle Position";
                            const getAxlePosName = (value) => {

                              switch (value) {
                                case 0:
                                  return "Low";
                                case 1:
                                  return "Medium";
                                case 2:
                                  return "High";

                              };
                            }

                            // If there's only one unique value for this field, show only the value
                            if (uniqueValues.length === 1) {
                              return <span style={{ color: 'black', fontWeight: 300, fontFamily: 'sans-serif' }} key={index}>{!isAxPos ? value : getAxlePosName(value)}</span>;
                            }

                            // Otherwise, show the value along with the driver's names
                            return (
                              <>
                                {window.innerWidth >= 1250 && (
                                  <p6 key={index} style={{ display: 'inline-block', color: 'black' }}>
                                    <LightTooltip placement='top' title={driverNames} arrow>
                                      {index > 0} {/* Add comma for all but the first value */}
                                      <div style={{ fontSize: '18px', fontFamily: 'sans-serif', fontWeight: 'normal' }}>
                                        {!isAxPos ? value : getAxlePosName(value)}
                                        {uniqueValues.length === index + 1 ? "" : ","}
                                      </div>
                                    </LightTooltip>

                                  </p6>

                                )}
                                {window.innerWidth < 1250 && (

                                  <p6 key={index} style={{ display: 'inline-block', color: 'black' }}>
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "chassis" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >
                                      {index > 0} {/* Add comma for all but the first value */}
                                      <div onClick={() => handleTooltipOpen("chassis" + index + field)} style={{ fontSize: '18px', fontFamily: 'sans-serif', fontWeight: 'normal' }}>
                                        {!isAxPos ? value : getAxlePosName(value)}
                                        {uniqueValues.length === index + 1 ? "" : ","}

                                      </div>
                                    </LightTooltip>

                                  </p6>
                                )}

                              </>

                            );
                          })}
                        </ul>
                      </Grid>
                    );
                  })}
                </Grid>


              </>
            )}
          </Grid>

          <hr style={{ color: 'black' }} aria-hidden="true" />
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <p1 style={{ fontSize: '18px', bottom: '-20px' }}>DRIVING EXPERIENCE</p1>
            {!EditQuestions ? (
              <Button
                variant="contained"
                onClick={() => {
                  handleQuestionsClick();
                  setEditQuestions(true);
                }}
                style={{ alignSelf: 'flex-end' }}
              >
                Edit
              </Button>
            ) : (
              <Grid container spacing={1} style={{ marginBottom: 20, }}>
                <Grid container item xs justifyContent={'center'} style={{ gap: "2%", }}>
                  {driver.map((surname) => {
                    const selectedName = emailName.find(dataItem => dataItem.email === surname);
                    const name = selectedName ? selectedName.surname : '';
                    return (
                      <Grid item key={surname} style={{ justifyContent: 'center', }}>
                        <Chip
                          variant={selectedQuestionsDriver ? (surname === selectedQuestionsDriver.email ? "contained" : "outlined") : "outlined"}
                          onClick={() => handleDriverQuestionsButtonClick(surname)}
                          label={name}
                          sx={{
                            mb: { xs: 1, lg: 0 }
                          }}

                        >

                        </Chip>
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid item xs={'auto'}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleQuestionsClick();
                      setEditQuestions(false);
                    }}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    Close edit
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
          {!EditQuestions && driver && (
            <>

              <Grid container spacing={3} justifyContent="flex-start" style={{ marginTop: -20 }}>
                {questionsfields.map(field => {
                  // Extract unique values for the current field across all drivers 

                  const uniqueValues = [...new Set(raceQuestions.map(driver => driver[field]))];
                  const hasValueOne = raceQuestions.some(driver => driver[field] === '1');

                  return (
                    <>
                      {uniqueValues.map((value, index) => {
                        // Find the drivers with this value for the current field
                        const driversWithThisValue = raceQuestions.filter(driver => driver[field] === value);
                        const driverNames = driversWithThisValue.map(driver => driver.surname).join('   , '); {/*driversWithThisValue.map(driver => driver.surname.substring(0, 4)).join(', ');*/ }



                        let label = '';
                        if (field === 'CURVE ENTER') {
                          if (value === '0') {
                            label = 'EARLY CURVE ENTER';
                            if (raceQuestions.length === 1) {
                              return (
                                <>
                                  {window.innerWidth >= 1250 && (
                                    <Grid item>
                                      <Chip color="warning" label={label} variant="filled" />
                                    </Grid>
                                  )}

                                  {window.innerWidth < 1250 && (
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "questions" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >

                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" onClick={() => handleTooltipOpen("questions" + index + field)} />
                                      </Grid>
                                    </LightTooltip>


                                  )}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  {window.innerWidth >= 1250 && (
                                    <LightTooltip placement="top" title={driverNames} arrow key={index}>
                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" />
                                      </Grid>
                                    </LightTooltip>
                                  )}

                                  {window.innerWidth < 1250 && (
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "questions" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >

                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" onClick={() => handleTooltipOpen("questions" + index + field)} />
                                      </Grid>
                                    </LightTooltip>


                                  )}
                                </>
                              );
                            }

                          } else if (value === '1') {
                            label = 'NEUTRAL CURVE ENTER';
                            if (raceQuestions.length === 1) {
                              return (
                                <>
                                  {window.innerWidth >= 1250 && (
                                    <Grid item>
                                      <Chip color="warning" label={label} variant="filled" />
                                    </Grid>
                                  )}

                                  {window.innerWidth < 1250 && (
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "questions" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >

                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" onClick={() => handleTooltipOpen("questions" + index + field)} />
                                      </Grid>
                                    </LightTooltip>


                                  )}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  {window.innerWidth >= 1250 && (
                                    <LightTooltip placement="top" title={driverNames} arrow key={index}>
                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" />
                                      </Grid>
                                    </LightTooltip>
                                  )}

                                  {window.innerWidth < 1250 && (
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "questions" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >

                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" onClick={() => handleTooltipOpen("questions" + index + field)} />
                                      </Grid>
                                    </LightTooltip>


                                  )}
                                </>
                              );
                            }
                          } else if (value === '2') {
                            label = 'LATE CURVE ENTER';
                            if (raceQuestions.length === 1) {
                              return (
                                <>
                                  {window.innerWidth >= 1250 && (
                                    <Grid item>
                                      <Chip color="warning" label={label} variant="filled" />
                                    </Grid>
                                  )}

                                  {window.innerWidth < 1250 && (
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "questions" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >

                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" onClick={() => handleTooltipOpen("questions" + index + field)} />
                                      </Grid>
                                    </LightTooltip>


                                  )}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  {window.innerWidth >= 1250 && (
                                    <LightTooltip placement="top" title={driverNames} arrow key={index}>
                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" />
                                      </Grid>
                                    </LightTooltip>
                                  )}

                                  {window.innerWidth < 1250 && (
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "questions" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >

                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" onClick={() => handleTooltipOpen("questions" + index + field)} />
                                      </Grid>
                                    </LightTooltip>


                                  )}
                                </>
                              );
                            }
                          }
                        } else {
                          label = field;

                          if (value === '1') {
                            if (raceQuestions.length === 1) {
                              return (
                                <>
                                  {window.innerWidth >= 1250 && (
                                    <Grid item>
                                      <Chip color="warning" label={label} variant="filled" />
                                    </Grid>
                                  )}

                                  {window.innerWidth < 1250 && (
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "questions" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >

                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" onClick={() => handleTooltipOpen("questions" + index + field)} />
                                      </Grid>
                                    </LightTooltip>


                                  )}
                                </>
                              );
                            } else {
                              return (

                                <>
                                  {window.innerWidth >= 1250 && (
                                    <LightTooltip placement="top" title={driverNames} arrow key={index}>
                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" />
                                      </Grid>
                                    </LightTooltip>
                                  )}

                                  {window.innerWidth < 1250 && (
                                    <LightTooltip disableFocusListener placement='top' title={driverNames} arrow onClickAway={handleTooltipClose}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={open === "questions" + index + field}
                                      disableHoverListener
                                      disableTouchListener
                                    >

                                      <Grid item>
                                        <Chip color="warning" label={label} variant="filled" onClick={() => handleTooltipOpen("questions" + index + field)} />
                                      </Grid>
                                    </LightTooltip>


                                  )}
                                </>
                              );
                            }
                          }
                        }
                        // If there's only one unique value for this field, show only the value

                      })}

                    </>
                  );
                })}



              </Grid>

            </>
          )}
          {EditQuestions && selectedQuestionsDriver && driver && (
            <>
              <Grid container spacing={2} direction="row" alignItems="center" >
                <Grid item lg>
                  <Grid container spacing={2} direction="row" alignItems="center" >
                    <Grid item xs={12} sm={4} md={3} lg={'auto'}>
                      {!OversteerSelect ? (
                        <Chip label="I HAVE OVERSTEER" variant="outlined" deleteIcon={<DoneIcon />} onDelete={handleOVClick} sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }} />
                      ) : (
                        <Chip color="success" onDelete={handleOVClick} label="I HAVE OVERSTEER" variant="outlined" sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={'auto'}>
                      {!UndersteerSelect ? (
                        <Chip label="I HAVE UNDERSTEER" variant="outlined" deleteIcon={<DoneIcon />} onDelete={handleUSClick}
                          sx={{
                            height: 'auto',
                            '& .MuiChip-label': {
                              display: 'block',
                              whiteSpace: 'normal',
                            },
                          }} />
                      ) : (
                        <Chip color="success" onDelete={handleUSClick} label="I HAVE UNDERSTEER" variant="outlined" sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={'auto'}>
                      {!Tires ? (
                        <Chip label="NEW TIRES" variant="outlined" deleteIcon={<DoneIcon />} onDelete={handleTiresClick} sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }} />
                      ) : (
                        <Chip color="success" onDelete={handleTiresClick} label="NEW TIRES" variant="outlined" sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={'auto'}>
                      {!Heavy ? (
                        <Chip label="THE KART FEELS HEAVY TO DRIVE" variant="outlined" deleteIcon={<DoneIcon />} onDelete={handleHeavyClick} sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }} />
                      ) : (
                        <Chip color="success" onDelete={handleHeavyClick} label="THE KART FEELS HEAVY TO DRIVE" variant="outlined" sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={'auto'}>
                      {!Slipping ? (
                        <Chip label="THE KART SLIPS IN THE MIDDLE OF THE CURVE" variant="outlined" deleteIcon={<DoneIcon />} onDelete={handleSlipClick} sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }} />
                      ) : (
                        <Chip color="success" onDelete={handleSlipClick} label="THE KART SLIPS IN THE MIDDLE OF THE CURVE" variant="outlined" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={'auto'}>
                  <Box sx={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px', maxWidth: '240px' }}>
                    <Typography style={{ marginBottom: '8px', fontSize: 12, color: 'black', fontWeight: 'bold' }}>HOW DOES IT ENTER THE CURVE?</Typography>
                    <Grid container spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                      <Grid item>
                        <Chip label="EARLY" onClick={handleEarlyClick} size="small" variant={Curve === 0 ? "outlined" : "outlined"} color={Curve === 0 ? "success" : undefined} />
                      </Grid>
                      <Grid item >
                        <Chip label="NEUTRAL" onClick={handleNeutralClick} size="small" variant={Curve === 1 ? "outlined" : "outlined"} color={Curve === 1 ? "success" : undefined} />
                      </Grid>
                      <Grid item >
                        <Chip label="LATE" onClick={handleLateClick} size="small" variant={Curve === 2 ? "outlined" : "outlined"} color={Curve === 2 ? "success" : undefined} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="kartSlipSelect"
                    label="Additional Notes? (optional)"
                    multiline
                    rows={3}
                    value={comment}
                    onChange={(event) => handleCommentChange(event, selectedQuestionsDriver.email)}
                    fullWidth
                  />
                </Grid>
              </Grid>

            </>
          )}

        </Root>
        <Button variant='contained' sx={{ marginTop: 8 }} onClick={prepairQuery}> {!isLoading ? 'SUBMIT' : 'SUBMITTING...'}</Button>
      </div>


    </>


  );
}
export default observer(InsertDriver);