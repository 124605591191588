import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import ChatMessage from './Message';
import Markdown from 'marked-react';
import throttle from 'lodash/throttle'

function Writer({ incomingFragments, setIncomingFragments, onComplete = () => { }, scrollChat = () => { } }) {
  const [displayText, setDisplayText] = useState(''); // Testo visualizzato
  const [buffer, setBuffer] = useState(''); // Buffer di testo temporaneo da scrivere
  const [isTyping, setIsTyping] = useState(false); // Stato che indica se sta scrivendo

  useEffect(() => {
    // Funzione per scrivere i caratteri del buffer uno per uno
    const typeCharacter = async () => {
      if (buffer.length === 0) {
        setIsTyping(false); // Se non ci sono caratteri da scrivere, interrompi la scrittura
        return;
      }

      setIsTyping(true); // Inizia la scrittura

      for (let i = 0; i < buffer.length; i++) {
        setDisplayText((prevText) => prevText + buffer[i]);
      }
      setBuffer(''); // Svuota il buffer dopo aver scritto tutti i caratteri
      setIsTyping(false); // Imposta isTyping a false
      scrollChat()
    };

    if (!isTyping && buffer.length > 0) {
      if (buffer === '[DONE]') {
        onComplete(displayText)
      } else {
        typeCharacter(); // Avvia la scrittura se c'è testo nel buffer e non si sta già scrivendo
      }
    }
  }, [buffer, isTyping, incomingFragments]);

  useEffect(() => {
    if (incomingFragments.length > 0 && !isTyping) {
      // Se ci sono frammenti in arrivo e non sta già scrivendo, aggiungili al buffer
      setBuffer((prevBuffer) => prevBuffer + incomingFragments[0]);
      setIncomingFragments((prev) => prev.slice(1)); // Rimuovi il frammento dalla coda
    }
  }, [incomingFragments, isTyping]);

  return (
    <ChatMessage owner={false} message={displayText} />
  );
}

export default Writer;

