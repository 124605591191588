import { Box, Container, TextField, Typography } from '@mui/material';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

function ResetPasswordForm() {
  const location = useLocation();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {

    const searchParams = new URLSearchParams(location.search);
    // setAccessToken();
    // console.log('ACCESS TOKEN');
    console.log(searchParams.get("access_token"));
    // http://localhost:3000/
    // axios.get('http://localhost:8000/users/me', {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/users/me/`, {
        headers: {
          Authorization: `Bearer ${searchParams.get("access_token")}`, // Include the access token in the header
          accept: "application/json",
        },
      })
      .then((userResponse) => {
        const userData = userResponse.data;
      })
      .catch((error) => {
        toast.error("Token is not valid!", {
          position: "top-right",
          autoClose: 2000, // Close after 2 seconds
          hideProgressBar: false,
        });
        history.push("/");
        console.error("Query Error:", error);
      });
  }, [location.search, history]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/change_password/?password=${password}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${searchParams.get("access_token")}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        const userData = userResponse.data;
        const token = userData.accessToken;
        toast.success("Password updated correctly!", {
          position: "top-right",
          autoClose: 2000, // Close after 2 seconds
          hideProgressBar: false,
        });
        history.replace("/sign-in");
        // window.location.reload();
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "black",
        color: "white",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{
          p: 4,
          backgroundColor: "#1c1c1e",
          borderRadius: 2,
          boxShadow: 3,
          textAlign: "center",
          maxWidth: 400,
          width: "100%",
          margin: "0 auto"
        }}>
          <Typography variant="h5" component="h1" gutterBottom align="center">
            Enter your new password
          </Typography>
          <Box component="form" onSubmit={handleFormSubmit}>
            <TextField
              label="Password"
              type="password"
              variant="filled"
              fullWidth
              value={password}
              onChange={handlePasswordChange}
              margin="normal"
              required
              InputLabelProps={{
                style: { color: 'white' }
              }}
              InputProps={{
                style: { color: 'white', backgroundColor: '#424242' }
              }}
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            >
              RESET PASSWORD
            </Button>
          </Box>
          <Link to={'/sign-in'}>
            Cancel
          </Link>
        </Box>
      </Container>
    </Box>
  );
}

export default ResetPasswordForm;

