import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Fade, Grid, ToggleButton, ToggleButtonGroup, styled,useMediaQuery,   useTheme, } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { toast } from 'react-toastify';
import PayPalButton from 'components/PayPalButton';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Plan from 'components/Plans/Plan';
import PlanExternal from 'components/Plans/PlanExternal';
import myUserStore from 'stores/UserStore';
import { observer } from 'mobx-react-lite';
import 'swiper/css';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#fff',
  padding: '7px 24px',
  borderColor: 'transparent',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#fff',
    color: '#000',
  },
}));

const sliderOptions = {
  slidesPerView: 3,
  spaceBetween: 16,
  breakpoints: {
    320: {
      centeredSlides: true,
      slidesPerView: 1.1,
      spaceBetween: 21,
    },
    900: {
      centeredSlides: false,
      slidesPerView: 3,
      spaceBetween: 24,
    },
  },
};

const pricingOptions1 = [
  {
    chip: 'Basic',
    chip: {
      color: 'primary',
      label: 'Basic',
      variant: 'outlined',
    },
    title: 'Growth',
    currency: '€',
    description: 'For drivers racing individually looking to use AiMotor to improve their performance',
    price: '€25/month',
    yearPrice: '€280/year',
    priceNumber: 25,
    yearPriceNumber: 280,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    chip: {
      color: 'secondary',
      label: 'most popular',
      variant: 'contained',
    },
    title: 'Pro',
    currency: '€',
    price: '€55/month',
    description: 'For drivers seeking to use AiMotor to compare and improve their performance within a single, exclusive group of drivers and with our best AI model',
    yearPrice: '€640/year',
    priceNumber: 55,
    yearPriceNumber: 640,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'Create One Team (up to 5 members)'
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    chip: {
      color: 'success',
      label: 'best',
      variant: 'contained',
    },
    title: 'Team',
    price: '€70/month',
    description: 'For drivers seeking to use AiMotor to compare and improve their performance with multiple and large groups of drivers and with our best AI model',
    yearPrice: '€820/year',
    currency: '€',
    priceNumber: 70,
    yearPriceNumber: 820,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'Create Unlimited teams',
      'Invite Unlimited team members',
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
];

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "EUR",
  intent: "subscription",
  vault: true,
};

function Plans({ isExternal = false, onPlanClick = () => {}, onSuccess = () => {}, onUndo = () => {}, showAll }) {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [plainId, setPlanId] = useState();
  const [period, setPeriod] = useState("MONTH");
  const [showPaypal, setShowPaypal] = useState(false);
  const [newTmpPlan, setNewTmpPlan] = useState({});
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedYearPrice, setSelectedYearPrice] = useState(0);
  const [pricingOptions, setPricingOptions] = useState([]);
  const scrollToRef = useRef(null);  // Ref for scrolling
  const [scrollToPaypal, setScrollToPaypal] = useState(false);
  const paypalRef = useRef(null);
console.log("show all", showAll)
  const myPlan =
  myUserStore?.subscriptionDetails === undefined || showAll
    ? undefined
    : myUserStore?.subscriptionDetails?.custom_id;
console.log("myPlan", myPlan)

const isTrial = myUserStore?.subscriptionDetails?.status === 'CANCELLED' || showAll? false : !myPlan ;
console.log("sub details", myUserStore?.subscriptionDetails?.custom_id)
  
  useEffect(() => {
    let filteredOptions;
    if (myPlan) {
      filteredOptions = pricingOptions1.filter(option => {
        if (myPlan === 'Growth') return true;
        if (myPlan === 'Pro') return option.title === 'Pro' || option.title === 'Team';
        if (myPlan === 'Team') return option.title === 'Team';
        return false;
      });
    } else {
      filteredOptions = pricingOptions1;
    }
    setPricingOptions(filteredOptions);
  }, [myPlan]);

  const selectedPlanId = myUserStore.subscriptionDetails?.custom_id;
  const subscriptionId = myUserStore.subscriptionId;

  const onUndoPayment = () => {
    setShowPaypal(false);
    setNewTmpPlan(undefined);
    onUndo();
  };

  const onPaymentComplete = (subscriptionDetails) => {
    myUserStore.setUserSubscriptionDetails(subscriptionDetails);
    setNewTmpPlan(undefined);
    setShowPaypal(false);
    onSuccess();
  };

  useEffect(() => {
    if (showPaypal && scrollToPaypal && paypalRef.current) {
      paypalRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrollToPaypal(false); // Reset the scroll state
    }
  }, [showPaypal, scrollToPaypal]);

  const handlePlanClick = (plan, isActive) => {
    if (!isActive) {
      setShowPaypal(true);
      setSelectedPrice(plan.priceNumber);
      setSelectedYearPrice(plan.yearPriceNumber);
      setNewTmpPlan(plan);
      setScrollToPaypal(true); // Set scroll state to true to trigger scrolling after rendering
      onPlanClick(plan);
    }
  };

  const handleSwitchChange = (value) => {
    setPeriod(value);
    onUndoPayment();
  };

  const PlanByType = useCallback((props) => {
    return isExternal ? <PlanExternal {...props} /> : <Plan {...props} />;
  }, [isExternal]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
   
      <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
        <ToggleButtonGroup
          color="light"
          size="small"
          value={period}
          exclusive
          onChange={(e, value) => handleSwitchChange(value)}
          aria-label="Platform"
          sx={{
            overflow: 'hidden',
            borderRadius: '30px',
            border: '1px solid #fff'
          }}
        >
          <StyledToggleButton value="MONTH">MONTHLY</StyledToggleButton>
          <StyledToggleButton value="YEAR">YEARLY</StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box as={Swiper} {...sliderOptions} sx={{ width: '100%', padding: isMobile ? ' 16px' : '16px 80px', }}>
        {pricingOptions.map((option, index) => {
          const hasPlan = newTmpPlan && newTmpPlan?.title;
          const isSelected = newTmpPlan?.title && newTmpPlan.title === option.title;
          return (
            <SwiperSlide key={index} style={{ height: 'auto' }}>
              <PlanByType
                plan={option}
                period={period}
                isActive={selectedPlanId === option.title}
                loading={loading}
                onClick={handlePlanClick}
                sx={{
                  opacity: hasPlan ? (isSelected ? 1 : 0.2) : 1,
                  disabled: hasPlan && !isSelected
                }}
              />
            </SwiperSlide>
          );
        })}
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="stretch"></Grid>
      <Fade in={showPaypal} unmountOnExit>
        <Box px={3}> {/* Add ref to the PayPal container */}
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButton  ref={paypalRef}
              period={period}
              price={period === 'MONTH' ? selectedPrice : selectedYearPrice}
              planId={plainId}
              currentSubscriptionId={subscriptionId}
              selectedPlan={newTmpPlan}
              onPaymentComplete={onPaymentComplete}
              onUndoPayment={onUndoPayment}
              isTrial={isTrial}
              noPromo={showAll}
            />
          </PayPalScriptProvider>
        </Box>
      </Fade>
    </Box>
  );
}

export default observer(Plans);
