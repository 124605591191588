import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory } from "react-router-dom";
import myUserStore from "../../stores/UserStore";
import { observer } from "mobx-react-lite";

function Highlights({ open, onClose }) {
  const selectedTeam = myUserStore.getSelectedTeam;
  const history = useHistory();
  const [selectedSessions, setSelectedSessions] = useState([1]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataset, setDataset] = useState({});
  const [datasetTeam, setDatasetTeam] = useState({});
  const [viewMode, setViewMode] = useState("Personal"); // View mode state
  const [selectedLapData, setSelectedLapData] = useState(null); // State to store selected lap data
  const user = JSON.parse(localStorage.getItem("user_data") || "{}");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSessionClick = (session) => {
    setSelectedSessions((prev) =>
      prev.includes(session)
        ? prev.filter((item) => item !== session)
        : [...prev, session]
    );
  };
  const availableSessions = Object.keys(dataset?.sessions || {}).map(Number);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/fastest_lap_telemetry_user`, {
        params: {
          user_email: user.email,
          team_id: selectedTeam.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setDataset(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        setIsLoading(false);
      });
  }, [selectedTeam, open]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/fastest_lap_telemetry_team`, {
        params: {
          user_email: user.email,
          team_id: selectedTeam.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setDatasetTeam(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        setIsLoading(false);
      });
  }, [selectedTeam, open]);

  const getDriversFromDatasetTeam = () => {
    if (!datasetTeam?.processed_data) return [];
    const driversMap = {};

    Object.values(datasetTeam?.processed_data).forEach((session) => {
      session.forEach((data) => {
        if (data.driver) {
          if (!driversMap[data.driver]) {
            driversMap[data.driver] = [];
          }
          driversMap[data.driver].push(data.Lap);
        }
      });
    });

    return Object.entries(driversMap).map(([driver, laps]) => ({ driver, laps }));
  };

  const driversData = getDriversFromDatasetTeam();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxHeight: "90vh",
          padding: isMobile ? "0px" : "20px",
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: "#000000" }}>
        Sector Telemetry Data
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Chip
            label="Personal"
            color={viewMode === "Personal" ? "primary" : "default"}
            onClick={() => {
              setViewMode("Personal");
              setSelectedLapData(null); // Resetta lo stato di lap selezionato
            }}
            sx={{ marginRight: 1 }}
          />
          <Chip
            label="Team"
            color={viewMode === "Team" ? "primary" : "default"}
            onClick={() => setViewMode("Team")}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : selectedLapData ? (
          // Render table for selected lap

          <Box sx={{ marginTop: 3 }}>
            <div>

            </div>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Session {selectedLapData.sessionKey} - Lap {selectedLapData.lap}
            </Typography>
            <TableContainer>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sector</TableCell>
                    <TableCell>Accel Speed</TableCell>
                    <TableCell>Brake Speed</TableCell>
                    <TableCell>Average Acceleration</TableCell>
                    <TableCell>Accel Position</TableCell>
                    <TableCell>Brake Position</TableCell>
                    <TableCell>Sector Time</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedLapData.data.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{row.Sector}</TableCell>
                      <TableCell>{row.Accel_Speed}</TableCell>
                      <TableCell>{row.Brake_Speed}</TableCell>
                      <TableCell>{row.Average_Acceleration}</TableCell>
                      <TableCell>{row.accel_position}</TableCell>
                      <TableCell>{row.brake_position}</TableCell>
                      <TableCell> {Math.round(row.sector_time * 100) / 100}</TableCell>
                      <TableCell>{row.Note}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography sx={{ marginTop: "10px", color: "#000000" }}>
  Average Speed:{" "}
  {datasetTeam?.sessions?.[selectedLapData.sessionKey]?.average_speed?.toFixed(2) || "-"} km/h
</Typography>
<Typography sx={{ color: "#000000" }}>
  Average RPM:{" "}
  {datasetTeam?.sessions?.[selectedLapData.sessionKey]?.average_rpm?.toFixed(2) || "-"}
</Typography>


          </Box>
        ) : viewMode === "Personal" ? (
          dataset && Object.keys(dataset?.sessions || {}).length > 0 ? (<>
            {availableSessions.map((session) => (
              <Chip
                key={session}
                label={session}
                color={selectedSessions.includes(session) ? "primary" : "default"}
                onClick={() => handleSessionClick(session)}
                sx={{ marginRight: 1 }}
              />
            ))}
            {Object.entries(dataset?.processed_data || {})
              .filter(([sessionKey]) => selectedSessions.includes(Number(sessionKey)))
              .map(([sessionKey, sessionData], sessionIndex) => (
                <div key={sessionIndex} style={{ marginBottom: "30px" }}>

                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#000000", marginBottom: "10px" }}
                  >
                    Session {sessionKey} (Lap {sessionData[0]?.Lap || "-"})
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sector</TableCell>
                          <TableCell>Accel Speed</TableCell>
                          <TableCell>Brake Speed</TableCell>
                          <TableCell>Average Acceleration</TableCell>
                          <TableCell>Accel Position</TableCell>
                          <TableCell>Brake Position</TableCell>
                          <TableCell>Sector Time</TableCell>
                          <TableCell>Notes</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(sessionData || []).map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.Sector}</TableCell>
                            <TableCell>{row.Accel_Speed}</TableCell>
                            <TableCell>{row.Brake_Speed}</TableCell>
                            <TableCell>{row.Average_Acceleration}</TableCell>
                            <TableCell>{row.accel_position}</TableCell>
                            <TableCell>{row.brake_position}</TableCell>
                            <TableCell> {Math.round(row.sector_time * 100) / 100}</TableCell>
                            <TableCell>{row.Note}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Typography sx={{ marginTop: "10px", color: "#000000" }}>
                    Average Speed:{" "}
                    {dataset?.sessions?.[sessionKey]?.average_speed?.toFixed(2) ||
                      "-"}{" "}
                    km/h
                  </Typography>
                  <Typography sx={{ color: "#000000" }}>
                    Average RPM:{" "}
                    {dataset?.sessions?.[sessionKey]?.average_rpm?.toFixed(2) || "-"}
                  </Typography>
                </div>
              ))
            } </>) : (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography>No telemetry data inserted yet for personal mode!</Typography>
            </Box>
          )
        ) : (
          driversData.map(({ driver, laps }, index) => (
            <Accordion key={index} sx={{ marginBottom: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: "bold" }}>{driver}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Object.entries(datasetTeam?.processed_data || {}).map(
                  ([sessionKey, sessionData], sessionIndex) => {
                    const driverLaps = sessionData
                      .filter((data) => data.driver === driver)
                      .map((d) => d.Lap);
                    if (driverLaps.length === 0) return null;
                    return (
                      <Box key={sessionIndex}>
                        <Typography>Session {sessionKey}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                            alignItems: "center",
                            marginTop: 1,
                          }}
                        >
                          {Array.from(new Set(driverLaps)).map((lap, lapIndex) => (
                            <Box
                              key={lapIndex}
                              sx={{
                                padding: "8px 16px",
                                backgroundColor: "#e0e0e0",
                                borderRadius: "8px",
                                cursor: "pointer",
                                textAlign: "center",
                                fontSize: "14px",
                                fontWeight: "bold",
                                ":hover": {
                                  backgroundColor: "#d0d0d0",
                                },
                              }}
                              onClick={() => {
                                const filteredLapData = sessionData.filter(
                                  (data) =>
                                    data.Lap === lap && data.driver === driver
                                );
                                setSelectedLapData({
                                  sessionKey,
                                  lap,
                                  data: filteredLapData,
                                });
                              }}
                            >
                              Lap {lap}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    );
                  }
                )}
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </DialogContent>
      <DialogActions>
        {selectedLapData ? (
          <Button
            onClick={() => setSelectedLapData(null)}
            variant="outlined"
            color="primary"
            sx={{ marginRight: "auto" }} // Allinea il pulsante a sinistra
          >
            Back
          </Button>
        ) : null}
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          sx={{ width: 100, height: 35 }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(Highlights);
