import { Box, Chip, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import HttpProvider from 'services/HttpProvider';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { LineChart } from '@mui/x-charts/LineChart';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import axios from 'axios';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import myUserStore from '../../stores/UserStore';
import { useHistory, } from 'react-router-dom';
import { observer } from "mobx-react-lite"

export default function MarkOptimization({ track1, hoverLatitude, hoverLongitude, trackIndex, race, driverSessions, day }) {
  const [chartSize, setChartSize] = useState({ width: window.innerWidth < 500 ? 285 : 500, height: window.innerWidth < 500 ? 199.5 : 300 });
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [showSectors, setShowSectors] = useState(true);
  const [showSectorNumbers, setShowSectorNumbers] = useState(false);
  const [ballColors, setBallColors] = useState([]);
  const [sectorParts, setSectorParts] = useState([]);  // Initialization added here
  const [track, setTrack] = useState([]);
  const [trackData, setTrackData] = useState([]);
  const history = useHistory();
  const [competitions, setCompetitions] = useState([]);
  const [centroids, setCentroids] = useState([]);
  const selectedTeam = myUserStore.getSelectedTeam;
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  let paddingBlock = 49.2;
  let paddingInline = 49.2;
  const user = JSON.parse(localStorage.getItem("user_data") || "{}");
  console.log("track index", trackIndex * 4)
  // console.log("track index", dataset[0]?.drivingData?.[trackIndex * 4]?.latitude, dataset[0].drivingData?.[trackIndex * 4]?.longitude )
 
  const showPersonalButton = myUserStore.isPersonalSpace;



  const fetchCompetitions = async () => {
    return HttpProvider.get(`/get_competitions/?team_id=${selectedTeam.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      }
    )
      .then((res) => {
        setCompetitions(res.data);

        // Trova l'oggetto che ha l'id uguale a race e imposta setTrack
        const competition = res.data.find((comp) => comp.id === race);
        if (competition) {
          setTrack(competition.track);
        }

        getCentroids(competition.track);
        getLongLatTrack(competition.track);
      })
      .catch((err) => {
        console.error('Error fetching competitions:', err);
      })

  };




  const getCentroids = async (givenTrack) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/centroids_data/?track=${givenTrack}`);
      const data = response.data;
      setCentroids(data);

    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  }

  const getLongLatTrack = async (givenTrack) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/long_lat_for_track/?track=${givenTrack}`);
      const data = response.data;
      const filteredData = data.filter((_, index) => index % 8 === 0);

      // Update state with filtered data
      setTrackData(filteredData);
      console.log("Filtered Track Data:", filteredData);

    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  }

  const getSectors = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/get_change_in_sector?race=${race}&&day=${day}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            'Content-Type': 'application/json',
          },
        }
      )
      .then((userResponse) => {
        const sectorTimes = userResponse.data[0]?.sectorData?.sector_times || [];
        const roundedSectorParts = sectorTimes.map(time => Math.round(time / 0.16));
        setSectorParts(roundedSectorParts); // Use setSectorParts to update state
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  };

  const getTrackData = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/get_filtered_longitude_latitude?race=${race}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            'Content-Type': 'application/json',
          },
        }
      )
      .then((userResponse) => {
        setIsLoading(false);
        setDataset(userResponse.data);
        getSectors(); // Retrieve sector parts after getting track data
      })
      .catch((error) => {
        console.error("Query Error:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTrackData();

  }, [, chartSize, driverSessions, track]);

  useEffect(() => {

    fetchCompetitions();


  }, [, chartSize, driverSessions, track1]);



  useEffect(() => {
    const generateRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const colors = dataset.map(() => generateRandomColor());
    setBallColors(colors);
  }, [dataset]);

  const increaseSize = () => {
    if (chartSize.width < window.innerWidth) {
      setChartSize((prevSize) => ({
        width: prevSize.width * 1.1,
        height: prevSize.height * 1.1,
      }));
      paddingBlock = paddingBlock * 1.1;
      paddingInline = paddingInline * 1.1;
    }
  };

  const decreaseSize = () => {
    setChartSize((prevSize) => ({
      width: Math.max(prevSize.width * 0.9, 100),
      height: Math.max(prevSize.height * 0.9, 100),
    }));
  };

  const handleTouchOrClickOutside = (event) => {
    if (chartRef.current && !chartRef.current.contains(event.target)) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchOrClickOutside, { passive: true });
    document.addEventListener('mousedown', handleTouchOrClickOutside);

    return () => {
      document.removeEventListener('touchstart', handleTouchOrClickOutside);
      document.removeEventListener('mousedown', handleTouchOrClickOutside);
    };
  }, []);

  const calculateTopPosition = (latitude) => {
    const minLatitude = Math.min(...latitudeData);
    const maxLatitude = Math.max(...latitudeData);
    const mapHeight = chartSize.height - paddingBlock * 2;
    return ((maxLatitude - latitude) * mapHeight) / (maxLatitude - minLatitude);
  };

  const calculateLeftPosition = (longitude) => {
    const minLongitude = Math.min(...longitudeData);
    const maxLongitude = Math.max(...longitudeData);
    const mapWidth = chartSize.width - paddingBlock * 2;
    return ((longitude - minLongitude) / (maxLongitude - minLongitude)) * mapWidth;
  };

  // const longitudeData = dataset[0]?.drivingData?.map((point) => point.longitude) || [];
  // const latitudeData = dataset[0]?.drivingData?.map((point) => point.latitude) || [];

  const longitudeData = trackData?.map((point) => point.longitude) || [];
  const latitudeData = trackData?.map((point) => point.latitude) || [];
  



  const marginTopValue = chartRef.current ? -chartRef.current.clientHeight : -chartSize.height;

  const minLongitude = Math.min(...longitudeData);
  const maxLongitude = Math.max(...longitudeData);
  const minLatitude = Math.min(...latitudeData);
  const maxLatitude = Math.max(...latitudeData);

  const series =
    // showSectors ? sectorParts?.map((start, index) => {
    //   const end = sectorParts[index + 1] !== undefined ? sectorParts[index + 1] : latitudeData?.length;
    //   if (start >= end || start < 0 || end > latitudeData.length) {
    //     return null;
    //   }
    //   return {
    //     data: [
    //       ...(index !== 0 ? Array(start).fill(null) : []),
    //       ...latitudeData.slice(start, end + 1)
    //     ],
    //     showMark: false,
    //     lineStyle: { stroke: 'transparent' },
    //     id: `sector-${index}`,
    //   };
    // }).filter(Boolean) : 
    [{
      data: latitudeData,
      showMark: false,
      lineStyle: { stroke: 'transparent' },
      id: 'track'
    }];
  const navigateToTelemetryData = () => {
    history.push('/racing-space/telemetry-data'); // Use history.push for navigation
  };
  return (
    <>
      <Draggable bounds={'.draggable-parent'} sx={{boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",}}>
        <Box
          sx={{ position: 'relative', display: 'inline-block',boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", zIndex: 991, borderRadius: 2, backgroundColor: '#fff', border: '1px solid #dedede' }}
          onMouseEnter={() => setIsHovered(true)}
          onTouchStart={() => setIsHovered(true)}
          ref={chartRef}
        >
          {isHovered && (
            <>
              <Chip
                label="Show Sectors"
                color={showSectors ? 'primary' : 'default'}
                onClick={() => setShowSectors(!showSectors)}
                onTouchStart={() => setShowSectors(!showSectors)}
                sx={{ position: 'absolute', top: 10, left: 20, zIndex: 12 }}
              />
              {showSectors && (
                <>
                  <Chip
                    label="Sector Numbers"
                    onTouchStart={() => setShowSectorNumbers(!showSectorNumbers)}
                    color={showSectorNumbers ? 'secondary' : 'default'}
                    onClick={() => setShowSectorNumbers(!showSectorNumbers)}
                    sx={{ position: 'absolute', top: 10, left: 140, zIndex: 12, marginBottom: 20, fontSize: '12px' }}
                  />
                  {/* Add yellow square and text when showSectorNumbers is enabled */}

                </>
              )}
              {/* Legend Box */}
            </>
          )}
          {!isLoading && (dataset[0]?.drivingData?.length > 0 && trackData?.length > 0) ? (
            <>
              <LineChart
                axisHighlight={false} // Disable axis highlighting
                grid={false} // Disable grid lines
                disableAxisListener={true}
                xAxis={[{
                  data: longitudeData,
                  min: minLongitude,
                  max: maxLongitude,
                  hide: true, // Hide x-axis
                  disableLine: true,
                  disableTicks: true,
                  hideTooltip: true,
                  tickInterval: []
                }]}
                yAxis={[{
                  min: minLatitude,
                  max: maxLatitude,
                  hide: true, // Hide y-axis
                  disableLine: true,
                  disableTicks: true,
                  hideTooltip: true,
                  tickInterval: []
                }]}
                series={series}
                width={chartSize.width}
                height={chartSize.height}
                highlightedItem={{
                  seriesId: 'track',
                  dataIndex: 10,
                }}
                interactions={{
                  hover: false, // Disabilita il comportamento di hover
                }}
                tooltip={{
                  enabled: false, // Disabilita i tooltip
                }}
              // Reset on mouse leave
              />

              {/* Tooltip for hovered sector */}


              {dataset.map((data, index) => {
               
                const currentLatitude = data?.drivingData?.[trackIndex * 4]?.latitude;
                const currentLongitude = data?.drivingData?.[trackIndex * 4]?.longitude;
                console.log ('current lat long:', currentLatitude, currentLongitude )
                const ballColor = ballColors[index];

                return (
                  currentLatitude && currentLongitude && (
                    <div
                      key={index}
                      ref={containerRef}
                      style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        marginTop: `${marginTopValue}px`,
                        paddingBlock: `${paddingBlock}px`,
                        paddingInline: `${paddingInline}px`,
                      }}
                    >
                      <div
                        style={{
                          position: 'relative',
                          top: `${calculateTopPosition(currentLatitude)}px`,
                          left: `${calculateLeftPosition(currentLongitude)}px`,
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: ballColor,
                          borderRadius: '50%',
                          width: '10px',
                          height: '10px',
                          zIndex: 11,
                        }}
                      />
                    </div>
                  )
                );
              })}




              {showSectors && centroids?.map((point, index) => (
                <div
                  key={index}
                  ref={containerRef}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    marginTop: `${marginTopValue - 3}px`,
                    paddingBlock: `${paddingBlock}px`,
                    paddingInline: `${paddingInline}px`,
                  }}
                >
                  {showSectorNumbers ? (
                    <Typography
                      variant="caption"
                      style={{
                        position: 'absolute',
                        top: `${calculateTopPosition(point[0]) + 50}px`,
                        left: `${calculateLeftPosition(point[1]) + 50}px`,
                        transform: 'translate(-50%, -50%)',
                        zIndex: 18,
                        color: 'black',
                        fontSize: window.innerWidth < 400 ? '12px' : '14px', // Adjust font size as needed
                        padding: '4px', // Slight padding for the last number
                        borderRadius: '4px', // Square background for the last number
                        backgroundColor: '#f0f0f0', // Light background for better visibility
                        border: '1px solid #ccc', // Border for the last number
                      }}
                    >
                      {index === 0 ? 0 : index} {/* Display the sector number */}
                    </Typography>
                  ) : (
                    <Tooltip title={`Sector ${index}`}>
                      <CloseIcon
                        style={{
                          position: 'absolute',
                          top: `${calculateTopPosition(point[0]) + 50}px`,
                          left: `${calculateLeftPosition(point[1]) + 50}px`,
                          transform: 'translate(-50%, -50%)',
                          color: 'black',
                          fontSize: '20px',
                          zIndex: 10,
                          // Customize the color or style of centroid markers


                          zIndex: 12,
                        }}
                      />
                    </Tooltip>
                  )}
                </div>

              ))}
              {latitudeData.length > 0 && longitudeData.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: `${calculateTopPosition(latitudeData[latitudeData.length - 1]) + 46}px`,
                    left: `${calculateLeftPosition(longitudeData[longitudeData.length - 1]) + 50}px`,
                    transform: 'translate(-50%, -50%)',
                    zIndex: 15,
                  }}
                >
                  <SportsScoreIcon
                    style={{ width: '30px', height: '30px' }}
                  />
                </div>
              )}
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: chartSize.width,
                height: chartSize.height,
                backgroundColor: '#f0f0f0',
                borderRadius: '8px',
              }}
            >
              {isLoading ? 'Loading data...' : (<Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: chartSize.width,
                  height: chartSize.height,
                  backgroundColor: '#f0f0f0',
                  borderRadius: '8px',
                  flexDirection: 'column',
                }}
              >
                <Typography>No telemetry data inserted yet!</Typography>
                <Chip
                  label="Insert Data"
                  color="primary"
                  onClick={navigateToTelemetryData} // Call the navigation function
                  sx={{ mt: 2 }}
                />
              </Box>)}

            </Box>
          )}
          {isHovered && (
            <Box
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '4px',
                padding: '4px',
              }}

            >
              {chartSize.width < window.innerWidth && (<IconButton onClick={increaseSize} size="small" sx={{ zIndex: 300 }}
                onTouchStart={increaseSize}
              >
                <ZoomInIcon />
              </IconButton>)}
              <IconButton onClick={decreaseSize} size="small" sx={{ zIndex: 300 }}
                onTouchStart={decreaseSize}
              >
                <ZoomOutIcon />
              </IconButton>
            </Box>
          )}

        </Box>
      </Draggable>
    </>
  );
}
