import { Button, Stack, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { styled } from '@mui/system';

export default function GoFasterHero() {
  const BackgroundContainer = styled(Stack)(({ theme }) => ({
    background: 'linear-gradient(135deg, #3A49F9 0%, #4D5DFB 100%)',
    height: '20vh',
    color: '#fff',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }));

  return (
    <BackgroundContainer>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        Go Faster today
      </Typography>
      <Link to="/sign-up" className="insertdata">
        <Button variant="contained" color='primary'>
          Register
        </Button>
      </Link>
    </BackgroundContainer>
  );
}
