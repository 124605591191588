import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import TrackMapChat from './Try_TrackMapChat';
import TrySubscribe from "../Try_Subscribe";
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1E1E1E',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#AAAAAA',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h6: {
            fontWeight: 'bold',
            color: '#BBBBBB',
        },
    },
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
        backgroundColor: '#333333',
    },
}));

const StyledListItemText = styled(ListItemText)({
    primary: {
        fontWeight: '500',
        color: '#FFFFFF',
    },
    secondary: {
        color: '#AAAAAA',
    },
});

const AnchorTemporaryDrawer = ({ onNewChat, isOpen, onClose }) => {
    const [race, setRace] = useState("");
    const [day, setDay] = useState("");
    const [driverSessions, setDriverSessions] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false); 
    const history = useHistory(); 
    const userData = JSON.parse(localStorage.getItem("user_data"));

    const getLatestRace = async () => {
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/latest_race`, {
                params: {
                    user_email: userData?.email
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    accept: "application/json",
                },
            })
            .then((res) => {
                setRace(res.data.race);
                setDay(res.data.day);
                setDriverSessions(res.data.driverSessions);
            })
            .catch((error) => {
                console.error("Axios Error:", error);
            });
    };

    useEffect(() => {
        if (isOpen) {
            getLatestRace();
        }
    }, [isOpen]);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const performQuery = (e) => {
        e.preventDefault();
        onNewChat();  
        // The user can start a new chat, no history involved.
    };

    const handleCloseSubscribeDialog = () => {
        setIsSubscribeDialogOpen(false); 
    };

    const handleSubscribeClick = () => {
        history.push('/sign-up'); 
    };

    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <Drawer anchor="right" open={isOpen} onClose={onClose}>
                    <Box sx={{ width: 250, backgroundColor: '#1E1E1E', color: '#FFFFFF' }}>
                        <List>
                            <StyledListItem disablePadding button onClick={handleOpenDialog}>
                                <IconButton>
                                    <MyLocationIcon />
                                </IconButton>
                                <StyledListItemText primary="Track Map" />
                            </StyledListItem>
                            <StyledListItem disablePadding onClick={performQuery}>
                                <IconButton>
                                    <AddIcon />
                                </IconButton>
                                <StyledListItemText primary="New Chat" />
                            </StyledListItem>
                        </List>
                        <Divider sx={{ backgroundColor: '#333333' }} />
                        {/* No Chat History Message */}
                        <Box sx={{ padding: 2 }}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                No chat history for try version
                            </Typography>
                        </Box>
                    </Box>
                </Drawer>
            </ThemeProvider>

            <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
                <DialogContent>
                    <IconButton
                        onClick={handleCloseDialog}
                        style={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <TrackMapChat
                        race={race}
                        driverSessions={driverSessions}
                        day={day}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={isSubscribeDialogOpen}
                onClose={handleCloseSubscribeDialog}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    Subscribe Now
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseSubscribeDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    To access this function, please subscribe.
                </DialogContent>
                <DialogActions>
                    <Chip
                        label="Subscribe"
                        color="secondary"
                        onClick={handleSubscribeClick}
                        clickable
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AnchorTemporaryDrawer;
