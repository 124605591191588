import React from 'react';

const PrivacyPolicy = () => {
  const styles = {
    container: {
      width: '100vw',
      minHeight: '100vh',
      margin: '0',
      padding: '0',
      fontFamily: 'Arial, sans-serif',
      color: '#FFFFFF',
      backgroundColor: '#000000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      maxWidth: '800px',
      padding: '40px 20px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#000000',
    },
    heading1: {
      fontSize: '2.5em',
      marginBottom: '0.5em',
      color: '#00ADEF',
      textAlign: 'center',
    },
    heading2: {
      fontSize: '1.8em',
      marginTop: '1.5em',
      marginBottom: '0.5em',
    },
    paragraph: {
      lineHeight: '1.8',
      marginBottom: '1.2em',
      color: '#DDDDDD',
    },
    date: {
      color: '#AAAAAA',
      textAlign: 'center',
    },
    highlightedText: {
      color: '#00ADEF',
    },
    globalStyle: {
      margin: '0',
      padding: '0',
      backgroundColor: '#000000',
      color: '#FFFFFF',
      height: '100%',
      width: '100%',
      overflowX: 'hidden',
    },
  };

  return (
    <>
      <style>{`
        body, html {
          margin: 0;
          padding: 0;
          background-color: #000000;
          color: #FFFFFF;
          height: 100%;
          width: 100%;
          overflow-x: hidden;
        }
      `}</style>
      <div style={styles.container}>
        <div style={styles.content}>
          <h1 style={styles.heading1}>Privacy Policy</h1>
          <p style={styles.date}>Effective Date: July 10, 2024</p>

          <h2 style={styles.heading2}>Introduction</h2>
          <p style={styles.paragraph}>
            At AI Tech Company, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
          </p>

          <h2 style={styles.heading2}>Information We Collect</h2>
          <p style={styles.paragraph}>
            We may collect personal information that you provide to us directly, such as your name, email address, and phone number. We also collect information automatically as you navigate through our site, such as IP addresses, browser types, and browsing actions.
          </p>

          <h2 style={styles.heading2}>How We Use Your Information</h2>
          <p style={styles.paragraph}>
            We use your information to provide, operate, and maintain our services. This includes using data to improve our services, communicate with you, process transactions, and for security purposes.
          </p>

          <h2 style={styles.heading2}>Sharing Your Information</h2>
          <p style={styles.paragraph}>
            We do not sell or rent your personal information to third parties. We may share information with vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.
          </p>

          <h2 style={styles.heading2}>Data Security</h2>
          <p style={styles.paragraph}>
            We implement security measures to protect your personal information. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.
          </p>

          <h2 style={styles.heading2}>Your Data Rights</h2>
          <p style={styles.paragraph}>
            Depending on your location, you may have rights regarding your personal data, including the right to access, correct, or delete your personal information. You may also have the right to object to or restrict certain processing activities.
          </p>

          <h2 style={styles.heading2}>Changes to This Privacy Policy</h2>
          <p style={styles.paragraph}>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
          </p>

          <h2 style={styles.heading2}>Contact Us</h2>
          <p style={styles.paragraph}>
            If you have any questions about this Privacy Policy, please contact us at support@aimotor.eu
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
