import { Box, Button, Card, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check'
import { styled } from '@mui/system';

const PlanExternal = ({
  plan,
  period = "MONTH",
  isActive,
  loading = false,
  onClick = () => { },
  isTrial,
  ...props
}) => {

  const handleClick = (title, price) => {
    onClick(title, price)
  }


  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#1e1e2d',
    color: '#fff',
    borderRadius: '15px',
    padding: theme.spacing(4),

    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    '&:hover': {
      boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
    },
    height: '100%',
    
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#5d5fef',
    color: '#fff',
    borderRadius: '25px',
    padding: '10px 20px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginTop: 'auto',
    '&:hover': {
      backgroundColor: '#4a4cda',
    },
  }));


  return (
    <StyledCard>
      <Box>
        <Typography variant="h5" component="div" style={{ fontWeight: 'bold', marginBottom: '4px',marginTop:-5,color: '#fff', fontSize: '1.5rem' }}>
          {plan.title}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: '1px', fontStyle: 'italic', fontSize: '0.95rem', color: '#fff' }}>
          {plan.description}
        </Typography>
        <Typography
          variant="h4"
          component="div"

          style={{ textAlign: 'center', margin: '16px 0', color: '#fff', marginTop: plan.title === "Growth" ? 35 : 0 }}
        >
          {isTrial ? (
            <>
              €0.00
              <Typography
                variant="body2"
                component="div"
                style={{ fontSize: '0.6em', color: '#ccc' }}
              >
                ({period === "MONTH" ? plan.price : plan.yearPrice} after)
              </Typography>

              <Box
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  color: '#fff',
                  marginBottom: '12px', // Space between text and button
                }}
              >
                Cancel at any time!
              </Box>
            </>
          ) : (
            <>
              {period === "MONTH" ? plan.price : plan.yearPrice}
            </>
          )}
        </Typography>
        <List dense size="sm" sx={{ p: 0, mt: 2, mb: 2 }}>
          {plan.features.map((feature, index) => (
            <ListItem key={index} sx={{ px: 0, py: { md: 0, lg: 0.5 } }}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <CheckIcon color='light' />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
        {/* <ul style={{ padding: 0, listStyle: 'none', marginBottom: '16px', textAlign: 'left', color: '#fff', fontSize: '1rem' }}>
          {plan.features.map((feature, index) => (
            <Typography component="li" key={index} style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', color: '#fff' }}>
              <span style={{ marginRight: '8px', color: '#5d5fef' }}>✔️</span>{feature}
            </Typography>
          ))}
        </ul> */}
      </Box>

      <StyledButton
        variant="contained"
        onClick={() => handleClick(plan)}
      >
        Choose plan
      </StyledButton>
    </StyledCard>
  );
};

export default PlanExternal;


