import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Stack, Typography, Chip } from '@mui/material';

import BuildIcon from '@mui/icons-material/Build';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'; // Caster/Mechanical Setup
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'; // Axle Position
import SpeedIcon from '@mui/icons-material/Speed'; // Fast Driver & Setup
import CompareIcon from '@mui/icons-material/Compare'; // Time Comparison
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // Exit Speeds
import TrackChangesIcon from '@mui/icons-material/TrackChanges'; // Braking Points
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'; // Max RPM with Sprocket and Pinion
import TuneIcon from '@mui/icons-material/Tune'; // Setup for Fastest Sector
import TireRepairIcon from '@mui/icons-material/TireRepair'; // Rear Grip & Tires
import FilePresentIcon from '@mui/icons-material/FilePresent';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
const randomColors = [
  { chip: 'primary', icon: '#2196f3' },
  { chip: 'secondary', icon: '#f50057' },
  { chip: 'success', icon: '#4caf50' },
  { chip: 'error', icon: '#f44336' },
  { chip: 'warning', icon: '#ff9800' },
  { chip: 'info', icon: '#00bcd4' },
];

const getRandomColor = () => randomColors[Math.floor(Math.random() * randomColors.length)];

const suggestionGroups = [
  [
    {
      icon: <SportsMotorsportsIcon />,
      text: 'Driving',
      detailedMessage: "What caster did my fastest teammate use in the first setup session yesterday?",
      helpText: 'Help Me',
      color: getRandomColor(),
      suggestions: [
        {
          title: "Help me understand why  am I slower than my teammate using telemetry data",
          message: "Help Me understand why I am slower than my teammate using telemetry data",
        },
        {
          title: "Help me understand in which sector should I imporve  the most and why",
          message: "Help me understand in which sector should I imporve  the most and why",
        },
        {
          title: "Help me understand how to improve my braking in sector 3",
          message: "Help me understand how to improve my braking in sector 3",
        },
        {
          title: "Help me understand if I’m anticipating or delaying Sector 11",
          message: "Help me understand if I’m anticipating or delaying Sector 11",
        },
      ],
    },
    {
      icon: <WifiProtectedSetupIcon />,
      text: 'Setup',
      detailedMessage: "What caster did my fastest teammate use in the first setup session yesterday?",
      helpText: 'Help Me',
      color: getRandomColor(),
      suggestions: [
        {
          title: "Help me choosing what axle to use for my next session",
          message: "Help Me choosing what axle to use for my next session",
        },
        {
          title: "Help me decide whether increasing the caster is the right choice for my next session, considering that the temperature will be 25°C, my tires won't be new, and my teammates' and my previous data",
          message: "Help me decide whether increasing the caster is the right choice for my next session, considering that the temperature will be 25°C, my tires won't be new, and my teammates' and my previous data",
        },
        {
          title: "Help me understand what changes to make, considering that in my last session, I struggled with getting my go-kart to turn and experienced slipping in the middle of corners",
          message: "Help me understand what changes to make, considering that in my last session, I struggled with getting my go-kart to turn and experienced slipping in the middle of corners",
        },
        {
          title: "Help me decide whether my cold pressure for the next session should be 0.66 or 0.69",
          message: "Help me decide whether my cold pressure for the next session should be 0.66 or 0.69",
        },
      ],
    },
    {
      icon: <FilePresentIcon />,
      text: 'Data ',
      detailedMessage: "What caster did my fastest teammate use in the first setup session yesterday?",
      helpText: 'Help Me',
      color: getRandomColor(),
      suggestions: [
        {
          title: "Help me remember what caster setting did my faster teammate use in Session 3 yesterday",
          message: "Help me remember what caster setting did my faster teammate use in Session 3 yesterday",
        },
        {
          title: "Help me remember which sprocket and pinion I used to achieve my maximum average speed and average RPM today",
          message: "Help me remember which sprocket and pinion I used to achieve my maximum average speed and average RPM today",
        },
        {
          title: "Help me remember with what axle have I made sector 6 the fastest",
          message: "Help me remember with what axle have I made sector 6 the fastest",
        },
        {
          title: "Help me remember who my fastest teammate was in each session yesterday and what setup they used",
          message: "Help me remember who my fastest teammate was in each session yesterday and what setup they used",
        },
      ],
    },
   
  ],
];

function ChatSuggestion({ onClick = () => {}, ...props }) {
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true); // Trigger the fade-out animation
      setTimeout(() => {
        setCurrentGroupIndex((prevIndex) => (prevIndex + 1) % suggestionGroups.length);
        setIsTransitioning(false); // Trigger the fade-in animation
      }, 500); // Wait for the fade-out animation to complete
    }, 5000); // Change every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const currentSuggestions = suggestionGroups[currentGroupIndex];

  return (
    <Grid container spacing={2} {...props} justifyContent="center">
      {/* Dynamic Heading */}
      <Grid item xs={12} sx={{ marginBottom: 1, marginTop: -5 }} textAlign="center">
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', sm: '2rem', md: '2.5rem' }, // Responsive font size
          }}
        >
          What can I help with?
        </Typography>
      </Grid>

      {/* Suggestions */}
      {currentSuggestions.map((card, key) => (
        <Grid
          item
          xs={6} // Two chips per row on small screens
          lg={3} // Four chips per row on large screens
          key={key}
          className={isTransitioning ? 'fade-exit-active' : 'fade-enter-active'}
        >
          <Chip
            sx={{
              width: '100%',
              height: '50px', // Adjust height for better visibility
              borderRadius: '25px', // Fully round the corners
              fontSize: '16px', // Adjust font size, // Make the text bold
              padding: '0 10px',
              backgroundColor: card.color.chip === 'contained' ? '#f5f5f5' : '',
            }}
            label={card.text}
            icon={
              <Box component="span">
                {React.cloneElement(card.icon, { style: { color: card.color.icon } })}
              </Box>
            }
            //color={card.color.chip}
            onClick={() => onClick(card)}
            
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default ChatSuggestion;

