import { Box, Button, Container, Grid, List, ListItem, Checkbox, MenuItem, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import CheckIcon from '@mui/icons-material/Check'
import GoogleLogin from "./Google/Login";
import HttpProvider from 'services/HttpProvider'
import { Link } from 'react-router-dom';
import axios from "axios";
import myUserStore from "../stores/UserStore";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const reasons = ["Get answers", "Share your setups with other drivers and your team", "Keep track and manage your setups", "Analyze your setups"]

function SignUpForm({ teamId }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [role, setRole] = useState("Driver");
  const [team, setTeam] = useState("Kart Republic");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [newTeam, setNewTeam] = useState("");
  const [teams, setTeams] = useState([]);
  const history = useHistory();
  const [referralCode, setReferralCode] = useState("");
  console.log("referral code", referralCode)
  const [teamid, setTeamid] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [personalHelp, setPersonalHelp] = useState(false); // Checkbox state
  const [phoneNumber, setPhoneNumber] = useState(""); // Phone number state
  const [selectedCountryCode, setSelectedCountryCode] = useState("+39"); // Default country code

  const countryCodes = [
    { code: "+39", country: "Italy", flag: "🇮🇹" },
    { code: "+1", country: "United States", flag: "🇺🇸" },
    { code: "+44", country: "United Kingdom", flag: "🇬🇧" },
    // Add more countries as needed
  ];
  const handlePersonalHelpChange = (e) => {
    setPersonalHelp(e.target.checked);
    if (!e.target.checked) {
      setPhoneNumber(""); // Clear phone number if unchecked
    }
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (confirmPassword && e.target.value !== confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (password && e.target.value !== password) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  };

  const isFormValid = () => {
    return (
      name &&
      surname &&
      city &&
      country &&
      email &&
      password &&
      confirmPassword &&
      password === confirmPassword
    );
  };

  const handleAddMember = async (user_id, team_id) => {
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/add_member/?role=MGR&team_id=${parseInt(team_id)}&status=ACTIVE&user_id=${user_id}`;
    await axios.post(
      apiUrl,
      {
        role: "DRV",
        team_id: parseInt(team_id),
        status: "ACTIVE",
        user_id: user_id
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      }
    ).then((response) => {
      // handle success
      return response
    }).catch((error) => {
      console.error("Error Api", error);
    });
  };

  const getTeams = () => {
    axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_teams/`).then((res) => {
      setTeams(res.data);
      return res.data
    }).catch((error) => {
      console.error("Axios Error:", error);
    });
  }

  const signInUser = (token, isGoogleSingIn = false) => {
    let userData
   if ( isGoogleSingIn ){
    return axios.get(`${process.env.REACT_APP_API_DOMAIN}/users/me/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    }).then(async (userResponse) => {
       userData = userResponse.data;
      if (teamId != undefined) {
        await handleAddMember(userData.user_id, teamId);
      }
      localStorage.setItem("user_data", JSON.stringify(userData));
      history.push("/pricing-sign-up");


      toast.success(`Your account was created ${!isGoogleSingIn ? ', please wait for approval!' : ''}`, {
        position: "top-right",
        autoClose: 2000, // Close after 3 seconds
        hideProgressBar: false,
      });

      

      return userData
    });
   }else{
    if (teamId != undefined) {
      setPendingTeams(teamId, token)
    }
    history.push('/activate_account_detail');

   }
    
    
  }

  useEffect(() => {
    getTeams()
  }, [newTeam]);

  const setPendingTeams= async (selectedTeam, token) => {
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/set_pending_teams/?team_id=${selectedTeam}`;
     await axios.post(
      apiUrl,
      {
        team_id: teamId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "application/json",
        },
      }
    )
      .then((res) => {
        console.log("Posted:");
      })
      .catch((error) => {
        console.log("Error");


        return error
      })
      
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/register_user/?name=${name}&surname=${surname}&promo_code=${referralCode}&city=${city}&country=${country}&contact=${phoneNumber}&email=${email}&password=${password}`;
    return axios.post(apiUrl)
      .then((res) => {
        const { access_token } = res.data
        return signInUser(access_token)
      })
      .catch((error) => {
        const { data } = error?.response || {}
        const message = data?.detail || data || "User not registered correctly!"
        setError(message);
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        return error
        console.error("Query Error:", error);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const addTeam = () => {
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/insert_team/?team_name=${newTeam}`;
    axios.post(apiUrl).then((response) => {
      if (response.status == 200) {
        setTeam(newTeam);
        setNewTeam("");
      }
      // toast.success("Data inserted correctly!", {
      //   position: "top-right",
      //   autoClose: 2000, // Close after 3 seconds
      //   hideProgressBar: false,
      // });
      handleClose();
    }).catch((error) => {
      toast.error("Data not inserted correctly!", {
        position: "top-right",
        autoClose: 2000, // Close after 3 seconds
        hideProgressBar: false,
      });
      console.error("Query Error:", error);
    });
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_teams/`).then((res) => {
      setTeams(res.data);
    }).catch((error) => {
      console.error("Axios Error:", error);
    });
  }, [newTeam]);

  const handleGoogleSignIn = async (response) => {
    setLoading(true);
    const token = response.credential;

    return HttpProvider.post('/google_token', { token })
      .then((response) => {
        if (response.data) {
          return signInUser(response.data.access_token, true)
        } else {
          toast.error("The account is not active, please check your inbox!", {
            position: "bottom-left",
          });
        }
      })
      .catch((error) => {
        // Handle login error
        toast.error("Error with the Login!", {
          position: "bottom-left",
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const handleGoogleError = (error) => {
    toast.error('Google sign-in failed!');
    console.error(error);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container
      maxWidth={true}
      fullHeight={true}
      sx={{
        textAlign: "center",
        backgroundColor: "#000",
        display: "flex",
        minHeight: 'calc(100% - 86px)',



        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Grid container spacing={4} justifyContent="center" alignItems="center" style={{ height: "100%" }}>
        <Grid item xs={12} md={6} className="text-light" sx={{ display: { lg: 'flex', xs: 'none' }, flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", padding: "20px" }}>
          <Typography variant="h2" component="h2" style={{ color: "#535bf2", fontSize: "2.5rem", fontWeight: "bold", marginBottom: "20px", marginLeft: "20px" }}>
            Join our community to:
          </Typography>
          <List dense size="sm" sx={{ p: 0, mt: 2 }}>
            {reasons.map((reason, index) => (
              <ListItem key={index} sx={{ px: 0, py: { md: 0, lg: 0.5 } }}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <CheckIcon color='light' />
                </ListItemIcon>
                <ListItemText primary={reason} primaryTypographyProps={{ fontSize: '16px' }} />
              </ListItem>
            ))}
          </List>
          <div style={{ fontSize: "1rem", textAlign: "center", marginTop: "30px" }}>
            <Typography style={{ color: "white", marginBottom: "10px" }}>
              Join other teams and drivers  advancing with AiMotor like:
            </Typography>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginBottom: "20px" }}>

            </div>
            <img
              src={`${process.env.PUBLIC_URL}/images/kart-republic-logo-white.png`}
              alt="Logo"
              style={{ width: 100, marginBottom: '0.4rem' }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              p: 4,
              backgroundColor: "#1c1c1e",
              borderRadius: 2,
              boxShadow: 3,
              textAlign: "left",
              maxWidth: 400,
              width: "100%"
            }}
          >
            <Typography variant="h5" component="h2" gutterBottom style={{ color: "white", textAlign: "center", marginBottom: "20px" }}>
              Sign Up to your Account
            </Typography>
            <Box display="flex" justifyContent="center" marginBottom="20px">
              <GoogleLogin
                onSuccess={handleGoogleSignIn}
                onError={handleGoogleError}
              />
            </Box>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                sx: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                    WebkitTextFillColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Surname"
              variant="outlined"
              fullWidth
              margin="normal"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                sx: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                    WebkitTextFillColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="City"
              variant="outlined"
              fullWidth
              margin="normal"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                sx: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                    WebkitTextFillColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Country"
              variant="outlined"
              fullWidth
              margin="normal"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                sx: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                    WebkitTextFillColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Email address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                sx: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                    WebkitTextFillColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={handlePasswordChange}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                sx: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                    WebkitTextFillColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                sx: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                    WebkitTextFillColor: 'white',
                  },
                },
              }}
            />
            {passwordError && <Typography color="error" mb={2}>{passwordError}</Typography>}
            <TextField
              label="Referral Code (not mandatory)"
              variant="outlined"
              fullWidth
              margin="normal"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                sx: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                    WebkitTextFillColor: 'white',
                  },
                  '& fieldset': {
                    borderColor: '#535bf2', // Colore blu violetto per il bordo
                  },
                  '&:hover fieldset': {
                    borderColor: '#7a7fff', // Colore leggermente più chiaro al passaggio del mouse
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#535bf2', // Colore blu violetto quando è attivo
                  },
                },
              }}
            />
            <Box display="flex" alignItems="center" marginY={2}>
              <Checkbox checked={personalHelp}sx={{color:' white', marginRight: 2}} onChange={handlePersonalHelpChange} />
              <Typography variant="body1" style={{ color: 'white' }}>
                I want to recieve personalised support from AiMotor team
              </Typography>
            </Box>
            {personalHelp && (
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                margin="normal"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{
                  style: { color: "white" },
                  sx: {
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                      WebkitTextFillColor: 'white',
                    },
                  },
                }}
              />
            )}





            <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
              {/*<Box display="flex" alignItems="center">
                <input type="checkbox" id="keepMeLoggedIn" />
                <label htmlFor="keepMeLoggedIn" style={{ color: 'white', marginLeft: '8px' }}>Keep me logged in</label>
              </Box>*/}
            </Box>
            <Box my={2}>
              <Typography variant="body2" style={{ color: 'white' }}>
                Already registered ? <Link to={`/sign-in?teamId=${teamId}`} style={{ color: '#535bf2' }}>Log In</Link>
              </Typography>
            </Box>
            {error && <Typography color="error" mb={2}>{error}</Typography>}
            <Button variant="contained" type="submit" fullWidth color="primary" disabled={loading || !isFormValid()}>
              {loading ? "Logging in..." : "Sign Up"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default observer(SignUpForm);