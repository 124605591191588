import React from "react";
import "../components.css";
import { Redirect } from "react-router-dom";
import ViewDataComponent from "../ViewDataComponent";

function ViewData() {
  return (
    <>
      {localStorage.getItem("access_token") ? (
        <ViewDataComponent />
      ) : (
        <Redirect to="/sign-in" />
      )}
    </>
  );
}

export default ViewData;
