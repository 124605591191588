import React, { useState, useEffect } from "react";
import axios from "axios"; // Importing axios directly from the axios library
import { Table, Container, Form, Col, Stack} from "react-bootstrap";
import RaceFormDriver from "../RaceFormDriver";
import DriverAnalysisDriver from "../DriverAnalysisDriver";



const CombinedComponent = () => {

 

  const [selectedComponent, setSelectedComponent] = useState("");
  const [selectedRaceWeekend, setSelectedRaceWeekend] = useState("");
  const [userData, setUserData] = useState(null);
  const [competitions, setCompetitions] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState("");
  const [race, setRace] = useState("");

  const handleSelect = (event) => {
    setSelectedComponent(event.target.value);
  };

  const handleRaceWeekendChange = (event) => {
    setSelectedRaceWeekend(event.target.value);
  };

 

  return (
    <div>
      <Container>
      <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Select Race:</Form.Label>
              <Stack direction="horizontal" gap={1}>
                <Form.Select
                  name="track"
                  value={race}
                  placeholder="Select Race"
                  onChange={(e) => setRace(e.target.value)}
                  required
                >
                  <option value="">Select Race</option>
                  {competitions.map((competition) => (
                    <option
                      key={competition["competition"]}
                      value={competition["id"]}
                    >
                      {competition["competition"]} ({competition["track"]})
                    </option>
                  ))}
                </Form.Select>
              </Stack>
            </Form.Group>
          </Col>

        {/* Move the select tool dropdown outside of the conditional check */}
        <Col xs={12} sm={6} md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Select Tool:</Form.Label>
            <Form.Select
              value={selectedComponent}
              onChange={handleSelect}
              required
            >
              <option value="">Select Tool</option>
              <option value="RaceFormDriver">Live Timing</option>
              <option value="DriverAnalysis">Setup Analysis</option>
              <option value="TeamAnalysis">Team Analysis</option>
            
            </Form.Select>
          </Form.Group>
        </Col>

        <div>
          {selectedComponent === "RaceFormDriver" && <RaceFormDriver raceWeekend={selectedRaceWeekend} />}
          {selectedComponent === "DriverAnalysis" && <DriverAnalysisDriver raceWeekend={selectedRaceWeekend}/>}
   
       
        </div>
      </Container>
    </div>
  );
};


export default CombinedComponent;