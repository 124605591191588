import React from 'react';
import '../components.css';
import RemoteForm from '../RemoteForm.js';


function Home() {
  return (
    <>
       <RemoteForm/> 
    </>
  );
}

export default Home;