import { Box, Chip, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import HttpProvider from 'services/HttpProvider'
import StepperContentLoader from './Loaders';
import { observer } from "mobx-react-lite";

const racingDays = [1,2,3,4,5]

const SelectDay = ({
  activeRace = '',
  activeDay = '',
  onChange = () => {},
  ...props
}) => {
  const [days, setDays] = useState(racingDays);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSessionDay, setDay] = useState(activeDay);

  const handleClick = (day) => {
    setDay(day)
    onChange(day)
  }

  const getDays = () => {
    setIsLoading(true);
    return HttpProvider.get(`/get_day?competition=${activeRace}`)
      .then((res) => {
        if (res.data.length) {
          //setDays(res.data);
        }
      })
      .catch((err) => {
        console.error('Error fetching competitions:', err);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  useEffect(() => {
    getDays();
  }, [activeDay]);

  return (
    <Box textAlign="center" {...props}>
      {
        isLoading ? <StepperContentLoader times={6} />
        : 
        <Box direction="row" flexWrap={"wrap"}>
          {
          days.map((day, index) => (
            <Chip
              label={`Day - ${day}`}
              key={index}
              color={day === currentSessionDay ? 'primary': 'default'}
              onClick={() => handleClick(day)} sx={{ mb: 1.5, mr: 2 }}
            />
          ))
          }
        </Box>
      }
    </Box>
  );
};

export default observer(SelectDay);