import React, { useState } from "react";

const EditableCellNumeric = ({ value, onSave, field, id, max, min }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const [isHovered, setIsHovered] = useState(false);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onSave(editValue, field, id);
  };

  const handleChange = (e) => {
    setEditValue(e.target.value);
  };

  return (
    <td
      onDoubleClick={handleDoubleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        cursor: "pointer",
        backgroundColor: isHovered ? "#f0f0f0" : "transparent"
      }}
    >
      {isEditing ? (
        <input
          type="number"
          value={editValue}
          onChange={handleChange}
          min={min}
          max={max}
          onBlur={handleBlur}
          autoFocus
          style={{ minWidth: "20px" }}
        />
      ) : (
        <span>{editValue}</span>
      )}
    </td>
  );
};

export default EditableCellNumeric;

