import React, { useState } from "react";
import { DialogContent, DialogTitle, Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";

const ContactInside = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { firstName, lastName, email, message } = formData;
    const accessToken = localStorage.getItem("access_token");
  
    const payload = new URLSearchParams({
        name: firstName,
        surname: lastName,
        email: email,
        message: message,
      }).toString();
  
    const url = `${process.env.REACT_APP_API_DOMAIN}/contact_message/?${payload}`;
  
    console.log("Request URL:", url);
    console.log("Payload:", payload);
  
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
              accept: "application/json",
            },
          });
  
      if (response.status === 200) {
        console.log("Response Data:", response.data);
        toast.success("Message sent successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
  
        // Reset the form
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
      } else {
        throw new Error(`Failed with status code: ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to send the message!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
  };
  

  return (
    <>
      <DialogTitle>Contact Us</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Send
          </Button>
        </form>
      </DialogContent>
    </>
  );
};

export default ContactInside;
