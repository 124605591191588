import React from 'react';
import '../components.css';
import RaceForm from '../RaceForm.js';


function Home() {
  return (
    <>
       <RaceForm/> 
    </>
  );
}

export default Home;