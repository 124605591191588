import React from 'react';
import './components.css';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container'

function FutureUpdates(){
  return(
    <Container fluid className="mission-container">
      <Row>
        <Col>
          <h1 className='content-overlay'>
                This function will soon be available. <br/>
                We appreciate your patience and can't wait to unveil it for you!
          </h1>
        </Col>
      </Row>
    </Container>
      
      
  )
}

export default FutureUpdates