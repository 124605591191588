import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import GoogleLogin from "./Google/Login";
import HttpProvider from 'services/HttpProvider'
import { Link } from 'react-router-dom';
import PaypalProvider from "services/PaypalProvider";
import Pricing from "./pages/Pricing";
import axios from "axios";
import { getToken } from "services/PaypalHelper";
import { googleLogout } from '@react-oauth/google';
import myUserStore from "../stores/UserStore";
import { observer } from "mobx-react-lite";
import qs from "qs";
import { toast } from "react-toastify";
import { transformData } from "helpers/utils";
import { useHistory } from "react-router-dom";

function SignInForm({ teamId }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const [show, setShow] = useState(false);
  let hasPayed = true;
  console.log("the team id is:", teamId);

  const handleClick = () => setShow(!show);

  const getPaypalSubscription = async (subId) => {
    const token = await getToken();
    if (token) {
      return PaypalProvider.get(`billing/subscriptions/${subId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          
          const subs = res.data
          postSubscription(subs);
          return subs
        })
        .catch((err) => {
          console.error('Error getting subscription details:', err);
          return {}
        })
    } else {
      return {}
    }
  }

  const getSettings = (access_token, user_data) => {
    return HttpProvider.get('/get_settings',
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          accept: "application/json",
        },
      }
    )
      .then((res) => {
        res.data.owner.forEach((ownerItem) => {
          const teamExists = res.data.teams.some(
            (team) => team.team_id === ownerItem.team_id
          );
          if (!teamExists) {
            console.log("Membro inserito nel team", ownerItem.team_id);
          }
        });
        const transformedData = transformData(
          res.data,
          user_data.user_id,
          user_data.email,
          user_data.surname
        );

        // controllo se ho selected teams
        const local = myUserStore.getSelectedTeam;

        // cerco dentro i team della get_settings
        // se esiste il team è gia presente
        const alreadySelected = transformedData.teamInfo.find(
          (t) => t.id === local.id
        );

        console.log(myUserStore.getSelectedTeam);
        //  se non presente lo aggiungo
        // aggiungo il primo team della get_settings
        if (Object.keys(local).length === 0 || !alreadySelected) {
          const selectedTeam = transformedData.teamInfo[0] || {};
          localStorage.setItem("selectedTeam", JSON.stringify(selectedTeam));
          myUserStore.setSelectedTeam(selectedTeam);
        }
        console.log("Team info", transformData(res.data));
        return transformedData;
      })
      .catch((err) => {
        console.err(err)
        return err
      })
  };

  const postSubscription = async (subscriptionDetails) => {
    await HttpProvider.post('/post_subscription/', { subscriptionDetails },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      }
    )
  };

  function isMoreThanAMonth(last_payment) {
    const lastPay = new Date(last_payment);
    const currentDate = new Date();
    const oneMonthLater = new Date(lastPay.setMonth(lastPay.getMonth() + 1));
    return currentDate > oneMonthLater;
  }

  const hasSubscription = async (access_token) => {
    return HttpProvider.get(`/get_subscription/`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          accept: "application/json",
        },
      }
    )
      .then(async (res) => {
        const { subscriptionDetails = {} } = res.data
        const {status} = res
        const subId = subscriptionDetails.id
        const paypalStatus = subscriptionDetails.status
        const billing_info = subscriptionDetails.billing_info

       // const { status: paypalStatus, billing_info } = await getPaypalSubscription(subId) @denis qua prendo la info del cancellato da db e non da paypal
        const { create_time: createTime } = subscriptionDetails; // "2024-09-14T14:16:16Z"

        // Parse the `create_time` to a Date object
        const createTimeDate = new Date(createTime);

        // Get the current date
        const currentDate = new Date();

        // Construct the `lastPayment` date
        const lastPayment = new Date(
          currentDate.getFullYear(), // Current year
          currentDate.getMonth(), // Current month (0-indexed, so 0 is January)
          createTimeDate.getDate(), // Day from `create_time`
          createTimeDate.getHours(), // Hour from `create_time`
          createTimeDate.getMinutes(), // Minutes from `create_time`
          createTimeDate.getSeconds() // Seconds from `create_time`
        ).toISOString();

        if (
          status === "error" ||
          (!paypalStatus || (paypalStatus === "CANCELLED" && isMoreThanAMonth(lastPayment)) ) 
        ) {
          myUserStore.setUserSubscriptionDetails([]);
          // no subscription found
          return false
        } else {
          // subscription is ok
          myUserStore.setUserSubscriptionDetails(subscriptionDetails);
          return true
        }
        // @todo @denis perche viene chiamato paypal per la subscription?
      })
      .catch((err) => {
        return false
      })
  };


  const handleAddMember = async (user_id, team_id) => {
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/add_member/?role=DRV&team_id=${parseInt(
      team_id
    )}&status=ACTIVE&user_id=${user_id}`;
    await axios
      .post(
        apiUrl,
        {
          role: "DRV",
          team_id: parseInt(team_id),
          status: "ACTIVE",
          user_id: user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
      .then((response) => {
        // toast.success("Data inserted correctly!", {
        //   position: "top-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        // });
      })
      .catch((error) => {
        console.error("Error Api", error);
      });
  };

  const getUserDetail = (token) => {
    return axios.get(`${process.env.REACT_APP_API_DOMAIN}/users/me/`, { //ho tolto user 
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        return false
      })
  }

  const logInUser = async (token) => {
    localStorage.setItem("access_token", token);
    const userData = await getUserDetail(token)

    if (!userData) {
      toast.error("Error with user", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      return false
    } else {

      localStorage.setItem("user_data", JSON.stringify(userData));
      const hasSub = await hasSubscription(token)
      if (!hasSub) {
        if (teamId) {
          await handleAddMember(userData.user_id, teamId);
          // da aggiungere endpoint per associazione utente a team passare nella chiamata user email + teamId
        }
        history.push("/pricing-sign-up");
      } else {
        myUserStore.setIsLoggedIn(!!token)
        await getSettings(token, userData);

        localStorage.setItem("user_data", JSON.stringify(userData));

        // Continue with your logic, e.g., redirect to home
        if (teamId) {
          await handleAddMember(userData.user_id, teamId);
          // da aggiungere endpoint per associazione utente a team passare nella chiamata user email + teamId
        }

      
        history.push("/racing-space");
        return userData
      }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setError("");

    const formData = qs.stringify({
      grant_type: "",
      username: email,
      password: password,
      scope: "",
      client_id: "",
      client_secret: "",
    });

    await axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/token`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accept: "application/json",
          },
        }
      )
      .then((response) => {
        const token = response.data.access_token;
        // Store the token in local storage
        return logInUser(token)
      })
      .catch((error) => {
        toast.error("User login failed!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.error("SignIn Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const responseMessage = async (response) => {
    setLoading(true);
    const token = response.credential;

    return HttpProvider.post('/google_token', { token })
      .then((response) => {
        if (response.data) {
          return logInUser(response.data.access_token)
        } else {
          toast.error("The account is not active, please check your inbox!", {
            position: "bottom-left",
          });
        }
      })
      .catch((error) => {
        // Handle login error
        googleLogout()
        toast.error("Error with the Login!", {
          position: "bottom-left",
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const errorMessage = (error) => {
    toast.error('Google sign-in failed!');
    console.error(error);
  };

  return (
    <Container
      maxWidth={false}
      style={{
        textAlign: "center",
        backgroundColor: "#000",
        minHeight: teamId ? "100%": 'calc(100% - 86px)',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
      }}
    >
      {hasPayed && (
        <Grid container spacing={4} justifyContent="center" alignItems="center" >
          <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              
              sx={{
                p: 4,
                backgroundColor: "#1c1c1e",
                borderRadius: 2,
                boxShadow: 3,
                textAlign: "left",
                maxWidth: 400,
                width: "100%",
                margin: "0 auto",
                p: { xs: 2, sm: 4 },
                overflowY: 'auto',
                maxHeight: '100%',


              }}
            >
              <Typography variant="h5" component="h2" gutterBottom style={{ color: "white", textAlign: "center" }}>
                {hasPayed ? 'Log In to your Account' : '344'}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <GoogleLogin
                  onSuccess={responseMessage}
                  onError={errorMessage}
                />
              </Box>
              <Typography variant="body1" align="center" style={{ color: "white", marginBottom: "16px" }}>OR</Typography>
              <TextField
                label="Email address"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{
                  style: { color: 'white' },
                  sx: {
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                      WebkitTextFillColor: 'white',
                    },
                  },
                }}
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{
                  style: { color: 'white' },
                  sx: {
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 1000px #1c1c1e inset',
                      WebkitTextFillColor: 'white',
                    },
                  },
                }}
              />
              <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
                <Link to={"/recover-password"} style={{ color: '#535bf2' }}>Forgot Password?</Link>
              </Box>
              <Box my={2}>
                <Typography variant="body2" style={{ color: 'white' }}>
                  Not registered yet? <Link to={`/sign-up?teamId=${teamId}`} style={{ color: '#535bf2' }}>Create an Account</Link>
                </Typography>
              </Box>
              {error && <Typography color="error" mb={2}>{error}</Typography>}
              <Button variant="contained" type="submit" fullWidth color="primary" disabled={loading}>
                {loading ? "Logging in..." : "Login"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      <Pricing open={!hasPayed} showAll={"showAll"} onClose={() => { hasPayed = true; setLoading(false); }} />
    </Container>
  );
}

export default observer(SignInForm);