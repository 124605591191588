import React from 'react';
import './components.css';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';

function LearnForm() {
  return (
    <Container fluid className="learnform-container padding-box text-big">
      <Row >
        <Col>
          <p className='LearnForm-overlay'>
            To accelerate your performance we need to trace your data.<br/>
          </p>
        </Col>
      </Row>
      <Row >
        <Col>
          <p className='LearnForm-overlay'>
            Tracking setups on the track is however 
            laborious because it requires time and tools
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className='LearnForm-overlay'>
            AiMotor has the answer: with <span className="highlight">autocompilation</span>, recording your data will only take a few moments.<br/>
            Afterwards, you can analyze them using our <span className="highlight">intuitive A.I features</span>.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default LearnForm;

// Tracking setups on the track is crucial but also laborious. Crucial to identify the setup that got you going the fastest for each circuit and climate. Laborious because it requires time and tools. 
// AiMotor has the answer: with autocompilation, recording your data will only take a few moments. Afterwards, you can analyse them using our intuitive graphs.
