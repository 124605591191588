import { Box, Grid, Stack, Typography } from '@mui/material';

export default function ImageTextCard({ imageUrl, imageAlt = '', title, description, invert = false, ...props }) {
  return (
    <Box
      {...props}
      sx={{
        backgroundColor: 'rgb(34, 34, 34)',
        borderRadius: 2,
        overflow: 'hidden',
        width: '100%',
        ...(props.sx || {}),
        height: 'auto', 
      }}
    >
      <Grid container alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }}>
        <Grid item md={6} order={{ xs: 1, lg: invert ? 2 : 1 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img 
            src={imageUrl} 
            alt={imageAlt || title} 
            className='img-fluid'
            style={{ 
              maxWidth: '100%', 
              height: 'auto', 
              objectFit: 'contain', 
            }} 
          />
        </Grid>
        <Grid item md={6} order={{ xs: 2, lg: invert ? 1 : 2 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Stack spacing={1} py={4} px={{ xs: 2, md: 6 }} alignItems={'center'} justifyContent={'center'} color='#fff'>
            <Typography fontSize={'24px'} fontWeight={700} textAlign="center">{title}</Typography>
            <Typography fontSize={'16px'} textAlign="center">{description}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
