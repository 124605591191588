import React, { useState } from "react";
import { Form } from "react-bootstrap";

const EditableCellSelect = ({ value, onSave, field, id, vals }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const mapper = vals.reduce((acc, item) => {
    acc[item.label] = item.value;
    return acc;
  }, {});
  const invMapper = vals.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onSave(editValue, field, id);
  };

  const handleChange = (e) => {
    setEditValue(e.target.value);
  };

  return (
    <td onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        // <Form.Select
        <select
          value={editValue}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        >
          {vals.map((val) => (
            <option key={val["value"]} value={val["value"]}>
              {val["label"]}
            </option>
          ))}
          {/* </Form.Select> */}
        </select>
      ) : (
        <span>{invMapper[editValue]}</span>
      )}
    </td>
  );
};

export default EditableCellSelect;
