import React, { useState } from "react";
import "./components.css";
import { Box, Button, Grid, Stack, TextField, Typography, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import { Link } from "react-router-dom";
import NewLogo from './NewLogo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const footerLinks = [
  {
    title: 'About Us',
    links: [
      { label: 'Clients', href: '/clients' }
    ]
  },
  {
    title: 'Contact us',
    links: [
      { label: 'Support', href: '/contact-us' },
      { label: 'Whatsapp', href: 'https://wa.me/393397636905' }
    ]
  },
  {
    title: 'Terms & policies',
    links: [
      { label: 'Terms of Service', href: '/terms-of-service' },
      { label: 'Privacy policy', href: '/privacy' }
    ]
  },
  {
    title: 'Testimonials',
    links: [
      { label: 'Baccaglini ', href: 'https://youtu.be/fWnnMfYSZb8?si=HGwAFYpKzlHzRAEze' },
    ]
  },
  {
    title: 'Social media',
    links: [
      { label: 'Instagram', href: 'https://www.instagram.com/aimotor.kart/' },
      { label: 'Facebook', href: 'https://www.facebook.com/profile.php?id=61554863766530' },
      { label: 'X', href: '/' },
      { label: 'YouTube', href: '/' }
    ]
  },
  {
    title: 'Leave us a testimonial',
    links: [
      { label: 'Submit Video', href: '/' },
      { label: 'Ambassadors', href: '/' }
    ]
  }
];

function Footer() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // State to manage the dialog
  const [openDialog, setOpenDialog] = useState(false);

  // Function to handle dialog open
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  // Function to handle dialog close
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="footer-container">
      <div className="footer-line"></div>
      <Stack spacing={{ xs: '40px', md: '90px' }} className="container-lg">
        <section className="footer-subscription">
          <Grid container spacing={4} alignItems={'center'}>
            <Grid item xs={12} lg={8}>
              <Stack>
                <Typography variant="h4">Join AiMotor</Typography>
                <Typography variant="p">Subscribe to AiMotor newsletter to receive the latest news and announcements</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <form>
                <Stack direction="row" spacing={3} justifyContent={'center'} alignItems={'center'}>
                  <input
                    className="footer-input"
                    name="email"
                    type="email"
                    placeholder="Your Email"
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDialogOpen} // Open dialog on click
                  >
                    SUBSCRIBE
                  </Button>
                </Stack>
                <Typography as="p" variant="caption" mt="4px" textAlign={'left'} sx={{ opacity: 0.7 }}>
                  You can unsubscribe at any time.
                </Typography>
              </form>
            </Grid>
          </Grid>
        </section>
        <section className="footer-links">
          <Grid container spacing={4}>
            <Grid item md={5} hidden={!isDesktop}>
              <Link to="/" className="footerNew-logo">
                <img src={NewLogo} alt="AiMotors logo" />
              </Link>
            </Grid>
            <Grid item container md={7} rowSpacing={{ xs: 4, md: 0 }} justifyContent="flex-end">
              {
                footerLinks.slice(0, 4).map((item, index) => (
                  <Grid item xs={12} md={3} key={index}>
                    <Stack spacing={2} alignItems={isDesktop ? "flex-end" : "flex-start"}>
                      <Typography variant="subtitle2" color="white" fontWeight={600} sx={{ opacity: 0.6 }}>
                        {item.title}
                      </Typography>
                      <Stack spacing={1} className="footer-link-items" alignItems={isDesktop ? "flex-end" : "flex-start"}>
                        {
                          item.links.map((link, j) => (
                            link.href.startsWith('http') ?
                              <a key={`${index}-${j}`} href={link.href} target="_blank" rel="noopener noreferrer">{link.label}</a> :
                              <Link key={`${index}-${j}`} to={link.href}>{link.label}</Link>
                          ))
                        }
                      </Stack>
                    </Stack>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </section>
        <section className="social-media">
          <div className="social-media-wrap">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <small className="website-rights">© 2024. All right reserved.</small>
              </Grid>
              <Grid item xs={12} sm={6} mt={{ xs: 1, sm: 0 }}>
                <Stack className="social-icons" direction="row" spacing={4} ml={'auto'} justifyContent={'end'}>
                  <a className="social-icon-link instagram" href="https://www.instagram.com/aimotor.eu?igsh=MTBqNThobGE0ZzRicA==" target="_blank" aria-label="Instagram" rel="noopener noreferrer">
                    <i className="fab fa-instagram" />
                  </a>
                  <a className="social-icon-link facebook" href="https://www.facebook.com/profile.php?id=61554863766530" target="_blank" aria-label="Facebook" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a className="social-icon-link youtube" href="https://www.youtube.com/@user-jd3gi9kk9q" target="_blank" aria-label="Youtube" rel="noopener noreferrer">
                    <i className="fab fa-youtube" />
                  </a>
                  {/* <a className="social-icon-link twitter" href="/" target="_blank" aria-label="Twitter" rel="noopener noreferrer">
                    <i className="fab fa-twitter" />
                  </a> */}
                  <a className="social-icon-link linkedin" href="https://www.linkedin.com/company/aimotorsport/" target="_blank" aria-label="LinkedIn" rel="noopener noreferrer">
                    <i className="fab fa-linkedin" />
                  </a>

                  <a className="social-icon-link whatsapp" href="https://wa.me/393397636905" target="_blank" aria-label="WhatsApp" rel="noopener noreferrer">
                    <i className="fab fa-whatsapp" />
                  </a>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </section>
      </Stack>

      {/* Dialog Implementation */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Newsletter Subscription</DialogTitle>
        <DialogContent>
          <Typography>The newsletter will be soon available!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Footer;
