import "./components.css";
import { Box, Button, Chip, Drawer, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MenuIcon from '@mui/icons-material/Menu';
import NewLogo from './NewLogo.png';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import myUserStore from "../stores/UserStore";
import { observer } from "mobx-react-lite";
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TimelineIcon from '@mui/icons-material/Timeline';
function Navbar({ isLoggedIn }) {
    const history = useHistory();
    const [click, setClick] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const isTabletOrLarger = useMediaQuery(theme.breakpoints.up('md'));

    const handleProductsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    const logOut = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_data");
        myUserStore.setIsLoggedIn(false);
        history.replace("/sign-in");
        setClick(false);
    };

    const logIn = () => {
        history.push("/sign-in");
    };

    const StyledButton = styled(Button)(({ theme }) => ({
        backgroundColor: '#fff',
        color: 'black',
        borderRadius: '25px',
        padding: '10px 20px',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    }));

    const signUp = () => {
        history.push("/sign-up");
    };

    const getStarted = () => {
        history.push("/get-started");
    };

    const toggleDrawer = (boolval = false) => {
        setOpenMenu(boolval);
    };

    const handleDrawerProductsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <nav className="navbar px-2 px-lg-0">
                <div className="container-lg navbar-container" style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to="/" className="navbar-logo" onClick={() => toggleDrawer(false)}>
                        <img src={NewLogo} alt="AiMotors logo" />
                    </Link>
                    <Box className="nav-buttons" hidden={!isDesktop} sx={{ display: 'flex', alignItems: 'center', marginLeft: '3rem' }}>
                        <Button component={Link} to="/pricing-fixed" sx={{ color: 'white', marginRight: '2rem', fontSize: '1rem' }}>
                            Pricing
                        </Button>
                        {isTabletOrLarger && (
                            <Button
                                sx={{ color: 'white', marginRight: '2rem', fontSize: '1rem' }}
                                onClick={handleProductsClick}
                            >
                                Products
                            </Button>
                        )}
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{
                                sx: {
                                    backgroundColor: '#1c1c1e',
                                    color: 'white',
                                    borderRadius: '8px',
                                    mt: '1rem',
                                },
                            }}
                        >
                            <MenuItem component={Link} to="/ai-product" onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                <SmartToyRoundedIcon sx={{ marginRight: '8px' }} />
                                Generative AI model
                            </MenuItem>
                            <MenuItem component={Link} to="/insert-product" onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                <AddIcon sx={{ marginRight: '8px' }} />
                                Data Insertion
                            </MenuItem>
                            <MenuItem component={Link} to="/archive-product" onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                <ArchiveIcon sx={{ marginRight: '8px' }} />
                                Data Archive
                            </MenuItem>
                            <MenuItem component={Link} to="/analytics-product" onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                <LeaderboardIcon sx={{ marginRight: '8px' }} />
                                Setup Analytics
                            </MenuItem>
                            <MenuItem component={Link} to="/driver-analytics-product" onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                <TimelineIcon sx={{ marginRight: '8px' }} />
                                Driver Analytics
                            </MenuItem>
                            {isTabletOrLarger && (
                                <MenuItem component={Link} to="/team-environment-product" onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <GroupsIcon sx={{ marginRight: '8px' }} />
                                    Racing Team
                                </MenuItem>
                            )}
                        </Menu>
                    </Box>
                    <Box className="menu-icon" hidden={isDesktop} sx={{ marginLeft: 'auto' }}>
                        <IconButton color="inherit" onClick={() => toggleDrawer(true)} sx={{ color: 'white' }}>
                            <MenuIcon fontSize="large" />
                        </IconButton>
                    </Box>
                    <Box className="nav-buttons" hidden={!isDesktop} sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                        <Button variant="contained" color="light" onClick={getStarted}>
                           Sign In
                        </Button>
                    </Box>
                </div>
            </nav>
            <Drawer
                open={openMenu}
                onClose={() => toggleDrawer(false)}
                anchor={"left"}
                PaperProps={{
                    sx: { maxWidth: '320px', width: '100%', backgroundColor: '#1c1c1e' },
                }}
            >
                <Stack spacing={4}>
                    <Box className="menu-icon" textAlign="right">
                        <IconButton color="inherit" onClick={() => toggleDrawer(false)} sx={{ color: 'white' }}>
                            <CloseIcon fontSize="medium" />
                        </IconButton>
                    </Box>
                    <Stack spacing={3} alignItems={'center'}>
                        <Chip
                            label="Sign In"
                            clickable
                            component="a"
                            href="/sign-in"
                            onClick={() => toggleDrawer(false)}
                            sx={{ color: 'white', backgroundColor: '#333', '&:hover': { backgroundColor: '#555' }, fontSize: '1.3rem', height: '48px', width: '220px' }}
                        />
                        <Chip
                            label="Sign Up"
                            clickable
                            component="a"
                            href="/sign-up"
                            onClick={() => toggleDrawer(false)}
                            sx={{ color: 'white', backgroundColor: '#333', '&:hover': { backgroundColor: '#555' }, fontSize: '1.3rem', height: '48px', width: '220px' }}
                        />
                        <Chip
                            label="Pricing"
                            clickable
                            component="a"
                            href="/pricing-fixed"
                            onClick={() => toggleDrawer(false)}
                            sx={{ color: 'white', backgroundColor: '#333', '&:hover': { backgroundColor: '#555' }, fontSize: '1.3rem', height: '48px', width: '220px' }}
                        />
                        <Chip
                            label="Products"
                            clickable
                            onClick={handleDrawerProductsClick}
                            sx={{ color: 'white', backgroundColor: '#333', '&:hover': { backgroundColor: '#555' }, fontSize: '1.3rem', height: '48px', width: '220px' }}
                        />
                    </Stack>
                </Stack>
            </Drawer>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                    sx: {
                        backgroundColor: '#1c1c1e',
                        color: 'white',
                        borderRadius: '8px',
                        mt: '1rem',
                    },
                }}
            >
               <MenuItem 
    component={Link} 
    to="/ai-product" 
    onClick={handleMenuClose} 
    sx={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }} // Adjust font size here
>
    <SmartToyRoundedIcon sx={{ marginRight: '8px' }} />
    Generative AI model
</MenuItem>
<MenuItem 
    component={Link} 
    to="/insert-product" 
    onClick={handleMenuClose} 
    sx={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }} // Adjust font size here
>
    <AddIcon sx={{ marginRight: '8px' }} />
    Data Insertion
</MenuItem>
<MenuItem 
    component={Link} 
    to="/archive-product" 
    onClick={handleMenuClose} 
    sx={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }} // Adjust font size here
>
    <ArchiveIcon sx={{ marginRight: '8px' }} />
    Data Archive
</MenuItem>
<MenuItem 
    component={Link} 
    to="/analytics-product" 
    onClick={handleMenuClose} 
    sx={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }} // Adjust font size here
>
    <LeaderboardIcon sx={{ marginRight: '8px' }} />
    Setup Analytics
</MenuItem>
                <MenuItem component={Link} to="/driver-analytics-product" onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center' , fontSize: '1.2rem' }}>
                                <TimelineIcon sx={{ marginRight: '8px' }} />
                                Driver Analytics
                            </MenuItem>
         
                    <MenuItem component={Link} to="/team-environment-product" onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem'  }}>
                        <GroupsIcon sx={{ marginRight: '8px' }} />
                        Racing Team
                    </MenuItem>
          
            </Menu>
        </>
    );
}

export default Navbar;
