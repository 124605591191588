import React, { useState } from 'react';
import { Box, Typography, Card, CardMedia, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTheme, useMediaQuery } from '@mui/material';
import desktopImage from '../../img/kai_video_cover.jpg';
import mobileImage from '../../img/kai_cover_mobile.jpg';

const VideoLanding = () => {
  const [showVideo, setShowVideo] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePlayClick = () => {
    setShowVideo(true);
  };

  const videoSrc = isMobile ? '/videos/kai_mobile.mp4' : '/videos/kai.mp4';
  const coverImage = isMobile ? mobileImage : desktopImage;

  return (
    <Box
      sx={{
        backgroundColor: '#000',
        color: '#fff',
        minHeight: '60vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: 4,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          mb: 4,
        }}
      >
        AiMotor Coaching
      </Typography>

      {!showVideo ? (
        <Card
          sx={{
            margin: '0 auto',
            position: 'relative',
            width: { xs: '85%', sm: '85%', md: '85%', lg: '90%' },
            maxWidth: '1000px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
        >
          <CardMedia
            component="img"
            src={coverImage}
            alt="AiMotor Coaching Preview"
            sx={{
              width: '100%',
              height: 'auto',
              maxHeight: '90vh',
              display: 'block',
              objectFit: 'cover',
              borderRadius: 2,
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              bottom: 2,
              left: 16,
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
            }}
          >
            <Button
              onClick={handlePlayClick}
              sx={{
                backgroundColor: '#fff',
                color: '#000',
                borderRadius: '50%',
                width: 56,
                height: 56,
                minWidth: 'unset',
                '&:hover': {
                  backgroundColor: '#ccc',
                },
              }}
            >
              <PlayArrowIcon />
            </Button>
            <Typography
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '1.2rem',
                textShadow: '0 0 4px rgba(0,0,0,0.6)',
              }}
            >
              Watch the video!
            </Typography>
          </Box>
        </Card>
      ) : (
        <Card
          sx={{
            margin: '0 auto',
            width: { xs: '85%', sm: '90%', md: '85%' },
            maxWidth: '1200px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
        >
          <CardMedia
            component="video"
            src={videoSrc}
            title="AiMotor Coaching Video"
            autoPlay
            controls
            muted
            playsInline
            sx={{
              borderRadius: 2,
              width: '100%',
              height: 'auto',
            }}
          />
        </Card>
      )}
    </Box>
  );
};

export default VideoLanding;
