import { GoogleLogin as GLogin, GoogleOAuthProvider, googleLogout } from '@react-oauth/google';

import { Button } from '@mui/material';

export default function GoogleLogin ({ onSuccess = () => {}, onError = () => {}, sx = {} }) {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}>
      <GLogin
      onSuccess={onSuccess}
      onError={onError} />
    </GoogleOAuthProvider>
  );
}
