import { Avatar, Box, IconButton, TextField, Stack, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Markdown from 'marked-react';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import TrySubscribe from "../pages/Try_Subscribe";
import myUserStore from 'stores/UserStore';


const ChatEditMessage = ({ message = "", onCancell = () => { }, onSubmit = () => { } }) => {
  const [internalMessage, setInternalMessage] = useState(message);
  const [dialogOpen, setDialogOpen] = useState(false);
  const selectedTeam = myUserStore.getSelectedTeam;
  console.log(selectedTeam?.name, "try team")
  useEffect(() => {
    if (!selectedTeam?.name) {
      setDialogOpen(true);
      
    }
  }, []);
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        mb: 2 // Optional: Adds space between messages
      }}
    >
      <TrySubscribe open={dialogOpen} onClose={() => setDialogOpen(false)} />
      <Grid item xs={12}>
        <Box
          sx={{
            p: 2, // Padding only for user messages
            backgroundColor: 'whitesmoke', // Transparent for system messages
            borderRadius: '15px', // Rounded corners only for user messages
            whiteSpace: "pre-line",
            color: 'black',
            mb: 1,
            overflow: 'auto',
            display: 'inline-block',
            textAlign: "justify",
          }}
        >
          <TextField value={internalMessage} sx={{ mb: 2 }} multiline onChange={(e) => setInternalMessage(e.target.value)}>
          </TextField>
          <Stack direction="row" spacing={2}>
            <Button
              onClick={onCancell}
              sx={{
                border: '1px solid gray',
                color: 'gray',
                borderRadius: '15px',
                textTransform: 'none',
                padding: '6px 16px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                }
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={internalMessage.length === 0}
              onClick={() => onSubmit(internalMessage)}
              sx={{
                backgroundColor: '#0D47A1',
                color: 'white',
                borderRadius: '15px',
                textTransform: 'none',
                padding: '6px 16px',
                '&:hover': {
                  backgroundColor: 'darkblue',
                }
              }}
            >
              Send
            </Button>

          </Stack>

        </Box>
      </Grid>
    </Grid>
  );
};

export default ChatEditMessage;
