import React from 'react';

const TermsOfService = () => {
  const styles = {
    container: {
      width: '100vw',
      minHeight: '100vh',
      margin: '0',
      padding: '0',
      fontFamily: 'Arial, sans-serif',
      color: '#FFFFFF',
      backgroundColor: '#000000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      maxWidth: '800px',
      padding: '40px 20px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#000000',
    },
    heading1: {
      fontSize: '2.5em',
      marginBottom: '0.5em',
      color: '#00ADEF',
      textAlign: 'center',
    },
    heading2: {
      fontSize: '1.8em',
      marginTop: '1.5em',
      marginBottom: '0.5em',
    },
    paragraph: {
      lineHeight: '1.8',
      marginBottom: '1.2em',
      color: '#DDDDDD',
    },
    date: {
      color: '#AAAAAA',
      textAlign: 'center',
    },
    highlightedText: {
      color: '#00ADEF',
    },
    globalStyle: {
      margin: '0',
      padding: '0',
      backgroundColor: '#000000',
      color: '#FFFFFF',
      height: '100%',
      width: '100%',
      overflowX: 'hidden',
    },
  };

  return (
    <>
      <style>{`
        body, html {
          margin: 0;
          padding: 0;
          background-color: #000000;
          color: #FFFFFF;
          height: 100%;
          width: 100%;
          overflow-x: hidden;
        }
      `}</style>
      <div style={styles.container}>
        <div style={styles.content}>
          <h1 style={styles.heading1}>Terms of Service</h1>
          <p style={styles.date}>Effective Date: July 10, 2024</p>

          <h2 style={styles.heading2}>Introduction</h2>
          <p style={styles.paragraph}>
            Welcome to AI Tech Company. These terms of service ("Terms") apply to your access and use of our services. By accessing or using our services, you agree to be bound by these Terms.
          </p>

          <h2 style={styles.heading2}>Definitions</h2>
          <p style={styles.paragraph}>
            <strong style={styles.highlightedText}>"Services"</strong> refers to the AI technology services provided by AI Tech Company.
          </p>
          <p style={styles.paragraph}>
            <strong style={styles.highlightedText}>"User"</strong> refers to any individual or entity using our services.
          </p>

          <h2 style={styles.heading2}>User Agreement</h2>
          <p style={styles.paragraph}>
            By using our services, you agree to comply with and be legally bound by these Terms.
          </p>

          <h2 style={styles.heading2}>Services Description</h2>
          <p style={styles.paragraph}>
            AI Tech Company provides AI-powered technology solutions. We reserve the right to modify or discontinue any service at any time.
          </p>

          <h2 style={styles.heading2}>User Obligations</h2>
          <p style={styles.paragraph}>
            Users must provide accurate information and keep their account credentials secure. Prohibited activities include misuse of the service and violation of any laws.
          </p>

          <h2 style={styles.heading2}>Content and Intellectual Property</h2>
          <p style={styles.paragraph}>
            Users retain ownership of content they create. By submitting content, you grant us a license to use, modify, and distribute it. We own the content we create and our trademarks.
          </p>

          <h2 style={styles.heading2}>Privacy and Data Protection</h2>
          <p style={styles.paragraph}>
            We collect and use data as described in our Privacy Policy. Users have rights regarding their data, including access and deletion.
          </p>

          <h2 style={styles.heading2}>Disclaimers and Limitation of Liability</h2>
          <p style={styles.paragraph}>
            We provide our services "as is" without warranties. Our liability is limited to the maximum extent permitted by law.
          </p>
          <p style={styles.paragraph}>
            <strong style={styles.highlightedText}>Health and Safety Disclaimer:</strong> Our AI model may provide suggestions related to health and wellness. These suggestions are for informational purposes only and are not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read or been advised by our services.
          </p>
          <p style={styles.paragraph}>
            <strong style={styles.highlightedText}>Limitation of Liability for Health-Related Issues:</strong> AI Tech Company shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if AI Tech Company has been advised of the possibility of such damages), resulting from any health-related issues that may arise from following any suggestions provided by our AI model.
          </p>

          <h2 style={styles.heading2}>Termination</h2>
          <p style={styles.paragraph}>
            Either party may terminate this agreement at any time. Upon termination, your right to use the services will cease.
          </p>

          <h2 style={styles.heading2}>Dispute Resolution</h2>
          <p style={styles.paragraph}>
            These Terms are governed by the laws of [Italy]. Any disputes will be resolved through arbitration.
          </p>

          <h2 style={styles.heading2}>Miscellaneous</h2>
          <p style={styles.paragraph}>
            If any part of these Terms is found to be invalid, the remaining provisions will remain in effect. These Terms constitute the entire agreement between you and AI Tech Company.
          </p>

          <h2 style={styles.heading2}>Contact Us</h2>
          <p style={styles.paragraph}>
            If you have any questions about these Terms, please contact us at support@aimotor.eu
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;


