import { Button, Container, Table } from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";
import React, { useEffect, useState } from "react";

import axios from "axios";

const Control = () => {
    const [controlSessions, setControlSessions] = useState([]);
    const [hiddenDrivers, setHiddenDrivers] = useState([]);
    const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/control_sessions/?date=2024-02-08&team_id=${selectedTeam.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                            accept: "application/json",
                        },
                    }
                );
                console.log("control response", res.data);
                setControlSessions(res.data);
                const storedHiddenDrivers = localStorage.getItem("hiddenDrivers");
                if (storedHiddenDrivers) {
                    setHiddenDrivers(JSON.parse(storedHiddenDrivers));
                }
            } catch (error) {
                console.error("Axios Error:", error);
            }
       
        };

        fetchData();
    }, []);


    const toggleDriverVisibility = (index) => {
        setHiddenDrivers((prevHiddenDrivers) => {
            const newHiddenDrivers = prevHiddenDrivers.includes(index)
                ? prevHiddenDrivers.filter((item) => item !== index)
                : [...prevHiddenDrivers, index];
            localStorage.setItem("hiddenDrivers", JSON.stringify(newHiddenDrivers));
            return newHiddenDrivers;
        });
    };

    return (
        <Container>
            <Table responsive striped bordered hover className="sticky-thc">
                <thead>
                    <tr>
                        <th>Driver</th>

                        <th>Session 1</th>
                        <th>Session 2</th>
                        <th>Session 3</th>
                        <th>Session 4</th>
                        <th>Session 5</th>
                        <th>Session 6</th>
                        
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {controlSessions.map((session, index) => (
                        <tr key={index}>
                            <td>{session.full_name}</td>
                     
                            {[1, 2, 3, 4, 5, 6].map((session_number) => (
                                <td key={session_number}>
                                    {hiddenDrivers.includes(index) ? "" : session.sessions.includes(session_number) ? (
                                        <FaCheck style={{ color: "green" }} />
                                    ) : (
                                        <FaTimes style={{ color: "red" }} />
                                    )}
                                </td>
                            ))}
                            <td>
                                <Button
                                    variant={hiddenDrivers.includes(index) ? "outline-success" : "outline-danger"}
                                    onClick={() => toggleDriverVisibility(index)}
                                >
                                    {hiddenDrivers.includes(index) ? "Show" : "Hide"}
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default Control;






