import React from 'react';
import { Container, Row, Col, Figure } from 'react-bootstrap';
import aifeatures from '../img/aifeatures.svg';
import Chatbot from '../img/Chatbot.svg';
import kartrepublic from '../img/kartrepublic.svg';
import DPK from '../img/DPK.png'
import AREGENTI from '../img/ARGENTI.png'
import DRAKE from '../img/DRAKE.png'
import SP from '../img/SP.png'
import kidix from '../img/kidix.png'
import kartrepublicWhite from '../img/kart-republic-logo-white.png';

const MainPage = () => {
  return (
    <Container
      style={{
        backgroundColor: 'black',
        minHeight: 'calc(100% - 86px)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0
      }} fluid >
      <Row className="justify-content-center align-items-center" style={{ height: '50vh' }}>
        {/* Immagine centrale */}
        <Col xs={12} md={6} className="text-center ellipse-image mb-3">
          <Figure>
            <Figure.Image
              className="ellipse-image"
              alt="171x180"
              src={kartrepublicWhite}
              style={{ width: '100%', height: '100%' }}
            />
          
          </Figure>
        </Col>
      </Row>
    </Container>


  );
}

export default MainPage
