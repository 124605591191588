export const transformData = (data, user_id, email, surname) => {
  const { teams = [], owner = [], teams_info = [] } = data || {};

  const transformedTeamsInfo = teams_info.map((team) => ({
    id: team.team_id,
    name: team.team_name,
    role: team.role,
    isOwner: owner.some(
      (t) => t.team_id === team.team_id && t.team_owner === user_id
    ),
    members: team.members,
  }));

  return {
    email: email,
    surname: surname,
    teams: teams.map((team) => team.team_name),
    owners: owner,
    teamInfo: transformedTeamsInfo,
  };
};