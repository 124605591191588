import { useHistory } from 'react-router-dom'; // Import useHistory from react-router-dom
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton'; // Import IconButton from MUI
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon from MUI
import Typography from '@mui/material/Typography'; // Import Typography from MUI

const SubscribeDialog = ({ open, onClose }) => {
  const history = useHistory(); // Initialize useHistory hook

  const handleSubscribeClick = () => {
    history.push('/sign-up'); // Redirect to the subscribe route
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '90%', // Adjust width to be responsive
          maxWidth: '500px', // Set a maximum width
          maxHeight: '80vh', // Set a maximum height based on viewport
          padding: '20px', // Add padding for a cleaner look
          borderRadius: '10px', // Rounded corners
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
        },
      }}
    >
      <DialogTitle>
        <Typography 
          variant="h5" 
          component="span" 
          sx={{
            fontWeight: 'bold', // Bold text for emphasis
            color: '#3f51b5', // Custom color
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)', // Subtle shadow for a nicer effect
            display: 'inline-block', // Ensures the element does not expand
          }}
        >
          Subscribe Now
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        To use the <strong>complete version</strong> of AiMotor, please <strong>subscribe</strong>.<br /><br />
        You will have access to all the functionalities such as:<br />
        - <strong>Specific</strong> and <strong>personalized AI analysis</strong><br />
        - Insertion of <strong>personal karting data</strong><br />
        - And more...
      </DialogContent>
      <DialogActions>
        <Chip
          label="Subscribe"
          color="secondary"
          onClick={handleSubscribeClick}
          clickable
          sx={{
            padding: '10px 20px', // Increase padding for better touch target
            fontWeight: 'bold', // Make the text bold for emphasis
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SubscribeDialog;
