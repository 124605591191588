import React, { useState, useEffect } from 'react';
import { Collapse, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

const SuggestionsList = ({ list = [], onClick = () => { } }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (list?.length > 0) {
      setIsOpen(true);
    } else { setIsOpen(false); };
  }, [list]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      {list.length ? (
        <Collapse
          in={isOpen}
          out={!isOpen}
          sx={{
            width: '100%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            margin: '0 auto',
          }}
          timeout="auto"
          unmountOnExit
        >
          <List
            sx={{
              width: '100%',
              maxWidth: 800,
              bgcolor: 'background.paper',
              margin: '0 auto',
              maxHeight: 470, // Limita l'altezza massima
              overflowY: 'auto', // Rende il contenuto scorribile
            }}
          >
            {list.map((suggestion, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemButton onClick={() => onClick(suggestion.message)}>
                    <ListItemText
                      primary={
                        <>
                          <span style={{ color: 'gray' }}>
                            {suggestion.title.split(' ').slice(0, 2).join(' ')}
                          </span>{' '}
                          {suggestion.title.split(' ').slice(2).join(' ')}
                        </>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        </Collapse>
      ) : null}
    </div>
  );
};

export default SuggestionsList;
