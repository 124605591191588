import React, { useState, useEffect } from 'react';
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import data1 from '../TryFiles/telemetryTable.json';

const LapTable = ({ drivers, race, day, fullName, laps, setLaps, selectedDriverEmail, sessionNumbersByDriver, driverSessions }) => {
  //const [rows, setRows] = useState([]);
  const [dataset, setDataset] = useState(data1?.find((session) => session?.email === selectedDriverEmail) || {});
  console.log('try dataset', dataset)
  const [compare, setCompare] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const sessionNumber = sessionNumbersByDriver[selectedDriverEmail] 
  ? sessionNumbersByDriver[selectedDriverEmail][sessionNumbersByDriver[selectedDriverEmail].length - 1] 
  : 1;
  const rows = dataset && Array.isArray(dataset.laps) ? dataset.laps.map((lapData) => ({
    Lap: lapData.lap,
    Email: dataset.email,
    'Lap Time': lapData.laptime,
    'Max RPM': lapData.MaxRpm,
    'Max Speed': lapData.MaxSpeed,
    'Max TK': lapData.MaxTK,
  })) : [];



 

  const handleCompareChange = (lap) => {

    setCompare((prev) => ({
      ...prev,
      [lap]: !prev[lap]
    }));

    setLaps((prevLaps) => {
      const existingEntry = prevLaps?.find(entry => entry.email === selectedDriverEmail);
      if (existingEntry) {
        const updatedLaps = existingEntry.laps.includes(lap)
          ? existingEntry.laps.filter(l => l !== lap)
          : [...existingEntry.laps, lap];

        return prevLaps.map(entry =>
          entry.email === selectedDriverEmail
            ? { ...entry, laps: updatedLaps }
            : entry
        );
      } else {
        return [...prevLaps, { email: selectedDriverEmail, laps: [lap] }];
      }
    });
  };

  return (
    isLoading ? (
      <Skeleton sx={{height:'70vh', marginTop: -18, marginBottom:-15}}/>
    ) : (
      <Box>
        <TableContainer component={Paper}  style={{
    width: '100%',
    maxHeight: 'calc(100vh - 200px)', // Adjust this value as needed for your layout
    overflowY: 'auto' // Enables scrolling if content overflows
  }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lap</TableCell>
                <TableCell>Lap Time</TableCell>
                <TableCell>Max RPM</TableCell>
                <TableCell>Max Speed</TableCell>
                <TableCell>Max TK</TableCell>
                <TableCell>Compare</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.Lap}</TableCell>
                  <TableCell>{row['Lap Time']}</TableCell>
                  <TableCell>{row['Max RPM']}</TableCell>
                  <TableCell>{row['Max Speed']}</TableCell>
                  <TableCell>{row['Max TK']}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={laps.some(entry => entry.email === selectedDriverEmail && entry.laps.includes(row.Lap))}
                      onChange={() => handleCompareChange(row.Lap)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  );
};

export default LapTable;
