// FeedbackPopup.js
import React, { useState } from 'react';
import './components.css';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
// const FeedbackPopup = ({ onClose, onSubmit }) => {
//   const [feedback, setFeedback] = useState('');

//   const handleSubmit = () => {
//     onSubmit(feedback);
//     onClose();
//   };

//   return (
//     <div className="popup">
//       <div className="popup-content">
//         <span className="close" onClick={onClose}>&times;</span>
//         <h2>Feedback</h2>
//         <p>We'd love to hear your thoughts! Please provide your feedback below:</p>
//         <textarea
//           value={feedback}
//           onChange={(e) => setFeedback(e.target.value)}
//           placeholder="Type your feedback here..."
//         ></textarea>
//         <button onClick={handleSubmit}>Submit</button>
//       </div>
//     </div>
//   );
// };

// export default FeedbackPopup;


function FeedbackPopup() {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={6} md={4} >
          <Form.Label htmlFor="inputPassword5">Tell us what you are interested in, and leave us your mail if you wish to be contacted</Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
          />
        </Col>
        <Row>
          <Col xs={12}>
            <div className="mb-3">
              Go back to <Link to="/">Home</Link>
            </div>
          </Col>
        </Row>
      </Row>
      <Row className="mb-3">
        <Col xs={12}>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Container>

  )
}

export default FeedbackPopup;