import { Box, Divider, Stack, Typography } from "@mui/material";

import dayjs from 'dayjs';

const subscriptionPaymentLegend = [
  {
    title: 'Last payment',
    value: 'last_payment',
    format: (value) => `${value?.amount?.value || 0} ${value?.amount?.currency_code || ''}`,
    sx: {
      fontWeight: 'bold'
    }
  },
  {
    title: 'Next payment date',
    value: 'next_billing_time',
    format: (value) => value ? dayjs(value).format('DD MMMM YYYY') : 'N/A'
  },
  {
    title: 'Final payment date',
    value: 'final_payment_time',
    format: (value) => value ? dayjs(value).format('DD MMMM YYYY') : 'N/A'
  },
]

const subscriptionLegend = [
  {
    title: 'Plan',
    value: 'custom_id',
    sx: {
      fontWeight: 'bold'
    }
  },
  {
    title: 'User name',
    value: 'subscriber',
    format: (value) => value?.name ? `${value.name?.given_name} ${value?.name?.surname}` : ''
  },
  {
    title: 'User email',
    value: 'subscriber',
    format: (value) => value?.email_address || ''
  },
  {
    title: 'Subscription ID',
    value: 'id',
  },
  {
    title: 'Plan ID',
    value: 'plan_id',
  },
  
  {
    title: 'Created at',
    value: 'create_time',
    format: (value) => dayjs(value).format('DD MMMM YYYY hh:mm')
  },
  {
    title: 'Status',
    value: 'status',
    sx: {
      fontWeight: 'bold'
    }
  },
  {
    title: 'Note',
    value: 'status_change_note',
    sx: {
      fontStyle: 'italic'
    }
  },
  {
    title: 'Start at',
    value: 'start_time',
    format: (value) => dayjs(value).format('DD MMMM YYYY hh:mm')
  },
]

const UserPlanRecap = ({ subscriptionDetail = {}, isCanceled = false, ...props}) => {
  return (
    <Box {...props}>
      <Typography variant="h4">Your plan recap</Typography>
      <Stack spacing={2} mt={4} >
        {
          subscriptionLegend.map((line, index) => (
            <Stack direction={"row"} key={index} justifyContent={'space-between'} alignItems={'center'} sx={{ opacity: (isCanceled && line.value !== 'status') ? 0.6 : 1 }}>
              <Typography variant="subtitle2">{line.title}</Typography>
              <Typography variant="subtitle2" sx={{ ...(line?.sx || {}) }}>
                {line?.format ? line.format(subscriptionDetail[line.value]) : subscriptionDetail[line.value] || 'N/A'}
              </Typography>
            </Stack>
          ))
        }
        <Divider />
        <Typography variant="h6">Billing</Typography>
        {
          subscriptionPaymentLegend.map((line, index) => (
            <Stack direction={"row"} key={index} justifyContent={'space-between'} alignItems={'center'} sx={{ opacity: isCanceled ? 0.6 : 1 }}>
              <Typography variant="subtitle2">{line.title}</Typography>
              <Typography variant="subtitle2" sx={{ ...(line?.sx || {}) }}>
                {line?.format ? line.format(subscriptionDetail?.billing_info?.[line.value]) : subscriptionDetail?.billing_info?.[line.value] || 'N/A'}
              </Typography>
            </Stack>
          ))
        }
      </Stack>
    </Box>
  );
};

export default UserPlanRecap;
