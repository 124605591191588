import React, { useState, useEffect } from "react";

const EditableCell = ({ value, onSave, field, id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onSave(editValue, field, id);
  };

  const handleChange = (e) => {
    setEditValue(e.target.value);
  };

  return (
    <td
      onDoubleClick={handleDoubleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        cursor: "pointer",
        backgroundColor: isHovered ? "#f0f0f0" : "transparent"
      }}
    >
      {isEditing ? (
        <input
          type="text"
          value={editValue}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <span>{editValue}</span>
      )}
    </td>
  );
};

export default EditableCell;
