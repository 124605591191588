import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/recover_password/?email=${email}`)
      .then((res) => {
        toast.success("Email sent successfully!", {
          position: "top-right",
          autoClose: 2000, // Close after 2 seconds
          hideProgressBar: false,
        });
      })
      .catch((error) => {
        toast.error("Error recovering password!", {
          position: "top-right",
          autoClose: 2000, // Close after 2 seconds
          hideProgressBar: false,
        });
        console.error("Axios Error:", error);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 'calc(100% - 86px)',
        py: 4,
        backgroundColor: "black",
        color: "white",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{
        p: 4,
        backgroundColor: "#1c1c1e",
        borderRadius: 2,
        boxShadow: 3,
        textAlign: "center",
        maxWidth: 400,
        width: "100%",
        margin: "0 auto"
      }}>
        <Typography variant="h5" component="h1" gutterBottom align="center">
          Enter your email to reset password
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            variant="filled"
            fullWidth
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            margin="normal"
            required
            InputLabelProps={{
              style: { color: 'white' }
            }}
            InputProps={{
              style: { color: 'white', backgroundColor: '#424242' }
            }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          >
            RESET PASSWORD
          </Button>
        </Box>
        <Link to={'/sign-in'}>
          Cancel
        </Link>
      </Box>
      </Container>
    </Box>
  );
}

export default ForgotPassword;




