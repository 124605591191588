import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { observer } from "mobx-react-lite"
import GraphTelemetry from './Try_GraphTelemetry.js';
import TelemetryArchive from './Try_TelemetryArchive.js';


const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) { // Pass 'name' and 'personName' as arguments
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PieChartWithSelect = ({ driver, race, day, driverSessions, hoveredData, setHoveredData, trackIndex,laps, setTrackIndex, collapsed }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState(0);
  console.log ("drivers parent", driver)
  const [anchorElTeamAnalysis, setAnchorElTeamAnalysis] = useState(null);
  const [selectedOptionTeamAnalysis, setSelectedOptionTeamAnalysis] = useState('');
  const [competitions, setCompetitions] = useState([]);
  const [racingData, setRacingData] = useState([]);

  const [showFront, setShowFront] = useState(false);

  const [showPressure, setShowPressure] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [emailName, setEmailName] = useState([]);

  const [showEngine, setShowEngine] = useState(true); // Use showEngine for engine graphs
  const [selectedDate, setSelectedDate] = useState(null);
  const [competitionLoading, setCompetitionLoading] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0)
    setShowFront(newValue === 1)
    setShowPressure(newValue === 2)
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleMenuClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-calendar-popover' : undefined;











  //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
  const selectedTeam = 226;
  const showPersonalButton = false;
 
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  return (

    <div>





                 
      <Box sx={{ width: '100%', marginTop: collapsed ? 10 : 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="fullWidth" value={value} onChange={handleChangeTab} >
            <Tab label="DELTA TIME GRAPH" {...a11yProps(0)} />
            <Tab label="SETUP" {...a11yProps(1)} />

          </Tabs>
          <div className='graphs2'>
          <>
            {showEngine && <GraphTelemetry race={race} day={day} laps={laps || []} driver={driver} fullName={emailName} showTable={!!race && !!day} driverSessions={driverSessions} hoveredData={hoveredData} setHoveredData={setHoveredData}   setTrackIndex={setTrackIndex}/>}
            {showFront && <TelemetryArchive race1={race} day1={day} selectedDrivers={driver}/>}

          </>

        </div>
        </Box>
   

      </Box>








    </div>
  );
};

export default observer(PieChartWithSelect);