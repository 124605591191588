import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"

export default function StepperContentLoader ({ times = 14, direction = "row", spacing = "24px", ...props }) {

  return (
    <Stack direction={direction} flexWrap={"wrap"} justifyContent="center" {...props}>
      {
        Array.from(Array(times).keys()).map((t, index) => (
          <Skeleton sx={{ width: 80, height: 30, borderRadius: 8, mb: 1.5, mr: 2 }} key={index} animation="wave" variant="rectangular" />
        ))
      }
    </Stack>
  )
}