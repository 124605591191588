import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Stack,
  Chip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { toast } from "react-toastify";
import CheckIcon from '@mui/icons-material/Check';
import "react-toastify/dist/ReactToastify.css";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CardLoader from "components/Loaders/CardLoader";
import HttpProvider from "services/HttpProvider";
import remove from "lodash/remove";
import myUserStore from "../../stores/UserStore";
import { observer } from "mobx-react-lite";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from "@mui/material";

/**
 * Simple progress bar with percentage label
 */
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: 50, mr: 1 }} hidden={props.status !== "processing"}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function FilesRecap({
  refreshList = false,
  useLinkToPage = false,
  length = 5,
  onComplete = () => { },
  sx = {},
  ...props
}) {

  const [isLoading, setIsLoading] = useState(false);
  const [processing, setProcessing] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null); // For Telemetry
  const showPersonalButton = myUserStore.isPersonalSpace;
  const selectedTeam = myUserStore.getSelectedTeam;
  const [editFileId, setEditFileId] = useState(null);
  const [status, setStatus] = useState(null);
  const [editNameValue, setEditNameValue] = useState("");

  // Track competitions to show raceName, etc.
  const [competitions, setCompetitions] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);

  // Onboard (video) variables
  const [videos, setVideos] = useState([]); // Onboard videos
  const [activeTab, setActiveTab] = useState("TELEMETRY");
  const [videoId, setVideoId] = useState(null); // For viewing a single video in modal
  const [videoLoading, setVideoLoading] = useState(false);

  // Toggles single-video edit/delete icons on each video
  const [manageMode, setManageMode] = useState(false);
  // For rename
  const [videoBeingEdited, setVideoBeingEdited] = useState(null);
  const [newVideoName, setNewVideoName] = useState("");

  // Search
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // const [filteredOnboardFiles, setFilteredOnboardFiles] = useState([]); 
  const [onboardFiles, setOnboardFiles] = useState([]); // List of onboard files

  /**
   * Filter TELEMETRY files by search query
   */
  const filteredFiles = useMemo(() => {
    return files.filter((file) =>
      file.Name?.toLowerCase().startsWith(searchQuery)
    );
  }, [files, searchQuery]);

  const filteredVideos = useMemo(() => {
    return videos.filter((vid) =>
      vid.filename?.toLowerCase().includes(searchQuery)
    );
  }, [videos, searchQuery]);

  /**
   * Load Telemetry files
   */
  const loadFiles = useCallback(() => {
    setIsLoading(true);

    const endpoint = showPersonalButton
      ? `/files_uploaded`
      : `/files_uploaded_for_team`;

    const params = !showPersonalButton
      ? { team_id: selectedTeam.id }
      : {};

    return HttpProvider.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
      params,
    })
      .then(async (res) => {
        const { data = [] } = res;
        const processingArray = remove(data, (d) => d.status === "processing");
        setProcessing(processingArray);
        setFiles(data);
        return data;
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [showPersonalButton, selectedTeam]);

  /**
   * Fetch onboard videos
   */
  useEffect(() => {
    const fetchOnboardVideos = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/list_videos?team_id=${selectedTeam.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        setVideos(res.data || []);
      } catch (error) {
        console.error("Error fetching onboard videos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOnboardVideos();
  }, [selectedTeam, activeTab]);

  /**
   * Opens video in a modal
   */
  const handleVideoClick = (video) => {
    setVideoLoading(true);
    setVideoId(video.video_id);
    (async () => {
      const videoStatus = await getVideoStatus(361126, video.video_id);
      debugger
      setStatus(videoStatus); // qui memorizziamo lo status restituito
    })();

  };
  const getVideoStatus = async (libraryId, videoId) => {
    try {
      const url = `https://video.bunnycdn.com/library/${libraryId}/videos/${videoId}/play`;

      const response = await axios.get(
        url,
        // Corpo della richiesta con il titolo del video
        {
          headers: {
            accept: 'application/json', // Specifica il formato della risposta
            'content-type': 'application/json', // Specifica il tipo di contenuto
            AccessKey: '83d006c0-f495-4a2f-b2937024639c-b620-4a18', // Sostituisci con la tua Access Key
          },
        }
      );

      console.log('Video creato con successo:', response.data?.video?.status);
      
      return response.data?.video?.status;
      // Restituisce i dettagli del video creato
    } catch (error) {
      console.error('Errore durante la creazione del video:', error.response?.data || error.message);
      throw error;
    }
  };


  /**
   * Onboard video embed URL
   */
  const embedUrl = `https://iframe.mediadelivery.net/embed/361126/${videoId}?autoplay=true&loop=false&muted=false&preload=true&responsive=true`;

  /**
   * Single-video delete from the Manage UI
   */
  const handleOnboardDeleteVideo = async (documentId) => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_API_DOMAIN}/delete_video?file_id=${documentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      // Remove from local state
      setVideos((prev) => prev.filter((vid) => vid.document_id !== documentId));
      // toast.success("Video deleted successfully");
    } catch (error) {
      console.error("Error deleting video:", error);
      toast.error("Failed to delete video");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Start rename mode for a single video
   */
  const handleOnboardEdit = (video) => {
    setVideoBeingEdited(video.document_id);
    setNewVideoName(video.filename || "");
  };

  /**
   * Confirm rename for the single video
   */
  const handleRenameVideo = async () => {
    if (!videoBeingEdited) return;
    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_API_DOMAIN}/update_video_name?file_id=${videoBeingEdited}&new_filename=${newVideoName}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );

      // Update local array with new filename
      setVideos((prev) =>
        prev.map((v) =>
          v.document_id === videoBeingEdited
            ? { ...v, filename: newVideoName }
            : v
        )
      );
      // toast.success("Filename updated successfully");
    } catch (error) {
      console.error("Error updating video name:", error);
      toast.error("Failed to update video name");
    } finally {
      setVideoBeingEdited(null);
      setNewVideoName("");
      setIsLoading(false);
    }
  };

  const handleCloseVideo = () => {
    setVideoLoading(false);
    setVideoId(null);
  };

  /**
   * Render ON BOARD videos
   */
  const renderOnboardList = () => {
    if (videos.length === 0) {
      return <Typography variant="body1">No onboard videos available.</Typography>;
    }

    return (
      <>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
            gap: 2,
          }}
        >
          {filteredVideos.map((video) => {
            const isBeingEdited = videoBeingEdited === video.document_id;

            return (
              <Box
                key={video.document_id}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  overflow: "hidden",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => {
                  // Only open video if we're NOT editing or in manage mode
                  if (!manageMode && !isBeingEdited) {
                    handleVideoClick(video);
                  }
                }}
              >
                <OndemandVideoIcon
                  sx={{
                    fontSize: { xs: "50px", sm: "75px", md: "100px" },
                    color: "#757575",
                    display: "block",
                    margin: "20px auto",
                  }}
                />
                <Box
                  sx={{
                    padding: "8px",
                    backgroundColor: "#f9f9f9",
                    fontSize: "12px",
                    color: "#000000",
                    textAlign: "center",
                  }}
                >
                  {video.filename || "Unknown filename"}
                </Box>

                {/* Manage-mode tools: pencil & bin */}
                {manageMode && !isBeingEdited && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 1,
                      marginTop: "6px",
                    }}
                  >
                    {/* Pencil (Edit) icon */}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnboardEdit(video);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>

                    {/* Bin (Delete) icon */}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnboardDeleteVideo(video.document_id);
                      }}
                    >
                      <DeleteIcon fontSize="small" color="error" />
                    </IconButton>
                  </Box>
                )}

                {/* If we're renaming a video, show inline input */}
                {isBeingEdited && (
                  <Box sx={{ p: 1, backgroundColor: "#fff" }}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={newVideoName}
                      onChange={(e) => setNewVideoName(e.target.value)}
                      onClick={(e) => e.stopPropagation()} // Prevent closing
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                          handleRenameVideo();
                        }
                      }}
                    />
                    <Box
                      sx={{ display: "flex", gap: 1, mt: 1, justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRenameVideo();
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setVideoBeingEdited(null);
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>

            );
          })}
        </Box>

      </>

    );

  };

  /**
   * TELEMETRY — handle clicks
   */
  const handleFileClick = (fileId) => {
    setSelectedFileId((prevSelectedId) =>
      prevSelectedId === fileId ? null : fileId
    );
  };

  /**
   * TELEMETRY — delete
   */
  const handleDeleteClick = (file) => {
    setIsLoading(true); // Show loader during deletion
    return HttpProvider.delete(`/delete_session_by_id`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
      params: {
        upload_id: file._id,
      },
    })
      .then((res) => {
        // Remove from local state
        setFiles((prevFiles) => prevFiles.filter((f) => f._id !== file._id));
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
      })
      .finally(() => {
        // toast.success("File deleted", { autoClose: 3000 });
        setIsLoading(false);
      });
  };

  /**
   * TELEMETRY — rename (start editing)
   */
  const handleEditClick = (file, e) => {
    e.stopPropagation();
    setEditFileId(file._id);
    setEditNameValue(file.Name);
  };

  /**
   * TELEMETRY — rename (finish editing)
   */
  const handleEditSubmit = (file) => {
    setIsLoading(true);
    return HttpProvider.put(`/update_name_by_id`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
      params: {
        upload_id: file._id,
        new_name: editNameValue,
      },
    })
      .then((res) => {
        // Update local name
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f._id === file._id ? { ...f, Name: editNameValue } : f
          )
        );
        setEditFileId(null);
      })
      .catch((error) => {
        console.error("Error updating name:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelEdit = () => {
    setEditFileId(null);
    setEditNameValue("");
  };

  /**
   * Single List Tile for TELEMETRY
   */
  const ListTile = useCallback(
    ({ file }) => {
      const isSelected = selectedFileId === file._id;
      const isEditing = editFileId === file._id;
      const hasError = file.status === "error";


      return (
        <>

          <ListItem
            onClick={() => handleFileClick(file._id)}
            sx={{
              backgroundColor: hasError
                ? "rgba(255, 0, 0, 0.05)"
                : isSelected
                  ? "rgba(0, 0, 255, 0.05)"
                  : "inherit",
              border: hasError
                ? "1px solid red"
                : isSelected
                  ? "1px solid blue"
                  : "none",
              borderRadius: "8px",
              transition: "all 0.3s ease",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: hasError ? "red" : "none" }}>
                <AttachFileIcon />
              </Avatar>
            </ListItemAvatar>

            {isEditing ? (
              <Box onClick={(e) => e.stopPropagation()} sx={{ flexGrow: 1 }}>
                <input
                  type="text"
                  value={editNameValue}
                  onChange={(e) => setEditNameValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEditSubmit(file);
                    }
                  }}
                  style={{
                    width: "100%",
                    padding: "8px",
                    fontSize: "1rem",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  autoFocus
                />
                <Box mt={1} sx={{ display: "flex", gap: "8px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleEditSubmit(file)}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <ListItemText
                primary={file.Name}
                secondary={
                  hasError
                    ? `FAILED TO UPLOAD: ${file.error_details || "Unknown error"}`
                    : `race: ${file.raceName} | laps: ${file.laps}`
                }
                sx={{
                  color: hasError ? "red" : "inherit",
                }}
              />
            )}


            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Help icon for specific error */}
              {hasError &&
                file.error_details === "\"['longitude', 'latitude'] not in index\"" && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setHelpDialogOpen(true);
                    }}
                    sx={{
                      color: "red",
                      visibility: "visible",
                      transition: "visibility 0.2s ease",
                      mr: 1,
                    }}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                )}

              {/* Edit icon (visible only if not error) */}
              {!hasError && (
                <IconButton
                  onClick={(e) => handleEditClick(file, e)}
                  sx={{
                    color: "gray",
                    visibility: isSelected ? "visible" : "hidden",
                    transition: "visibility 0.2s ease",
                    mr: 1,
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}

              {/* Delete icon */}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(file);
                }}
                sx={{
                  color: "red",
                  visibility: isSelected || hasError ? "visible" : "hidden",
                  transition: "visibility 0.2s ease",
                }}
              >
                <DeleteIcon />
              </IconButton>

              {/* Progress bar */}
              {!hasError && (
                <LinearProgressWithLabel value={file.percentage} status={file.status} />
              )}
            </Box>
          </ListItem>

          <Divider />
        </>
      );
    },
    [selectedFileId, editFileId, editNameValue]
  );

  /**
   * Fetch both Telemetry files & competitions on mount
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1. Load TELEMETRY files
        const loadedFiles = await loadFiles();

        // 2. Fetch competitions
        const response = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        const competitionsData = response.data;
        setCompetitions(competitionsData);

        // 3. Merge raceName into files
        const updatedFiles = loadedFiles.map((file) => {
          return {
            ...file,
            raceName:
              competitionsData.find((comp) => comp.id === file.race)
                ?.competition || "N/A",
          };
        });

        setFiles(updatedFiles);
        setIsDataReady(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [selectedTeam]);

  /**
   * Switch tab
   */
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSearchQuery(""); // Reset the search bar
  };

  const isEmptyList = useMemo(() => {
    return files.length === 0 && processing.length === 0;
  }, [files, processing]);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  return (
    <Box {...props}>
      <Dialog open={helpDialogOpen} onClose={() => setHelpDialogOpen(false)}>
        <DialogTitle>File Upload Error</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            When you export a CSV file from Starlane, make sure the track map is open.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHelpDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>
        <Typography variant="h4" component="div">
          Your Files
        </Typography>
        {useLinkToPage ? (
          <Button variant="contained" color="primary">
            Go to the list
          </Button>
        ) : null}
      </Stack>

      <Box mt={2} sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Chip
          label="TELEMETRY"
          variant={activeTab === "TELEMETRY" ? "filled" : "outlined"}
          color="primary"
          onClick={() => handleTabChange("TELEMETRY")}
        />
        <Chip
          label="ON BOARD"
          variant={activeTab === "ON BOARD" ? "filled" : "outlined"}
          color="primary"
          onClick={() => handleTabChange("ON BOARD")}
        />
      </Box>

      <Box mt={3}>
        {/* SEARCH BAR */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Search Files"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Type to search files..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { backgroundColor: "#ffffff", borderRadius: "8px" },
            }}
          />
        </Box>

        {isLoading ? (
          <CardLoader height={70} times={10} spacing={0.5} />
        ) : activeTab === "TELEMETRY" ? (
          files.length > 0 ? (
            <Box mt={3}>
              {filteredFiles.length > 0 ? (
                <List>
                  {filteredFiles.map((file) => (
                    <Fragment key={file._id}>
                      <ListTile file={file} />
                    </Fragment>
                  ))}
                </List>
              ) : (
                <Typography variant="body1">No files match your search.</Typography>
              )}
            </Box>
          ) : (
            <Typography variant="body1">No file in the list</Typography>
          )
        ) : (
          renderOnboardList()
        )}
      </Box>

      {/* Manage button only for ON BOARD tab */}
      {activeTab === "ON BOARD" && filteredVideos.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 5, gap: 2 }}>
          <Button
            variant={manageMode ? "outlined" : "contained"}
            color="primary"
            onClick={() => setManageMode((prev) => !prev)}
          >
            {manageMode ? "Close Manage" : "Manage"}
          </Button>
        </Box>
      )}

      {/* ON BOARD Video Modal */}
      <Dialog
        open={videoLoading}
        onClose={handleCloseVideo}
        PaperProps={{
          sx: {
            backgroundColor: "#000000",
            width: "100%",
            height: {
              xs: "46vw",
              sm: "46vw",
              lg: "46%",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "16px",
          },
        }}
      >
        {status !== 2 && status !==1  ? (
          <iframe
            src={embedUrl}
            loading="lazy"
            style={{
              border: 0,
              width: "100%",
              height: "100%",
              maxWidth: "90vw",
              maxHeight: "70vh",
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
            allowFullScreen
            title="Video Player"
          ></iframe>
        ) : (
          <Box
            sx={{
              color: "#ffffff",
              fontSize: "1.2rem",
              textAlign: "center",
              padding: "20px",
            }}
          >
            Video processing...
          </Box>
        )}
      </Dialog>

    </Box>
  );
}

export default observer(FilesRecap);
