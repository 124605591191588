import { Avatar, Box, IconButton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Markdown from 'marked-react';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import ChatEditMessage from './EditMessage';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ChatMessage = ({ owner = true, message = "", updated_at, onMessageEdit = () => { } }) => {
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [showEditIcon, setShowEditIcon] = useState(false);


  const handleOnCancell = () => {
    setIsEditing(false)


  };

  const handleOnSubmit = (newMessage) => {
    setIsEditing(false)
    onMessageEdit(newMessage, updated_at)

  };

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        textAlign: owner ? "end" : "start", // Conditionally applying text alignment
        fontSize: {
          xs: '16px',  // for mobile devices
          sm: '17px',  // for tablets and above
        },
        mb: 2, // Optional: Adds space between messages
      }}
    >
      <Grid item xs={12}>

        <Typography
          variant="caption"
          sx={{
            textAlign: owner ? 'right' : 'left',
            marginBottom: '5px',
            color: 'black', // Consistent text color for both
          }}
        >
          {owner ? 'You' : ''}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isEditing ? (
          <ChatEditMessage
            message={message}
            onCancell={handleOnCancell}
            onSubmit={handleOnSubmit}
            
          />
        ) : (
          <Box
            onClick={isMobile ? () => setShowEditIcon(!showEditIcon) : undefined}
            sx={{
              position: 'relative',
              '&:hover .edit-icon': {
                opacity: 1,
              },
            }}
          >
            <Stack
              direction="row"
              display="inline-flex"
              alignItems="flex-start"
              justifyContent={owner ? 'flex-end' : 'flex-start'}
              spacing={1}
              maxWidth="800px"
              width={'100%'}
            >
              <IconButton
                hidden={!owner}
                onClick={() => setIsEditing(true)}
                className="edit-icon"
                sx={{
                  opacity: isMobile ? (showEditIcon ? 1 : 0) : 0,
                  transition: 'opacity 0.3s',

                  top: 0,
                  right: 0,
                }}
              >
                <Avatar sx={{ width: 34, height: 34 }}>
                  <EditIcon sx={{ fontSize: 18 }} />
                </Avatar>
              </IconButton>
              <Box
                sx={{
                  p: owner ? 2 : 0, // Padding only for user messages
                  backgroundColor: owner ? 'whitesmoke' : 'transparent', // Transparent for system messages
                  borderRadius: owner ? '15px' : '0px', // Rounded corners only for user messages
                  whiteSpace: 'pre-line',
                  color: 'black', // Consistent text color
                  mb: 1,
                  overflow: 'auto',
                  display: 'inline-block',
                  textAlign: 'justify',
                  maxWidth: {
                    xs: '100%',
                    md: '100%',
                  },
                  '& p:last-child': {
                    marginBottom: 0,
                  },
                  '& table': {
                    overflow: 'auto',
                    width: '100%',
                    display: 'block',
                  },
                }}
              >
                <Markdown>
                  {message.replace(/<table>/g, '<div class="table-container-message"><table>').replace(/<\/table>/g, '</table></div>')}
                </Markdown>

                {/* <Markdown>
                  {message}
                </Markdown> */}


              </Box>
            </Stack>
          </Box>
        )}
      </Grid>
    </Grid>
  );

};

export default ChatMessage;
