import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Link } from "react-router-dom";
import { Box, Typography, Button, Chip, Stack, Grid } from '@mui/material';
import GoFasterHero from 'components/Hero/GoFaster';
import ImageTextCard from 'components/Card/ImageText';
import HomeStepper from "../HomeStepper";
import Footer from "../Footer";
const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('Generative AI');

  const BackgroundContainer = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(135deg, #3A49F9 0%, #4D5DFB 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
    color: '#fff',
    textAlign: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%', // Make it occupy full width
    margin: 0, // Remove any margin
    padding: 0, // Remove any padding
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#3A49F9',
    borderRadius: '25px',
    padding: '10px 20px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  }));

  const renderContent = () => {
    const contentStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '300px',
      flexDirection: 'row',
      color: 'white',
      padding: '20px',
      textAlign: 'left',
      marginBottom: '20px',
    };

    const imageStyles = {
      width: '50%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };

    const textStyles = {
      width: '50%',
      paddingLeft: '20px',
    };

    const messageStyles = {
      fontSize: '18px',
      marginBottom: '10px',
    };

    const descriptionStyles = {
      fontSize: '16px',
    };

    switch (activeTab) {
      case 'Generative AI':
        return (
          <div style={contentStyles}>
            <div style={{ ...imageStyles, backgroundImage: "url('https://images.unsplash.com/photo-1612832021031-6e0a66e9f0d6?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60')" }}></div>
            <div style={textStyles}>
              <div style={messageStyles}>Optimize your  performance.</div>
              <div style={descriptionStyles}>
              The model is trained with all team data, ensuring more accurate advice        </div>
            </div>
          </div>
        );
      case 'Data Insertion':
        return (
          <div style={contentStyles}>
            <div style={{ ...imageStyles, backgroundImage: "url('https://images.unsplash.com/photo-1602524204937-2e0e3e600a5b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60')" }}></div>
            <div style={textStyles}>
              <div style={messageStyles}>Enter Your Data Faster</div>
              <div style={descriptionStyles}>
              You don’t need to enter your data directly; the team manager can do it for you. By selecting multiple drivers in the Insertion component, a lot of data can be entered with just one click
              </div>
            </div>
          </div>
        );
      case 'Archive':
        return (
          <div style={contentStyles}>
            <div style={{ ...imageStyles, backgroundImage: "url('https://images.unsplash.com/photo-1581091703349-17e41f0c67c9?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60')" }}></div>
            <div style={textStyles}>
              <div style={messageStyles}>Access All Your Teammates' Setups</div>
              <div style={descriptionStyles}>
              In the archive component, for every competition, you will be able to see not only your setup but also those used by each member of the community
              </div>
            </div>
          </div>
        );
      case 'Analytics':
        return (
          <div style={contentStyles}>
            <div style={{ ...imageStyles, backgroundImage: "url('https://images.unsplash.com/photo-1531816468603-e95c3cf38b05?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60')" }}></div>
            <div style={textStyles}>
              <div style={messageStyles}>Analyze and Compare Your Setups with Your Teammates</div>
              <div style={descriptionStyles}>
              By selecting multiple drivers in the setup analytics component, you can improve by understanding your teammates' setups and changes.
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const NewComponent = () => {
    const newComponentStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#333',
      borderRadius: '10px',
      overflow: 'hidden',
      marginBottom: '20px',
      width: '80%',
      maxWidth: '1200px',
      height: 'auto',
      margin: '20px 0',
    };

    const gifContainerStyles = {
      width: '50%',
      height: 'auto',
      display: 'flex',
    };

    const gifStyles = {
      width: '100%',
      height: 'auto',
      display: 'block',
    };

    const textContainerStyles = {
      width: '50%',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '20px',
    };

    const textMessageStyles = {
      fontSize: '20px',
      marginBottom: '10px',
    };

    const textDescriptionStyles = {
      fontSize: '18px',
    };

    return (
      <div style={newComponentStyles}>
        <div style={gifContainerStyles}>
          <img src={"videos/SETTINGS.gif"} alt="GIF" style={gifStyles} />
        </div>
        <div style={textContainerStyles}>
          <div style={textMessageStyles}>Navigate to the settings space and create your team</div>
          <div style={textDescriptionStyles}>Invite members to join by email or using a link</div>
        </div>
      </div>
    );
  };



  const dashboardStyles = {
    backgroundColor: 'black',
    color: 'white',


  

  };

  const topSectionStyles = {
    textAlign: 'center',


  };

  const titleStyles = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#5865f2',
  };

  const descriptionStyles = {
    fontSize: '18px',
    margin: '10px 0',
  };

  const tabsStyles = {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
    width: '80%',
    maxWidth: '1200px',
  };

  const tabStyles = (isActive) => ({
    background: isActive ? 'white' : 'none',
    border: '1px solid white',
    color: isActive ? 'black' : 'white',
    fontSize: '16px',
    cursor: 'pointer',
    padding: '10px 20px',
    borderRadius: '20px',
    margin: '0 10px',
  });

  const contentContainerStyles = {
    width: '80%',
    maxWidth: '1200px',
    backgroundColor: '#222',
    borderRadius: '10px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '300px',
    marginBottom: '20px',
  };

  return (

    <div style={dashboardStyles}>
      <div style={topSectionStyles}>
        <div style={titleStyles}>AiMotor that's built for Racing Teams</div>
        <div style={descriptionStyles}>Usable by  teams of all sizes</div>

      </div>
      <Grid container spacing={4} justifyContent="center" sx={{ marginTop: '20px' }}>
      <Grid item md={10}>
          <ImageTextCard
            imageUrl={'videos/SETTINGS.gif'}
            imageAlt='Navigate to the settings space and create your team. Invite members to join by email or using a link'
            
            description="Navigate to the settings space and create your team.Invite members to join by email or using a link"
            sx={{
              textAlign: 'left',
              height: { lg: '280px'},
            }}
          />
        </Grid>

<Grid item md={10}>
          <ImageTextCard
            imageUrl={'images/manager_settings.jpg'}
            imageAlt='A Manager can: invite members, assign roles, insert data for every member of the team and change existing data'
            title={
              <Typography
                component="span"
                sx={{
                  color: '#5865f2',
                  fontSize: '2.5rem',
                  fontWeight: 'bold'
                }}
              >
     Manager
              </Typography>
            }
            description="A Manager can: invite members, assign roles, insert data for every member of the team and change existing data"
            sx={{
              textAlign: 'left',
              height: { lg: '280px'},
            }}
          />
        </Grid>
        <Grid item md={10}>
          <ImageTextCard
            imageUrl={'images/driver_settings.jpg'}
            imageAlt='A driver can: input data, view and analyze all team data, and get suggestions from AiMotor 2 based on the team data'
            title={
              <Typography
                component="span"
                sx={{
                  color: '#5865f2',
                  fontSize: '2.5rem',
                  fontWeight: 'bold'
                }}
              >
   Driver
              </Typography>
            }
            description="A Driver can: insert his/her data, view all team data, analyze all the team data and get suggestions by AiMotor 2 based on all team data"
            sx={{
              textAlign: 'left',
              height: { lg: '280px'},
            }}
          />
        </Grid>

        </Grid>

 
      {/* <NewComponent2 />
      <NewComponent3 /> */}
       
      <HomeStepper/>
      <BackgroundContainer>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
       Go Faster today
      </Typography>
      <Link to="/sign-up" className="insertdata">
      <StyledButton variant="contained">
Register
      </StyledButton>
      </Link>
    </BackgroundContainer>
    <Footer />
  
 

    </div>

  );
};

export default Dashboard;
