import "../components.css";

import React from "react";
import SignInForm from '../SignInForm';
import {
  useLocation
} from "react-router-dom";

export default function InvitedMember() {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let teamId = query.get('teamId');

  return (
    <>
      <SignInForm teamId={teamId} />
    </>
  )
}