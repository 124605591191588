import React, { useState, useEffect } from 'react';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import { useHistory } from 'react-router-dom';

const ChatGPTComponent = () => {
  const history = useHistory();
  const [userInput, setUserInput] = useState('');

  const pageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '65vh',
    backgroundColor: 'black',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
  };

  const contentContainerStyle = {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const titleStyle = {
    fontSize: '3.5rem',
    marginBottom: '30px',
  };

  const inputContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#333',
    borderRadius: '50px',
    padding: '10px 20px',
    maxWidth: '600px',
    width: '80%',
  };

  const inputStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    color: '#fff',
    outline: 'none',
    width: '100%',
    fontSize: '1.1rem',
    transition: 'opacity 0.5s ease-in-out',
    opacity: 1,
    padding: '10px',  // Add padding for better visual consistency
    margin: '0',      // Reset margin
    borderRadius: '50px', // Ensure border radius matches the container
  };

  const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    
  };

  const placeholderTexts = [
    'With what setup have I made sector 4 the fastest today?',
    'Why is my teammate faster than me in sector 1?',
    'What setup should I use for my next session ?',
    'Give me some insights about my telemetry data',
  ];

  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(0);

      setTimeout(() => {
        setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholderTexts.length);
        setOpacity(1);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = () => {
    if (userInput.trim() !== '') {
      history.push(`/racing-space-try/chat-bot?question=${encodeURIComponent(userInput)}`);
    }
  };

  const iconStyle = {
    color: '#fff',
    fontSize: '3rem', // Increased font size to make the icon bigger
  };

  return (
    <div style={pageStyle}>
      <div style={contentContainerStyle}>
        <h1 style={titleStyle}>Ask AiMotor anything</h1>
  
        <div style={inputContainerStyle}>
          <input
            style={{ ...inputStyle, opacity: opacity }}
            type="text"
            placeholder={placeholderTexts[currentPlaceholderIndex]}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
          />
          <button style={buttonStyle} onClick={handleButtonClick}>
            <ArrowCircleRightSharpIcon style={iconStyle} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatGPTComponent;
