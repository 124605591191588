
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Try_ManagerForm from "./Try_ManagerForm.js";
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { observer } from "mobx-react-lite"
import Try_InsertDriver from './Try_InsertDriver.js';

const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PieChartWithSelect = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState(0);
  const [showFront, setShowFront] = useState(false);
  const [showEngine, setShowEngine] = useState(true);


  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0);
    setShowFront(newValue === 1);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-calendar-popover' : undefined;

  // Set the value to false for showPersonalButton
  const showPersonalButton = false;
  console.log("Personal Button:", showPersonalButton);

  // Set selectedTeam to always be 226
  const selectedTeam = 226;
  console.log("Selected Team:", selectedTeam);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Grid
        item
        lg={showPersonalButton ? 6 : 4}
        md={showPersonalButton ? 6 : 4}
        sm={showPersonalButton ? 6 : 4}
        xs={12}
        sx={{ marginBlock: 2 }}
      >
        <Typography sx={{ fontSize: 40 }}> Setup Data</Typography>
      </Grid>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="fullWidth" value={value} onChange={handleChangeTab}>
            <Tab label="SETUP DATA" {...a11yProps(0)} />
            <Tab label="TRACK AND WEATHER " {...a11yProps(1)} />
          </Tabs>
        </Box>
        <div className="graphs">
          <>
            {showEngine && (
              <Try_InsertDriver

              />
            )}
            {showFront && (
              <Try_ManagerForm
      
              />
            )}
          </>
        </div>
      </Box>
    </div>
  );
};

export default observer(PieChartWithSelect);
