import { Box, Chip, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { LineChart } from '@mui/x-charts/LineChart';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import axios from 'axios';
import { useHistory, } from 'react-router-dom';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import HttpProvider from 'services/HttpProvider';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
export default function MarkOptimization({ track1, hoverLatitude, hoverLongitude, trackIndex, race, driverSessions, day }) {
  const [chartSize, setChartSize] = useState({ width: window.innerWidth < 500 ? 350 : 500, height: window.innerWidth < 500 ? 210 : 300 });
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataset, setDataset] = useState([]);

  const [sectors, setSectors] = useState([]);
  const [hoveredSector, setHoveredSector] = useState(null);
  const [showSectors, setShowSectors] = useState(true);
  const [showSectorNumbers, setShowSectorNumbers] = useState(false);
  const [ballColors, setBallColors] = useState([]);
  const [sectorParts, setSectorParts] = useState([]);  // Initialization added here
  const chartRef = useRef(null);
  const [track, setTrack] = useState([]);
  const toggleChangeTrackDialog = () => setIsChangeTrackDialogOpen((prev) => !prev);
  const [isChangeTrackDialogOpen, setIsChangeTrackDialogOpen] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState(null);

  const [competitions, setCompetitions] = useState([]);
  const [centroids, setCentroids] = useState([]);
  const selectedTeam = myUserStore.getSelectedTeam;
  const containerRef = useRef(null);
  const [trackData, setTrackData] = useState([]);

  let paddingBlock = 49.2;
  const history = useHistory();
  let paddingInline = 49.2;
  const user = JSON.parse(localStorage.getItem("user_data") || "{}");

  const showPersonalButton = myUserStore.isPersonalSpace;
  useEffect(() => {
    fetchCompetitions();
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#121212',
        paper: '#1E1E1E',
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#AAAAAA',
      },
    },
    typography: {
      fontFamily: 'Arial, sans-serif',
      h6: {
        fontWeight: 'bold',
        color: '#BBBBBB',
      },
    },
  });

  const getTrackUser = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/latest_track_user/?driver=${user.email}`);
      const data = response.data;

      setTrack(data);
      getCentroids(data.latest_track);
      getLongLatTrack(data.latest_track);


    } catch (error) {
      console.error("Error fetching competitions:", error);
    }



  }







  const getTrackTeam = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/latest_track_team/?team_id=${selectedTeam.id}`);
      const data = response.data;
      setTrack(data);
      getCentroids(data.latest_track);
      getLongLatTrack(data.latest_track);

    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  }



  const getCentroids = async (givenTrack) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/centroids_data/?track=${givenTrack}`);
      const data = response.data;
      setCentroids(data);

    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  }

  const getLongLatTrack = async (givenTrack) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/long_lat_for_track/?track=${givenTrack}`);
      const data = response.data;
      const filteredData = data.filter((_, index) => index % 8 === 0);

      // Update state with filtered data
      setTrackData(filteredData);
      console.log("Filtered Track Data:", filteredData);

    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  }
  useEffect(() => {
    if (!selectedTrack) {
      if (showPersonalButton) {
        getTrackUser();

      } else {
        getTrackTeam();
      }
    }


  }, [track1]);

  const getSectors = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/get_change_in_sector?race=${race}&&day=${day}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            'Content-Type': 'application/json',
          },
        }
      )
      .then((userResponse) => {
        const sectorTimes = userResponse.data[0]?.sectorData?.sector_times || [];
        const roundedSectorParts = sectorTimes.map(time => Math.round(time / 0.16));
        setSectorParts(roundedSectorParts); // Use setSectorParts to update state
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  };

  const getTrackData = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/get_filtered_longitude_latitude?race=${race}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            'Content-Type': 'application/json',
          },
        }
      )
      .then((userResponse) => {
        setDataset(userResponse.data);
        getSectors(); // Retrieve sector parts after getting track data
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  };

  useEffect(() => {
    getTrackData();
  }, [chartSize, driverSessions, track]);

  useEffect(() => {
    const generateRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const colors = dataset.map(() => generateRandomColor());
    setBallColors(colors);
  }, [dataset]);

  const increaseSize = () => {
    if (chartSize.width < window.innerWidth) {
      setChartSize((prevSize) => ({
        width: prevSize.width * 1.1,
        height: prevSize.height * 1.1,
      }));
      paddingBlock = paddingBlock * 1.1;
      paddingInline = paddingInline * 1.1;
    }
  };

  const decreaseSize = () => {
    setChartSize((prevSize) => ({
      width: Math.max(prevSize.width * 0.9, 100),
      height: Math.max(prevSize.height * 0.9, 100),
    }));
  };
  const isMobile = useMediaQuery(darkTheme.breakpoints.down('sm'));

  const handleTouchOrClickOutside = (event) => {
    if (chartRef.current && !chartRef.current.contains(event.target)) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchOrClickOutside, { passive: true });
    document.addEventListener('mousedown', handleTouchOrClickOutside);

    return () => {
      document.removeEventListener('touchstart', handleTouchOrClickOutside);
      document.removeEventListener('mousedown', handleTouchOrClickOutside);
    };
  }, []);

  const calculateTopPosition = (latitude) => {
    const minLatitude = Math.min(...latitudeData);
    const maxLatitude = Math.max(...latitudeData);
    const mapHeight = chartSize.height - paddingBlock * 2;
    return ((maxLatitude - latitude) * mapHeight) / (maxLatitude - minLatitude);
  };

  const calculateLeftPosition = (longitude) => {
    const minLongitude = Math.min(...longitudeData);
    const maxLongitude = Math.max(...longitudeData);
    const mapWidth = chartSize.width - paddingBlock * 2;
    return ((longitude - minLongitude) / (maxLongitude - minLongitude)) * mapWidth;
  };
  console.log("sector part", sectorParts)

  const longitudeData = trackData?.map((point) => point.longitude) || [];
  const latitudeData = trackData?.map((point) => point.latitude) || [];

  const marginTopValue = chartRef.current ? -chartRef.current.clientHeight : -chartSize.height;

  const minLongitude = Math.min(...longitudeData);
  const maxLongitude = Math.max(...longitudeData);
  const minLatitude = Math.min(...latitudeData);
  const maxLatitude = Math.max(...latitudeData);

  const series =
    // showSectors ? sectorParts?.map((start, index) => {
    //   const end = sectorParts[index + 1] !== undefined ? sectorParts[index + 1] : latitudeData?.length;
    //   if (start >= end || start < 0 || end > latitudeData.length) {
    //     return null;
    //   }
    //   if (index == 0) {
    //     return {
    //       data: [
    //         ...(index !== 0 ? Array(start).fill(null) : []),
    //         ...latitudeData.slice(start, end + 1)
    //       ],
    //       showMark: false,
    //       color: 'yellow',
    //       id: `sector-${index}`,
    //     };

    //   } else {
    //     return {
    //       data: [
    //         ...(index !== 0 ? Array(start).fill(null) : []),
    //         ...latitudeData.slice(start, end + 1)
    //       ],
    //       showMark: false,
    //       id: `sector-${index}`,
    //     };

    //   }

    // }).filter(Boolean) : 
    [{
      data: latitudeData,
      showMark: false,
      lineStyle: { stroke: 'transparent' },
      id: 'track'
    }];
  const navigateToTelemetryData = () => {
    history.push('/racing-space/telemetry-data'); // Use history.push for navigation
  };

  const fetchCompetitions = async () => {
    HttpProvider.get(`/get_competitions/?team_id=${selectedTeam.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
    })
      .then((res) => {
        setCompetitions(res.data);
      })
      .catch((err) => {
        console.error("Error fetching competitions:", err);
      })
      .finally(() => {
      });
  };

  return (
    <>
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <Box
          sx={{ position: 'relative', display: 'inline-block' }}
          onMouseEnter={() => setIsHovered(true)}
          onTouchStart={() => setIsHovered(true)}
          ref={chartRef}
        >
          {  trackData?.length > 0 && (
            <>
              <Chip
                label="Show Sectors"
                color={showSectors ? 'primary' : 'default'}
                onClick={() => setShowSectors(!showSectors)}

                sx={{ position: 'absolute', top: 10, left: 20, zIndex: 12 }}
              />
              {showSectors && (
                <>
                  <Chip
                    label="Sector Numbers"
                    color={showSectorNumbers ? 'secondary' : 'default'}
                    onClick={() => setShowSectorNumbers(!showSectorNumbers)}
                    sx={{ position: 'absolute', top: 10, left: 140, zIndex: 12, marginBottom: 20, fontSize: '12px' }}
                  />
                  {/* Add yellow square and text when showSectorNumbers is enabled */}

                </>
              )}
              {!isMobile && (<Chip
                label="Change Track"
                color="secondary"
                onClick={toggleChangeTrackDialog}
                sx={{ position: 'absolute', top: 10, left: 260, zIndex: 12, marginBottom: 20 }}
              />)}
              {/* Legend Box */}
            </>
          )}
          {!isLoading && (trackData?.length > 0) ? (
            <>
              <LineChart
                axisHighlight={false} // Disable axis highlighting
                grid={false} // Disable grid lines
                disableAxisListener={true}
                xAxis={[{
                  data: longitudeData,
                  min: minLongitude,
                  max: maxLongitude,
                  hide: true, // Hide x-axis
                  disableLine: true,
                  disableTicks: true,
                  hideTooltip: true,
                  tickInterval: []
                }]}
                yAxis={[{
                  min: minLatitude,
                  max: maxLatitude,
                  hide: true, // Hide y-axis
                  disableLine: true,
                  disableTicks: true,
                  hideTooltip: true,
                  tickInterval: []
                }]}
                series={series}
                width={chartSize.width}
                height={chartSize.height}
                highlightedItem={{
                  seriesId: 'track',
                  dataIndex: 10,
                }}
                interactions={{
                  hover: false, // Disabilita il comportamento di hover
                }}
                tooltip={{
                  enabled: false, // Disabilita i tooltip
                }}
              // Reset on mouse leave
              />

              {/* Tooltip for hovered sector */}
              {hoveredSector && (
                <Tooltip
                  title={`Sector ${hoveredSector}`} // Tooltip text
                  open={true}
                  placement="top"
                  style={{ position: 'absolute', zIndex: 20, top: '10px', left: '50%' }} // Adjust the position dynamically
                >
                  <div></div> {/* Empty div just for anchoring the tooltip */}
                </Tooltip>
              )}

              {dataset.map((data, index) => {
                const currentLatitude = data?.drivingData?.[trackIndex]?.latitude;
                const currentLongitude = data?.drivingData?.[trackIndex]?.longitude;
                const ballColor = ballColors[index];

                return (
                  currentLatitude && currentLongitude && (
                    <div
                      key={index}
                      ref={containerRef}
                      style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        marginTop: `${marginTopValue}px`,
                        paddingBlock: `${paddingBlock}px`,
                        paddingInline: `${paddingInline}px`,
                      }}
                    >
                      <div
                        style={{
                          position: 'relative',
                          top: `${calculateTopPosition(currentLatitude)}px`,
                          left: `${calculateLeftPosition(currentLongitude)}px`,
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: ballColor,
                          borderRadius: '50%',
                          width: '10px',
                          height: '10px',
                          zIndex: 11,
                        }}
                      />
                    </div>
                  )
                );
              })}




              {showSectors && centroids?.map((point, index) => (
                <div
                  key={index}
                  ref={containerRef}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    marginTop: `${marginTopValue - 3}px`,
                    paddingBlock: `${paddingBlock}px`,
                    paddingInline: `${paddingInline}px`,
                  }}
                >
                  {showSectorNumbers ? (
                    <Typography
                      variant="caption"
                      style={{
                        position: 'absolute',
                        top: `${calculateTopPosition(point[0]) + 50}px`,
                        left: `${calculateLeftPosition(point[1]) + 50}px`,
                        transform: 'translate(-50%, -50%)',
                        zIndex: 18,
                        color: 'black',
                        fontSize: window.innerWidth < 400 ? '12px' : '14px', // Adjust font size as needed
                        padding: '4px', // Slight padding for the last number
                        borderRadius: '4px', // Square background for the last number
                        backgroundColor: '#f0f0f0', // Light background for better visibility
                        border: '1px solid #ccc', // Border for the last number
                      }}
                    >
                      {index === 0 ? 0 : index} {/* Display the sector number */}
                    </Typography>
                  ) : (
                    <Tooltip title={`Sector ${index}`}>
                      <CloseIcon
                        style={{
                          position: 'absolute',
                          top: `${calculateTopPosition(point[0]) + 50}px`,
                          left: `${calculateLeftPosition(point[1]) + 50}px`,
                          transform: 'translate(-50%, -50%)',
                          color: 'black',
                          fontSize: '20px',
                          zIndex: 10,
                          // Customize the color or style of centroid markers


                          zIndex: 12,
                        }}
                      />
                    </Tooltip>
                  )}
                </div>

              ))}
              {latitudeData.length > 0 && longitudeData.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: `${calculateTopPosition(latitudeData[latitudeData.length - 1]) + 46}px`,
                    left: `${calculateLeftPosition(longitudeData[longitudeData.length - 1]) + 50}px`,
                    transform: 'translate(-50%, -50%)',
                    zIndex: 15,
                  }}
                >
                  <SportsScoreIcon
                    style={{ width: '30px', height: '30px' }}
                  />
                </div>
              )}
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: chartSize.width,
                height: chartSize.height,
                backgroundColor: '#f0f0f0',
                borderRadius: '8px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: chartSize.width,
                  height: chartSize.height,
                  backgroundColor: '#f0f0f0',
                  borderRadius: '8px',
                  flexDirection: 'column',
                }}
              >
                <Typography>No telemetry data inserted yet!</Typography>
                <Chip
                  label="Insert Data"
                  color="primary"
                  onClick={navigateToTelemetryData} // Call the navigation function
                  sx={{ mt: 2 }}
                />
              </Box>

            </Box>
          )}

          {isHovered && (
            <Box
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '4px',
                padding: '4px',
              }}
            >
            </Box>
          )}

        </Box>
      </div>
      {isMobile  && (trackData?.length > 0) &&(<Chip
        label="Change Track"
        color="secondary"
        onClick={toggleChangeTrackDialog}
        sx={{ zIndex: 300, marginTop: -8}}
      />)}
      <Dialog
        open={isChangeTrackDialogOpen}
        onClose={toggleChangeTrackDialog}

        maxWidth={false} // Permette la gestione manuale delle dimensioni
        sx={{
          '& .MuiDialog-paper': {
            // Disabilita limiti predefiniti
            borderRadius: '16px',
            padding: 5 // Arrotondamenti per aspetto moderno
          },
        }}
      >
       <DialogContent>
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: 2,
    }}
  >
    {[...new Set(competitions.map((competition) => competition.track))].map((uniqueTrack, index) => {
      const competition = competitions.find((comp) => comp.track === uniqueTrack);
      return (
        <Chip
          key={index}
          label={uniqueTrack}
          color={selectedTrack === uniqueTrack ? 'primary' : 'default'}
          onClick={() => {
            setSelectedTrack(uniqueTrack);
            toggleChangeTrackDialog(); // Chiude il dialog
            setTrack(competition);
            getCentroids(uniqueTrack);
            getLongLatTrack(uniqueTrack);
          }}
          sx={{ mb: 1.5 }}
        />
      );
    })}
  </Box>
</DialogContent>

      </Dialog>

    </>
  );
}
