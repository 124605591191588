import React from 'react';
import './components.css';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function AnalyzeDataComponent(){
return (
  <Container>
    <Form>
      <Row>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="userselect">Select User: </Form.Label>
            <Form.Select aria-label="Default select example" placeholder="Antonio12345">
     
      <option value="1">Alberto125</option>
      <option value="2">Marco99</option>
      <option value="3">Alfonso56</option>
    </Form.Select>
            
          </Form.Group>
        </Col>
        <Col xs={6} md={6} lg={4}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="sessionTypeSelect">Select Circuit: </Form.Label>
            <Form.Select aria-label="Default select example" placeholder="Lonato">
      <option>Four</option>
      <option value="Lonato">One</option>
      <option value="FranciaCorta">Two</option>
      <option value="Cremona">Three</option>
      
    </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="sessionSelect">Select Session Type: </Form.Label>
            <Form.Select aria-label="Default select example">
     
      <option value="1">Practrice</option>
      <option value="2">Qualifying</option>
      <option value="3">Race</option>
    </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="sessionSelect">Select Session Name: </Form.Label>
            <Form.Select aria-label="Default select example"placeholder="Siena11">
      
      <option value="1">Lonato11</option>
      <option value="2">Cremona19</option>
      <option value="3">Franciacorta13</option>
    </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
      <Col md={6} lg={4}>
          <Form.Group className="mb-3">
          <Link to="/future-updates-page">
          <Button variant="light" size="lg">
             SHOW
            </Button>
          </Link>
        
          </Form.Group>
        </Col>
      </Row>
    </Form>
</Container>
)
};


export default AnalyzeDataComponent