import { Box, Skeleton, Stack } from '@mui/material';
import { Button, Chip, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChatIcon from '@mui/icons-material/Chat';
import ChatMessage from 'components/Chat/Message';
import ChatMessageLoader from 'components/Chat/MessageLoader';
import ChatSuggestion from 'components/Suggestions';
import DOMPurify from 'dompurify';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Grid from '@mui/material/Grid';
import HttpProvider from 'services/HttpProvider'
import IconButton from '@mui/material/IconButton';
import Markdown from 'marked-react';
import Paper from '@mui/material/Paper';
import Pricing from '../Pricing';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Try_Drawer from './Try_Drawer';
import Typography from '@mui/material/Typography';
import Writer from 'components/Chat/Writer';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useLocation } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import SuggestionsList from 'components/Chat/Suggestions/SuggestionsList';


const chatTypes = [
    {
        title: 'AiMotor 1',
        description: 'Great for Personal Space',
        type: 'normal',
        icon: <FlashOnIcon fontSize="small" />,
        activeFor: ['Growth', 'Pro', 'Team'],
        userPlan: ['Growth'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        },
    },
    {
        title: 'AiMotor 2',
        description: 'Advanced model for Racing Teams',
        type: 'advanced',
        icon: <StarIcon fontSize="small" />,
        activeFor: ['Pro', 'Team'],
        userPlan: ['Growth', 'Pro', 'Team'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        },
    },
];

const Chat = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [chatSuggestions, setChatSuggestions] = useState([]);
    const [openPricing, setOpenPricing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chatType, setChatType] = useState(chatTypes[1]);
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [messageText, setMessageText] = useState('');
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const initialQuestion = query.get('question');
    const [currentStreamingMessage, setCurrentStreamingMessage] = useState('');
    const [incomingFragments, setIncomingFragments] = useState([]);
    const [isResponseStreaming, setIsResponseStreaming] = useState(false);
    const selectedChatId = '66de0882378af215137603b7';
    const [allChatMessages, setAllChatMessages] = useState({});
    const [conversation, setConversation] = useState([]);
    const [showChipSuggestions, setShowChipSuggestions] = useState(true);
    const [showChipSubmit, setShowChipSubmit] = useState(true);
    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleMenuItemClick = (model) => {
        const getChatToBeSelected = chatTypes.find((c) => c.title === model);

        if (getChatToBeSelected) {
            setChatType(getChatToBeSelected);
            handleClose();
        }
    };

    useEffect(() => {
        if (initialQuestion) {
            handleMessageSubmit(initialQuestion); // Automatically submit question from URL
        }
    }, [initialQuestion]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };
    const suggestionsRef = useRef(null);

    // Close suggestions when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setChatSuggestions([]);
                setShowChipSuggestions(true)// Clear the list

               // setMessageText('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const showSuggestionsByChip = (suggestion) => {
        // set message to add into searchbar -> helpText
        setMessageText(suggestion.helpText)
        setChatSuggestions(suggestion.suggestions)
        setShowChipSuggestions(false)
    };

    const handleSuggestionClick = (message) => {
        setMessageText('')
        setChatSuggestions([])
        handleMessageSubmit(message)
        setShowChipSuggestions(false)
    };

    useEffect(() => {
        scrollToBottom();  // Scroll to bottom whenever messages change
    }, [messages]);  // Depend on messages state

    const handleMessageComplete = (message, role = 'system') => {
        setMessages((prevMessages) => [...prevMessages, { message, role }]);

        setConversation((prevConversation) => [
            ...prevConversation.slice(-3), // Keep only the last conversation entry
            { message, role },
        ]);

        setIncomingFragments([]);
        setIsResponseStreaming(false);
    };
    const MAX_CONVERSATION_CHARACTERS = 4000;
    const truncateConversation = (conversation) => {
        let totalCharacters = 0;

        // Reverse the conversation array to prioritize retaining the most recent messages
        const truncatedConversation = [...conversation].reverse().filter((entry) => {
            const messageLength = entry.message.length; // Get the length of the `message` key
            if (totalCharacters + messageLength <= MAX_CONVERSATION_CHARACTERS) {
                totalCharacters += messageLength;
                return true; // Keep this entry
            }
            return false; // Exclude this entry
        });

        return truncatedConversation.reverse(); // Reverse it back to the original order
    };


    const handleMessageSubmit = async (message) => {
        debugger
        setShowChipSubmit(false);
        const encodedMessage = encodeURIComponent(message);
        const newMessage = { role: 'user', message: message };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setConversation((prevConversation) => [
            ...prevConversation.slice(-3), // Keep only the last conversation entry
            newMessage,
        ]);

        setIsResponseStreaming(true);
        scrollToBottom();

        try {
            setIsLoading(true);
            const endpoint = chatType.title === 'AiMotor 1' ? 'try_aimotor_1' : 'try_aimotor_2';

            const truncatedConversation = JSON.stringify(truncateConversation(conversation));

            await fetchEventSource(`${process.env.REACT_APP_API_DOMAIN}/${endpoint}?request=${encodedMessage}&conversation=${truncatedConversation}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Accept: "text/event-stream",
                },
                onmessage(event) {
                    setIsLoading(false);
                    let message = event.data;
                    if (message === "[DONE]") {
                        message = `"${message}"`;
                    }
                    setIncomingFragments((prev) => [...prev, JSON.parse(message)]);
                },
                onclose() {
                    setIncomingFragments((prev) => [...prev, JSON.parse('"[DONE]"')]);
                    setIsLoading(false);
                },
                onerror(err) {
                    setIsLoading(false);
                    console.error('Error querying LLM:', err);
                },
            });
        } catch (error) {
            setIsLoading(false);
            console.error('Error querying LLM:', error);
        }
    };



    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWriterComplete = () => {
        if (currentStreamingMessage) {
            setMessages((prevMessages) => [
                ...prevMessages,
                { message: currentStreamingMessage, role: 'system' },
            ]);

            setConversation((prevConversation) => [
                ...prevConversation,
                { message: currentStreamingMessage, role: 'system' },
            ]);
        }
        setIsResponseStreaming(false);
        setCurrentStreamingMessage('');
    };

    const resetChat = () => {
        setMessages([]);  // Clear the messages
        setConversation([]);  // Clear the conversation
        setCurrentStreamingMessage('');  // Clear the streaming message
        setIsResponseStreaming(false);  // Stop streaming response
    };


    console.log("Conv:", conversation)
    return (
        <>
            <Box className="chat-wrapper" container={true} justifyContent="center">
                <Box sx={{ position: 'absolute', top: '16px', left: '16px', display: 'flex', alignItems: 'center' }}>
                    <Button
                        aria-controls={open ? 'version-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant="text"
                        sx={{ color: '#0D47A1', textTransform: 'none', fontSize: '22px', fontWeight: 'bold' }}
                    >
                        {chatType.title}
                        <ArrowDropDownIcon />
                    </Button>
                    <Menu
                        id="version-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ mt: 1 }}
                    >
                        {chatTypes.map((type) => (
                            <MenuItem key={type.title} onClick={() => handleMenuItemClick(type.title)}>
                                <ListItemIcon>
                                    {type.icon}
                                </ListItemIcon>
                                <ListItemText primary={type.title} secondary={type.description} />
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                <Box sx={{ height: "100%", width: '100%', pt: 10 }} display={'flex'} flexDirection={'column'} mx={'auto'}>
                   <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ flex: '1', display: messages.length ? 'none' : 'flex' }}>
                        {showChipSuggestions && (<ChatSuggestion onClick={showSuggestionsByChip} sx={{ maxWidth: 800 }} />)}
                    </Stack>
                    <div
                        style={{ flex: '1', overflowY: 'auto', display: messages.length ? 'block' : 'none' }}
                        ref={chatContainerRef}
                    >
                        <Box sx={{
                            maxWidth: '800px',
                            margin: '0 auto'
                        }}>

                            {messages.map((msg, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    <ChatMessage owner={msg.role === "user"} message={msg.message} />
                                </div>
                            ))}
                            {isLoading && (
                                <ChatMessageLoader />
                            )}


                            {/* Show the streaming message while loading */}
                            {isResponseStreaming && !isLoading && (
                                <div style={{ marginBottom: '10px' }}>
                                    <Writer
                                        incomingFragments={incomingFragments}
                                        setIncomingFragments={setIncomingFragments}
                                        onComplete={(message) => handleMessageComplete(message)}
                                        scrollChat={scrollToBottom}
                                    />
                                </div>
                            )}
                        </Box>
                    </div>
                    <div ref={suggestionsRef} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <SuggestionsList list={chatSuggestions} onClick={handleSuggestionClick} />
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const message = e.target.elements.message.value;
                            if (message.trim() !== '') {
                                handleMessageSubmit(message)
                                e.target.reset();
                                setMessageText('');
                            }
                        }}
                        style={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: 0, padding: '10px', zIndex: 2, width: '100%', maxWidth: '800px', margin: '0 auto' }}
                    >
                        <Paper
                            sx={{
                                p: '7px',
                                borderRadius: '16px',
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: 'center',
                                width: '100%',
                                backgroundColor: 'whitesmoke',
                            }}
                        >
                            <TextField
                                fullWidth
                                placeholder="Message AiMotor"
                                variant="standard"
                                name="message"
                                value={messageText}
                                multiline
                                InputLabelProps={{
                                    style: { color: 'black', textAlign: 'center', width: '100%' },
                                    shrink: true,
                                }}
                                InputProps={{
                                    style: { color: 'black', textAlign: 'center' },
                                    disableUnderline: true,
                                }}
                                sx={{ textAlign: 'center' }}
                                onChange={(e) => setMessageText(e.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                {/* Chat button */}
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <Tooltip title="Previous chat history">
                                        <IconButton
                                            onClick={() => setIsDrawerOpen(true)}
                                            sx={{
                                                color: 'white',
                                                backgroundColor: '#0D47A1',
                                                '&:hover': { backgroundColor: '#1565C0' },
                                                padding: '10px',
                                                width: '35px',
                                                height: '35px'
                                            }}
                                        >
                                            <ChatIcon sx={{ fontSize: '17px' }} />
                                        </IconButton>
                                    </Tooltip>

                                    <IconButton
                                        sx={{
                                            color: '#0D47A1', // Colore grigio
                                            backgroundColor: 'transparent', // Rimuove sfondo
                                            padding: '10px',
                                            width: '35px',
                                            height: '35px'
                                        }}
                                        onClick={() => history.push('/racing-space-try/telemetry-data')}
                                    >
                                        <AttachFileIcon sx={{ fontSize: '25px' }} />
                                    </IconButton>
                                </div>

                                {/* Send button */}
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <IconButton
                                        sx={{
                                            color: '#0D47A1', // Colore grigio
                                            backgroundColor: 'transparent', // Rimuove sfondo
                                            padding: '10px',
                                            width: '35px',
                                            height: '35px'
                                        }}
                                        onClick={() => history.push('/racing-space-try/racing-data')}
                                    >
                                        <AddIcon sx={{ fontSize: '25px' }} />
                                    </IconButton>

                                    <IconButton
                                        type={isResponseStreaming ? 'button' : 'submit'}
                                        //onClick={isResponseStreaming ? handleStopChat : undefined}
                                        style={{
                                            minWidth: 'auto',
                                            backgroundColor: isResponseStreaming
                                                ? '#0D47A1' // Blue for stop
                                                : messageText.trim() !== ''
                                                    ? '#0D47A1' // Blue for send action
                                                    : 'transparent',
                                            borderRadius: '50%',
                                            padding: '10px',
                                            width: '35px',
                                            height: '35px'
                                        }}
                                    >
                                        {isResponseStreaming ? (
                                            <StopCircleIcon style={{ color: 'white' }} />
                                        ) : (
                                            <ArrowUpwardIcon
                                                style={{ color: messageText.trim() !== '' ? 'white' : 'gray' }}
                                            />
                                        )}
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                    </form>
                </Box>
                <div style={{ textAlign: 'center', fontSize: '13px', color: '#888' }}>
                    This is a demo version. Data has already been inserted.  </div>

                <Try_Drawer
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    onNewChat={resetChat}  // Pass resetChat as a prop
                />

                <Pricing open={openPricing} onClose={() => setOpenPricing(false)} />
            </Box>
        </>
    );
};

export default Chat;
