import React, { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import HttpProvider from 'services/HttpProvider';
import Plans from 'components/Pricing/Plans';
import myUserStore from 'stores/UserStore';
import { observer } from 'mobx-react-lite';
import { transformData } from 'helpers/utils';
import { useHistory } from 'react-router-dom';
import Plans_Dialog from 'components/Pricing/Plans_Dialog';
import axios from "axios";


const PricingSignUp = ({ handleRedirect = true, onComplete = () => {} }) => {
  const history = useHistory();
  const token = localStorage.getItem('access_token');
  const user = JSON.parse(localStorage.getItem('user_data') || '{}');

  const handleAddMember = async (user_id, team_id) => {
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/add_member/?role=DRV&team_id=${parseInt(
      team_id
    )}&status=ACTIVE&user_id=${user_id}`;
    await axios
      .post(
        apiUrl,
      {
        role: 'MGR',
        team_id: parseInt(team_id),
        status: 'ACTIVE',
        user_id: user_id
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: 'application/json'
        }
      }
    ).then((response) => {
      // handle success
      return response;
    }).catch((error) => {
      console.error('Error Api', error);
    });
  };

  const getSettings = (access_token, user_data) => {
    return HttpProvider.get('/get_settings', {
      headers: {
        Authorization: `Bearer ${access_token}`,
        accept: 'application/json'
      }
    })
      .then((res) => {
        debugger
        const transformedData = transformData(
          res.data,
          user_data.user_id,
          user_data.email,
          user_data.surname
        );
        const selectedTeam = transformedData.teamInfo[0] || {};
        localStorage.setItem('selectedTeam', JSON.stringify(selectedTeam));
        myUserStore.setSelectedTeam(selectedTeam);
       
        return res.data;
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        return err;
      });
  };

  const handleInsertTeam = async (user_id, accessToken, userData) => {
    debugger
    return HttpProvider.post(`/insert_team/?team_name=personal-${user_id}`,
      {
        team_name: `personal-${user_id}`
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          accept: 'application/json'
        }
      }
    ).then(async (response) => {
      await  handleAddMember(user_id, response.data);
      return await getSettings(accessToken, userData);
      
      

    })
    .catch((err) => {
      console.error('Error while creating the team:', err);
      return err;
    });
  };

  const userExist = useMemo(() => {
    return (user && user.email);
  }, [user]);

  const loginUser = async (token, user) => {
    debugger
    myUserStore.setIsLoggedIn(true);
    await handleInsertTeam(user.user_id, token, user);
    return history.push('/racing-space');
  };
  
  const handleSuccessPayment = () => {
    debugger
    onComplete();
    if (handleRedirect) {
      debugger
      return loginUser(token, user);
    }
  };

  useEffect(() => {
    if (!userExist) {
      return history.push('/sign-up');
    }
  }, [userExist]);

  return (
    <Box
    >

      {/* <Plans_Dialog isExternal={true} onSuccess={handleSuccessPayment} showAll={true} /> */}
      <Plans_Dialog isExternal={true} onSuccess={handleSuccessPayment}  />
    </Box>
  );
};

export default observer(PricingSignUp);
